import React, { useEffect, useState } from "react";
import { KTIcon, toAbsoluteUrl } from "../../../_metronic/helpers";
import axios from "axios";
import { Blocks } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../modules/auth";
import { resData } from "./HalData";
// import { saveAs } from 'file-saver';

const csvFileLinks: { [key: string]: string } = {
  "https://formrecognizer12345.blob.core.windows.net/form-recognizer/formrecognizer12345/test/HAL/20231124-114850%25LC140001.pdf": "LC140001",
  "https://formrecognizer12345.blob.core.windows.net/form-recognizer/formrecognizer12345/test/HAL/20231124-115033%25LC90001.pdf": "LC90001",
  "https://formrecognizer12345.blob.core.windows.net/form-recognizer/formrecognizer12345/test/HAL/20231129-102004%25LC150001.pdf": "LC150001",
  "https://formrecognizer12345.blob.core.windows.net/form-recognizer/formrecognizer12345/test/HAL/20231129-102004%25LC160001.pdf": "LC160001",
  "https://formrecognizer12345.blob.core.windows.net/form-recognizer/formrecognizer12345/test/HAL/20231129-102005%25LC210001.pdf": "LC210001",
  "https://formrecognizer12345.blob.core.windows.net/form-recognizer/formrecognizer12345/test/HAL/20231129-102005%25LC100001.pdf": "LC100001",
  "https://formrecognizer12345.blob.core.windows.net/form-recognizer/formrecognizer12345/test/HAL/20231129-102005%25LC170001.pdf": "LC170001",
  "https://formrecognizer12345.blob.core.windows.net/form-recognizer/formrecognizer12345/test/HAL/20231129-102006%25LC110001.pdf": "LC110001",
  "https://formrecognizer12345.blob.core.windows.net/form-recognizer/formrecognizer12345/test/HAL/20231129-102006%25LC120001.pdf": "LC120001",
  "https://formrecognizer12345.blob.core.windows.net/form-recognizer/formrecognizer12345/test/HAL/20231129-102006%25LC130001.pdf": "LC130001"
}

type Props = {};

type fileType = Array<string>;

const bgList = ["bg-success", "bg-primary", "bg-warning"];

type MyObject = {
  [key: string]: string[];
};

export const HalDocumentProcessingProxy = ({ }: Props) => {
  const { currentUser } = useAuth();

  const [folder, setFolder] = useState({
    files: [
      {
        "fileOriginalName": "LC140001.pdf",
        "fileUrl": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/formrecognizer12345/test/HAL/20231124-114850%25LC140001.pdf",
        "tag": "HAL Document",
        "blobLocation": "formrecognizer12345/test/HAL/20231124-114850%LC140001.pdf",
        "processed": 1,
        "containerName": "form-recognizer",
        "userEmail": "hal@thirdeyedata.ai",
        "createdAt": "2023-11-24 11:48:50.574348"
      },
      {
        "fileOriginalName": "LC90001.pdf",
        "fileUrl": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/formrecognizer12345/test/HAL/20231124-115033%25LC90001.pdf",
        "tag": "HAL Document",
        "blobLocation": "formrecognizer12345/test/HAL/20231124-115033%LC90001.pdf",
        "processed": 1,
        "containerName": "form-recognizer",
        "userEmail": "hal@thirdeyedata.ai",
        "createdAt": "2023-11-24 11:50:34.391767"
      },
      {
        "fileOriginalName": "LC150001.pdf",
        "fileUrl": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/formrecognizer12345/test/HAL/20231129-102004%25LC150001.pdf",
        "tag": "HAL Document",
        "blobLocation": "formrecognizer12345/test/HAL/20231129-102004%LC150001.pdf",
        "processed": 1,
        "containerName": "form-recognizer",
        "userEmail": "hal@thirdeyedata.ai",
        "createdAt": "2023-11-29 10:20:07.211562"
      },
      {
        "fileOriginalName": "LC160001.pdf",
        "fileUrl": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/formrecognizer12345/test/HAL/20231129-102004%25LC160001.pdf",
        "tag": "HAL Document",
        "blobLocation": "formrecognizer12345/test/HAL/20231129-102004%LC160001.pdf",
        "processed": 1,
        "containerName": "form-recognizer",
        "userEmail": "hal@thirdeyedata.ai",
        "createdAt": "2023-11-29 10:20:08.229970"
      },
      {
        "fileOriginalName": "LC210001.pdf",
        "fileUrl": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/formrecognizer12345/test/HAL/20231129-102005%25LC210001.pdf",
        "tag": "HAL Document",
        "blobLocation": "formrecognizer12345/test/HAL/20231129-102005%LC210001.pdf",
        "processed": 1,
        "containerName": "form-recognizer",
        "userEmail": "hal@thirdeyedata.ai",
        "createdAt": "2023-11-29 10:20:09.281573"
      },
      {
        "fileOriginalName": "LC100001.pdf",
        "fileUrl": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/formrecognizer12345/test/HAL/20231129-102005%25LC100001.pdf",
        "tag": "HAL Document",
        "blobLocation": "formrecognizer12345/test/HAL/20231129-102005%LC100001.pdf",
        "processed": 1,
        "containerName": "form-recognizer",
        "userEmail": "hal@thirdeyedata.ai",
        "createdAt": "2023-11-29 10:20:10.337438"
      },
      {
        "fileOriginalName": "LC170001.pdf",
        "fileUrl": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/formrecognizer12345/test/HAL/20231129-102005%25LC170001.pdf",
        "tag": "HAL Document",
        "blobLocation": "formrecognizer12345/test/HAL/20231129-102005%LC170001.pdf",
        "processed": 1,
        "containerName": "form-recognizer",
        "userEmail": "hal@thirdeyedata.ai",
        "createdAt": "2023-11-29 10:20:11.396041"
      },
      {
        "fileOriginalName": "LC110001.pdf",
        "fileUrl": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/formrecognizer12345/test/HAL/20231129-102006%25LC110001.pdf",
        "tag": "HAL Document",
        "blobLocation": "formrecognizer12345/test/HAL/20231129-102006%LC110001.pdf",
        "processed": 1,
        "containerName": "form-recognizer",
        "userEmail": "hal@thirdeyedata.ai",
        "createdAt": "2023-11-29 10:20:12.416507"
      },
      {
        "fileOriginalName": "LC120001.pdf",
        "fileUrl": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/formrecognizer12345/test/HAL/20231129-102006%25LC120001.pdf",
        "tag": "HAL Document",
        "blobLocation": "formrecognizer12345/test/HAL/20231129-102006%LC120001.pdf",
        "processed": 1,
        "containerName": "form-recognizer",
        "userEmail": "hal@thirdeyedata.ai",
        "createdAt": "2023-11-29 10:20:13.430523"
      },
      {
        "fileOriginalName": "LC130001.pdf",
        "fileUrl": "https://formrecognizer12345.blob.core.windows.net/form-recognizer/formrecognizer12345/test/HAL/20231129-102006%25LC130001.pdf",
        "tag": "HAL Document",
        "blobLocation": "formrecognizer12345/test/HAL/20231129-102006%LC130001.pdf",
        "processed": 1,
        "containerName": "form-recognizer",
        "userEmail": "hal@thirdeyedata.ai",
        "createdAt": "2023-11-29 10:20:14.480971"
      }
    ]
  });
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [selectedFileNames, setSelectedFileNames] = useState<string[]>([]);
  const [selectedCsv, setSelectedCsv] = useState<{ [key: string]: any }>([]);
  const [result, setResult] = useState<MyObject>({});
  const [resObj, setResObj] = useState<MyObject>({});
  const [viewtables, setViewTables] = useState<Array<MyObject>>([]);
  const [tables, setTables] = useState<Array<Array<MyObject>>>([]);
  const [keyListArray, setKeyListArray] = useState<string[][]>([]);
  const [viewKeyListArray, setViewKeyListArray] = useState<string[][]>([]);
  const [processing, setProcessing] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [preview, setPreview] = useState<boolean>(true);
  const [fileName, setFileName] = useState<string>("");

  const [sasUrl, setSasUrl] = React.useState("");
  const navigate = useNavigate()
  const handleDownload = async () => {
    // Iterate over the values and create a 2D array
    if (selectedFiles.length === 0 || selectedFiles.length > 1) {
      alert("Please select one file before processing");
    } else {

      let fileList: Array<Object> = [];
      let csvFileName: string = ""

      selectedFiles.forEach((selectedFile) => {
        csvFileName = csvFileLinks[selectedFile]
      });

      // Api Call

      try {
        const response = await axios.get(
          `${toAbsoluteUrl(
            `/media/hal/${csvFileName}`
          )}`,
          {
            responseType: "blob" // This ensures that the response is treated as a binary blob
          }
        );

        console.log(response)
        if (response.data.type == 'text/html') {
          alert("No files present to be downloded")
        } else {
          // Create a Blob object from the response data
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          });

          // Create a URL for the Blob
          const url = URL.createObjectURL(blob);

          // Create a link element and trigger the download
          const link = document.createElement("a");
          link.href = url;
          link.download = csvFileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        console.error("Error downloading Excel file:", error);
      }
    }
  };

  const handleProcess = () => {
    // api call
    if (selectedFiles.length === 0) {
      alert("Please select some file before processing");
    } else {
      setProcessing(true);
      setViewTables([]);
      setViewKeyListArray([]);
      setResult({});

      let fileList: Array<Object> = [];
      let res: { [key: string]: any } = {};
      selectedFiles.forEach((selectedFile) => {
        res = resData[selectedFile]
      });

      setTimeout(() => {
        if (res && 'data' in res) {
          let responseArray = res.data;
          let response = res.data[0][0];
          // setViewTables([])
          // setTables([])
          let tempViewTables: MyObject[] = [];
          let tempViewKeyArr: string[][] = [];
          Object.entries(response).map(([key, value], index) => {
            if (typeof value == "object" && response[key]) {
              if (response[key].length > 0) {
                // set the keys of table
                const viewKeyList: string[] = Array.from(
                  new Set(
                    response[key].flatMap(
                      (obj: MyObject) => Object.keys(obj.value) as string[]
                    )
                  )
                );
                tempViewKeyArr.push(viewKeyList);
                setViewKeyListArray([...tempViewKeyArr]);

                // set the table data
                let vtable: MyObject = {};
                for (let j of response[key]) {
                  for (let i of viewKeyList) {
                    if (!vtable[i]) {
                      vtable[i] = j["value"][i]
                        ? [j["value"][i]["content"]]
                        : ["None"];
                    } else {
                      vtable[i].push(
                        j["value"][i] ? j["value"][i]["content"] : ""
                      );
                    }
                  }
                }
                tempViewTables.push(vtable);
                setViewTables([...tempViewTables]);
              }
            }
          });

          setResult(response);
          console.log(tables);
          console.log("key", keyListArray);
          sessionStorage.setItem("response", JSON.stringify(res.data));
          console.log("resp length : ", responseArray.length);
          let tempTablesArr: MyObject[][] = tables;
          responseArray.map((ObjArr: []) => {
            ObjArr.map((resp: { [key: string]: any }) => {
              let tempTables: MyObject[] = [];
              Object.entries(resp).map(([key, value], index) => {
                if (typeof value == "object" && resp[key]) {
                  if (resp[key].length > 0) {
                    // set the keys of table
                    let uniqueKeys: string[] = Array.from(
                      new Set(
                        resp[key].flatMap(
                          (obj: MyObject) => Object.keys(obj.value) as string[]
                        )
                      )
                    );
                    // let keyList = Object.keys(resp[key][0]["value"]);
                    console.log("keys : ", uniqueKeys);
                    let tempKeyArr = keyListArray;
                    tempKeyArr.push(uniqueKeys);
                    setKeyListArray(tempKeyArr);

                    // set the table data
                    let table: MyObject = {};
                    for (let j of resp[key]) {
                      for (let i of uniqueKeys) {
                        if (!table[i]) {
                          table[i] = j["value"][i]
                            ? [j["value"][i]["content"]]
                            : ["None"];
                        } else {
                          table[i].push(
                            j["value"][i] ? j["value"][i]["content"] : ""
                          );
                        }
                      }
                    }

                    tempTables.push(table);
                    //   console.log("temp table : ", tempTables)
                  }
                }
                console.log("1");
              });
              console.log("loop table after: ", tables);
              // tables.length === 0
              //   ? setTables([tempTables])
              //   : setTables([...tables, tempTables]);
              tempTablesArr.push(tempTables);
              console.log("loop table after push: ", tempTablesArr);
              // push in array for CSV
              let csvFiles = selectedCsv;
              csvFiles.push(resp);
              setSelectedCsv(csvFiles);
            });
          });
          setTables(tempTablesArr);
          console.log(tables);
          setProcessing(false);
          setSelectedFiles([]);
        }
      }, 1000);

      console.log(res)
    }
  };

  const handleSelect = (
    e: any,
    url: string,
    name: string,
    processed: number
  ) => {
    console.log(e.target.checked, url);

    if (e.target.checked) {
      let tempSelectedFileNames = selectedFileNames;
      tempSelectedFileNames.push(name);
      setSelectedFileNames([...tempSelectedFileNames]);
      selectedFiles.push(url);
      setFileName(name);
      // if (processed == 1 && selectedFiles.length <= 1) {
      //   handleProcess();
      // }
    }
    if (!e.target.checked) {
      setResult({});
      const index = selectedFiles.indexOf(url);
      if (index > -1) {
        selectedFiles.splice(index, 1);
        let tempSelectedFileNames = selectedFileNames;
        tempSelectedFileNames.splice(index, 1);
        setSelectedFileNames([...tempSelectedFileNames]);
        if (selectedFileNames.length !== 0) {
          setFileName(tempSelectedFileNames[tempSelectedFileNames.length - 1]);
        }
      }
    }
  };

  const handlePreview = async () => {
    setPreview(!preview);
  };

  const handleSaveSql = () => {
    let fileList: Array<Object> = [];
    setSaving(true);
    if (selectedFiles.length === 0) {
      setSaving(false);
      alert("Please select some file before inserting");
    } else {
      selectedFiles.forEach((selectedFile) => {
        let fileObj = {
          fileLink: selectedFile
        };
        fileList.push(fileObj);
      });

      // sqlDataCreate({
      //   files: fileList,
      //   userName: currentUser?.username,
      //   email: currentUser?.email
      // }).then((res) => {
      //   setSaving(false);
      //   alert(res.message);
      // });
    }
  };

  useEffect(() => {
    // filesByUser({ email: currentUser?.email }).then((res) => {
    //   setFolder(res.data);
    // });
  }, []);

  const deleteCookie = (name: string) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    navigate('/auth/hallogin')
  };

  useEffect(() => {
    console.log(keyListArray);
    console.log(selectedCsv);
    console.log(tables);
  }, [tables, keyListArray]);

  return (
    <div>
      {/* Upload Document */}
      <div
        className="modal fade"
        id="kt_modal_assosiate_student"
        aria-hidden="true"
      >
        {/* begin::Modal dialog */}
        <div className="modal-dialog modal-dialog-centered mw-650px">
          {/* begin::Modal content */}
          <div className="modal-content">
            <div className="modal-header">
              {/* begin::Modal title */}
              <h2 className="fw-bolder">Upload Document</h2>
              {/* end::Modal title */}

              {/* begin::Close */}
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              <h4>Please Select from Pre-processed Document </h4>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>

      {/* Preview Document */}
      <div className="modal fade" id="kt_modal_preview" aria-hidden="true">
        {/* begin::Modal dialog */}
        <div className="modal-dialog modal-dialog-centered mw-650px">
          {/* begin::Modal content */}
          <div className="modal-content">
            <div className="modal-header">
              {/* begin::Modal title */}
              <h2 className="fw-bolder">Preview Document</h2>
              {/* end::Modal title */}

              {/* begin::Close */}
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              <div>
                <iframe
                  src={sasUrl}
                  title="PDF Preview"
                  width="100%"
                  height="500px"
                ></iframe>
              </div>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>

      <div className="card card-xl-stretch mb-xl-8 bg-light" style={{ borderRadius: '0px' }}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5 mb-5 align-items-center">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-dark mb-2">
              {" "}
              Document Library{" "}
            </span>
            {/* <span className="text-muted mt-1 fw-semibold fs-7">
              Search your <strong> files</strong>
            </span> */}
          </h3>
          {/* <span>
            <div className="menu-item px-5">
              <a onClick={(e) => deleteCookie('_Secure-Hal')} className="menu-link px-5">
                Sign Out
              </a>
            </div>
          </span> */}
        </div>
        {/* end::Header */}

        {/* begin: Btn Row */}
        <div className="row">
          <div
            className="d-flex justify-content-between my-1"
            data-kt-user-table-toolbar="base"
          >
            <div className="px-5">
              <button
                type="button"
                className="btn btn-primary me-3 my-3 w-180px "
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_assosiate_student"
              >
                <KTIcon iconName="plus" className="fs-3" />
                Upload Document
              </button>
              <button
                type="button"
                className="btn btn-primary me-3 my-3 w-180px "
                // data-bs-toggle="modal"
                // data-bs-target="#kt_modal_assosiate_student"
                onClick={handlePreview}
              >
                {!preview ? "Preview Document" : "Normal Mode"}
              </button>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-primary me-3 my-3 w-200px"
                onClick={handleProcess}
              >
                <KTIcon iconName="gear" className="fs-1" />
                Process
              </button>

              <button
                type="button"
                className="btn btn-primary me-3 my-3 w-200px"
                onClick={handleDownload}
              >
                <KTIcon iconName="cloud-download" className="fs-1" />
                Download All CSV
              </button>
              <button
                type="button"
                className="btn btn-primary me-3 my-3 w-200px"
                onClick={handleSaveSql}
              >
                {/*  */}
                {!saving && (
                  <span className="indicator-label d-flex justify-content-center align-items-center">
                    <KTIcon iconName="save-2" className="fs-1" />
                    Save to SQL
                  </span>
                )}
                {saving && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Please wait...{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
              {/* {Object.keys(result).length === 0 ? null : ( */}
              {/* <button
                className="btn btn-success me-3 my-3 w-150px"
                id="kt_drawer_chat_toggle"
              >
                <KTIcon iconName="message-text-2" className="fs-1" />
                Chat
              </button> */}
              {/* )} */}
            </div>
          </div>
        </div>
        {/* end :: Btn Row */}

        {folder.files.length <= 0 ? (
          <div className="row">
            <Blocks
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
            />
          </div>
        ) : (
          <div className="card-body pt-5">
            <div className="row d-flex justify-content-between position-relative">
              {/* <ListsWidget3 className='card-xl-stretch mb-5 mb-xl-8 px-5' folderName='Hal Document' files={[...files]} /> */}

              {/* Folder UI */}
              <div className="col-sm-12 col-md-3">
                <div
                  className={`card card-xl-stretch mb-5 mb-xl-8`}
                  style={{ maxHeight: "70vh", overflowY: "scroll" }}
                >
                  {folder && (
                    <>
                      {/* begin::Header */}
                      <div className="card-header border-0">
                        <h3 className="card-title fw-bold text-dark">
                          <KTIcon
                            iconName="folder"
                            className="fs-2x text-primary"
                          />
                          &nbsp;
                          {/* {folder.folderName} */}
                          HAL
                        </h3>
                      </div>
                      {/* end::Header */}
                      {/* begin::Body */}
                      <div className="card-body pt-2">
                        {/* begin::Item */}
                        {folder.files.map((file, index) => (
                          <div
                            className="d-flex align-items-center mb-8 mx-5 rounded p-1"
                            style={
                              file.processed == 1
                                ? { backgroundColor: "lightslategrey" }
                                : { backgroundColor: "transparent" }
                            }
                          >
                            {/* begin::Bullet */}
                            <span
                              className={`bullet bullet-vertical h-40px ${bgList[index + 1]
                                }`}
                            ></span>
                            {/* end::Bullet */}
                            {/* begin::Checkbox */}
                            <div className="form-check form-check-custom form-check-solid mx-5">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                checked={selectedFiles.includes(file.fileUrl)}
                                onClick={(e) =>
                                  handleSelect(
                                    e,
                                    file.fileUrl,
                                    file.fileOriginalName,
                                    file.processed
                                  )
                                }
                              />
                            </div>
                            {/* end::Checkbox */}
                            {/* begin::Description */}
                            <div className="flex-grow-1">
                              <a
                                // href={file.url}
                                // target="_blank"
                                className="text-gray-800 text-hover-primary fw-bold fs-6"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_preview"
                                title={file.fileOriginalName}
                              >
                                {file.fileOriginalName.length > 15
                                  ? file.fileOriginalName.slice(0, 10) + "..."
                                  : file.fileOriginalName}
                              </a>
                              <span className="text-muted fw-semibold d-block">
                                Created At : {file.createdAt}
                              </span>
                              <span className="badge badge-primary">
                                {file.tag}
                              </span>
                            </div>
                            {/* end::Description */}
                            {/* <span className="badge badge-light-success fs-8 fw-bold">New</span> */}
                          </div>
                        ))}
                        {/* end:Item */}
                      </div>
                      {/* end::Body */}
                    </>
                  )}
                </div>
              </div>

              {/* File Preview UI */}
              {preview && (
                <div className="col-sm-12 col-md-4">
                  <div className={`card card-xl-stretch mb-5 mb-xl-8`}>
                    <div className="card-header border-0">
                      <h3 className="card-title fw-bold text-dark">
                        <KTIcon
                          iconName="screen"
                          className="fs-2x text-primary"
                        />
                        &nbsp; Preview Document
                      </h3>
                    </div>
                    {fileName !== "" && (
                      <iframe
                        src={toAbsoluteUrl(`/media/hdfcFile/${fileName}`)}
                        title="PDF Preview"
                        width="100%"
                        height="500px"
                      ></iframe>
                    )}
                    {/* <Document file={toAbsoluteUrl('/media/hdfcFile/hdfc1.pdf')}>
                      <Page pageNumber={1} />
                    </Document> */}
                  </div>
                </div>
              )}

              {/* Result UI */}
              <div className={`col-sm-12 ${preview ? "col-md-5" : "col-md-9"}`}>
                {Object.keys(result).length === 0 ? (
                  <div className="row align-items-center">
                    {processing && (
                      <Blocks
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                      />
                    )}
                  </div>
                ) : (
                  <div className="card-xl-stretch mb-5 mb-xl-8 px-5">
                    <div className="d-flex justify-content-end my-1"></div>
                    <div
                      className="rounded p-3 bg-dark text-light overflow-auto"
                      style={{ maxHeight: "70vh", overflowY: "scroll" }}
                    >
                      <h3 className="pb-3 text-light">RESULT:</h3>

                      {/* Result Normal Ky value */}
                      {Object.entries(result).map(([key, value]) => (
                        <div key={key}>
                          {typeof value != "object" && (
                            <h6 className="text-light">
                              <span
                                style={{ fontWeight: "bold", color: "red" }}
                              >
                                {key}
                              </span>{" "}
                              : &nbsp;
                              <span>{JSON.stringify(value)}</span>
                            </h6>
                          )}
                        </div>
                      ))}

                      {/* Result Tables */}
                      {viewtables &&
                        viewtables.map((table, index) => (
                          <table className="my-2">
                            <tr>
                              {viewKeyListArray[index].map((headKey) => (
                                <th style={{ border: "1px solid" }}>
                                  {headKey}
                                </th>
                              ))}
                            </tr>
                            {Object.entries(table).map(
                              ([key, value], valueIndex) => (
                                <tr>
                                  {" "}
                                  {viewKeyListArray[index].map((bodyKeys) => (
                                    <td style={{ border: "1px solid" }}>
                                      {/* {table[bodyKeys][index]
                                        ? table[bodyKeys][0]
                                        : ""} */}
                                      {table[bodyKeys][valueIndex]}
                                    </td>
                                  ))}
                                </tr>
                              )
                            )}
                            {/* {Object.entries(table).map(([key, value]) => (
                            <tr>
                              {value.map((v) => (
                                <td>{tables[index]}</td>
                              ))}
                            </tr>
                          ))} */}
                          </table>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
