import DataSourceDashboard from './DataSourceDashboard'

type Props = {}

export default function DestinationDashboard({ }: Props) {
  return (
    <div className='m-3'>
      {/* begin::Row */}
      <DataSourceDashboard />
      {/* end::Row */}
     
    </div>
  )
}