import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { I18nProvider } from "../_metronic/i18n/i18nProvider";
import { LayoutProvider, LayoutSplashScreen } from "../_metronic/layout/core";
import { MasterInit } from "../_metronic/layout/MasterInit";
import { AuthInit } from "./modules/auth";
import { GoogleOAuthProvider } from "@react-oauth/google";

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <GoogleOAuthProvider
          clientId='530189915632-99uf6m53i80bslelck893eq2hsrf4qof.apps.googleusercontent.com'
        // responseType="code"
        // accessType="offline"
        // prompt="consent"
        >
          <LayoutProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
          </LayoutProvider>
        </GoogleOAuthProvider>
      </I18nProvider>
    </Suspense>
  );
};

export { App };
