import React, { useEffect, useState } from 'react';

import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Blocks } from 'react-loader-spinner';
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers';
import clsx from 'clsx';
import { Alerts, CandidateData, FilePreviewProps } from './ResumeFormattingModel';
import { EARAlert, FCVAlert } from './AlertFunctions';


const FilePreview = ({ uploadedFileLink, docLink, pdfFileName, loading, resumeJson, alertType }: FilePreviewProps) => {
    const [tab, setTab] = useState("processed");
    const [alertMessage, setAlertMessage] = useState<Alerts>({
        'Missing Values': {
            'Personal Details': [],
            'AcademicBackground': [],
            'Career Path': [],
            'Compensation Information': [],
            'Languages Known': []
        },
        'Gaps': [],
    });

    useEffect(() => {
        alertType == 'EAR' ?
            setAlertMessage(EARAlert(resumeJson)) :
            setAlertMessage(FCVAlert(resumeJson))
    }, []);

    return (
        <div className="col-sm-12 col-md-6">
            <div className="card card-xl-stretch mb-5 mb-xl-8" style={{ minHeight: "70vh" }}>
                <div className="card-header border-0">
                    <ul
                        className="nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap"
                        role="tablist"
                    >
                        <li className="nav-item">
                            <a
                                className={clsx(`nav-link cursor-pointer`, {
                                    active: tab === "processed"
                                })}
                                onClick={() => setTab("processed")}
                                role="tab"
                                style={
                                    tab == "processed"
                                        ? { borderBottom: "4px solid #fff" }
                                        : { borderBottom: "" }
                                }
                            >
                                <h3 className="card-title fw-bold text-dark">
                                    <KTIcon iconName="screen" className="fs-2x text-primary" />
                                    &nbsp; Preview Original Resume
                                </h3>

                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className={clsx(`nav-link cursor-pointer`, {
                                    active: tab === "unassosiated"
                                })}
                                onClick={() => {
                                    setTab("unprocessed")
                                }}
                                role="tab"
                                style={
                                    tab == "unprocessed"
                                        ? { borderBottom: "4px solid #fff" }
                                        : { borderBottom: "" }
                                }
                            >
                                <h3 className="card-title fw-bold text-dark">
                                    <KTIcon iconName="notification" className="fs-2x text-primary" />
                                    &nbsp; Resume Data Alert
                                </h3>
                            </a>
                        </li>
                    </ul>
                </div>
                <div>
                    <div className="tab-content pt-3">
                        <div className={clsx("tab-pane", { active: tab === "processed" })}>
                            {uploadedFileLink ? (
                                <>
                                    {!uploadedFileLink.toLowerCase().endsWith(".pdf") ? (
                                        <div className="rounded p-5 bg-dark text-light overflow-auto h-100 border border-3 border-primary">
                                            {
                                                docLink &&
                                                <DocViewer
                                                    documents={[{ uri: docLink, fileType: 'doc' }]}
                                                    pluginRenderers={DocViewerRenderers}
                                                    style={{ height: "450px" }}
                                                />
                                            }
                                        </div>
                                    ) : (
                                        <iframe
                                            src={`${toAbsoluteUrl(`/media/3P Consultancy/resume/${pdfFileName}`)}`}
                                            title="PDF Preview"
                                            width="100%"
                                            height="500px"
                                        ></iframe>
                                    )}
                                </>
                            ) : (
                                <div className="row align-items-center">
                                    {loading ? (
                                        <Blocks
                                            visible={true}
                                            height="80"
                                            width="80"
                                            ariaLabel="blocks-loading"
                                            wrapperStyle={{}}
                                            wrapperClass="blocks-wrapper"
                                        />
                                    ) : (
                                        <div className="card-body">
                                            <h4 className="text-center">The uploaded resume will be displayed here</h4>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className={clsx("tab-pane", { active: tab === "unprocessed" })}>
                            <div className="card text-dark pb-5" style={{ maxHeight: "60vh", overflowY: "auto" }}>
                                <div className="modal-body scroll-y mx-5 mx-xl-15">
                                    {/* Check if there are any alerts to display */}
                                    {Object.values(alertMessage['Missing Values']).some(
                                        section => section.length > 0
                                    ) && (
                                            <div className="alert-section">
                                                <h4>Missing Values</h4>
                                                {Object.entries(alertMessage['Missing Values']).map(([section, messages]) => (
                                                    messages.length > 0 && (
                                                        <ul key={section}>
                                                            <li>
                                                                <h5>{section}</h5>
                                                            </li>
                                                            <ul>
                                                                {messages.map((msg, index) => (
                                                                    <li key={index}>
                                                                        <span className='text-danger'>{msg.slice(0, msg.indexOf(" in "))}</span> {" "}
                                                                        {section !== "Personal Details" && <span className=''>{msg.slice(msg.indexOf(" in"))}</span>}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </ul>
                                                    )
                                                ))}
                                            </div>
                                        )}
                                    {alertMessage['Gaps'].length > 0 && (
                                        <div className="alert-section">
                                            <h4>Gaps</h4>
                                            <ul>
                                                {alertMessage['Gaps'].map((msg, index) => (
                                                    <li key={index}>
                                                        {msg.startsWith('There is a gap of ') && (
                                                            <>
                                                                There is a gap of <span className="text-danger">{msg.substring(17, msg.indexOf(' between'))}</span> between <span className="text-danger">{msg.substring(msg.indexOf(' between ') + 9, msg.indexOf(' and '))}</span> and <span className="text-danger">{msg.substring(msg.indexOf(' and ') + 5, msg.lastIndexOf(' in '))}</span> in <span className="text-danger">{msg.substring(msg.lastIndexOf(' in ') + 3)}</span>
                                                            </>
                                                        )}
                                                    </li>
                                                ))}

                                            </ul>
                                        </div>
                                    )}
                                    {Object.values(alertMessage['Missing Values']).flat().length === 0 &&
                                        alertMessage['Gaps'].length === 0 && (
                                            <p className="fs-1 fw-bold text-center">No issues detected</p>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default FilePreview;
