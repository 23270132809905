import React from 'react'
import { FilePreviewUI } from './FilePreviewUI'
import ResultUI from './ResultUI'
import { DocumentReviewViewProps } from './DocumentProcessingModel'


export default function DocumentReviewView({

    fileName,
    doctype,
    resultNew,
    resultView,
    tableHeader,
    responseJson, // Adjust the type according to your response data structure
    processAction,
    selectedFiles,
    processing,
    handleDocumentApproval,

}: DocumentReviewViewProps) {
    return (
        <div className="row d-flex justify-content-between position-relative">
            {/* <ListsWidget3 className='card-xl-stretch mb-5 mb-xl-8 px-5' folderName='Hal Document' files={[...files]} /> */}

            {/* File Preview UI */}
            <FilePreviewUI
                fileName={fileName}
                doctype={doctype ? doctype : ""}
            />

            {/* Result UI */}
            <div className={`col-sm-12 col-md-6`}>
                <ResultUI resultNew={resultNew} resultView={resultView} tableHeader={tableHeader} responseJson={responseJson} processAction={processAction} selectedFiles={selectedFiles} processing={processing} handleDocumentApproval={handleDocumentApproval} />
            </div>
        </div>
    )
}