import { FC, Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import { useAuth } from "../modules/auth";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import DocumentProcessingDashboard from "../pages/dashboard/DocumentProcessingDashboard";
import DocumentTypes from "../pages/dashboard/DocumentTypes";
import Statistics from "../pages/dashboard/Statistics";
import CustomConversation from "../pages/layout-builder/CustomConversation";
import DocumentProcessingNew from "../pages/layout-builder/DocumentProcessingNew";
import HalDocumentProcessing from "../pages/layout-builder/HalDocumentProcessing";
import MetaTagExtraction from "../pages/layout-builder/MetaTagExtraction";
import ResumeFormat from "../pages/layout-builder/ResumeFormat";
import SampleQuestion from "../pages/layout-builder/SampleQuestion";
import SemanticSearch from "../pages/layout-builder/SemanticSearch";
import Summarization from "../pages/layout-builder/Summarization";
import { Teams } from "../pages/layout-builder/Teams";
import TexisleDocumentProcessing from "../pages/layout-builder/TexisleDocumentProcessing";
import TextExtraction from "../pages/layout-builder/TextExtraction";
import WebsiteCategorization from "../pages/layout-builder/WebsiteCategorization";
import { DriveAnalytics } from "../pages/layout-builder/DriveAnalytics";

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
  const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
  const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
  const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));
  const ChatPage = lazy(() => import("../modules/apps/chat/ChatPage"));
  const UsersPage = lazy(
    () => import("../modules/apps/user-management/UsersPage")
  );

  const { currentUser } = useAuth();

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        <Route path="/*" element={<Navigate to="/dashboard" />} />
        {/* Pages */}
        {/* <Route path="dashboard" element={<DashboardWrapper />} /> */}

        {currentUser?.username == "Texisle" ? (
          <Route path="documentprocessing" element={<TexisleDocumentProcessing />} />
        ) : currentUser?.username == "HAL" ? (
          <Route
            path="documentprocessing"
            element={<HalDocumentProcessing />}
          />
        ) : (
          <Route
            path="documentprocessing/:doctype"
            element={<DocumentProcessingNew />}
          />
        )}
        <Route
          path="websitecategorization"
          element={<WebsiteCategorization />}
        />
        {currentUser?.document_service && <Route path="documentsdashboard" element={<DocumentProcessingDashboard />} />}
        <Route path="features" element={<DashboardWrapper />} />
        <Route path="doctypes" element={<DocumentTypes />} />
        {/* <Route path="statistics" element={<Statistics />} /> */}
        <Route path="statistics/:doctype" element={<Statistics className="Statistics" />} />
        {/* <Route path="datasource" element={<DataSourceDashboard />} /> */}
        {/* <Route path="destination" element={<DestinationDashboard />} /> */}
        <Route path="tagextraction" element={<MetaTagExtraction />} />
        {currentUser?.chatgpt_service && <Route path="customconversation" element={<CustomConversation />} />}
        <Route path="documenttextextraction" element={<TextExtraction />} />
        <Route path="summarization" element={<Summarization />} />
        {/* <Route path="semanticsearch" element={<SemanticSearch />} /> */}
        <Route path="resumesearch" element={<SemanticSearch />} />
        {currentUser?.resume_service && <Route path="resumeformater" element={<ResumeFormat />} />}
        <Route path="samplequestion" element={<SampleQuestion />} />
        <Route path="myteam" element={<Teams />} />
        <Route path="driveanalytics" element={<DriveAnalytics />} />
        {/* Lazy Modules */}
        <Route
          path="crafted/pages/profile/*"
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/pages/wizards/*"
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/widgets/*"
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/account/*"
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path="customchatgpt/*"
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/user-management/*"
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor
    },
    barThickness: 1,
    shadowBlur: 5
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };

