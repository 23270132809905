export const candidateResumeData = {
    "name": "Siddharat Raut",
    "location": "",
    "current_designation": "",
    "email": "",
    "phone": "",
    "summary": "Technology Innovation leader with 25+ years of experience leading technology innovation and new product development in Multinational companies (GE, Dow Chemical, Honeywell Cummins and Mahindra & Mahindra) – focused on enabling sustainable mobility based on electric, autonomous, connected and shared vehicles - Ph.D in Materials Science from Indian Institute of Science (won the Gold Medal for the Best Doctoral Thesis) - Executive Management degree from Indian Institute of Management Bangalore – Inventor in 20 International Patents (Granted) and 10 Indian Patents (Applications) – leading the practice of Systematic Innovation (TRIZ) in new product development for 20+ years.",
    "work_experience": [{
        "title": "Vice President",
        "company": "Mahindra & Mahindra",
        "location": "Mahindra Research Valley, Chennai",
        "time_period": "2016 – Till Date",
        "responsibilities": [
            "Leading Technology Innovation for Automotive Business – with a focus on rapidly scaling up a new Business based on electric, autonomous and connected vehicles.",
            "Leading Technology Innovation for Farm Business – enabling the growth of a new Business that aims at democratizing Technology – precision farming and smart agricultural implements - for Farmers with small land holdings",
            "Leading Intellectual Property & Knowledge Management Functions – IP Portfolio Strategy, IP Monetization, Knowledge Management across Automotive & Farm businesses. Managing IP Assets of 300+ granted Patents and 1800+ Patent Applications.",
            "Leading Technical Capability Building as the Dean of Mahindra Technical Academy – leading University Collaboration with IITs, IISc and Universities in US and Israel.",
            "Reporting to SVP and CTO"
        ],
        "remaining_related_data": {
            "department": "Automotive & Farm Business - Technology Innovation"
        }
    },
    {
        "title": "Director",
        "company": "Cummins",
        "location": "Cummins Technology Center India, Pune",
        "time_period": "",
        "responsibilities": [
            "Lead Technology Planning for India Technology Organization – across Engines, PowerGen and the Components businesses",
            "Lead Two Global Innovation Initiatives – synthesizing future technology vision, driving collaborative innovation across India, US and UK Engineering Teams.",
            "Lead the Technology Planning of India Tech Center in Pune with a focus on disruptive technologies like EVs",
            "Directly reported to the CTO"
        ],
        "remaining_related_data": {
            "department": "Technology Planning and Innovation"
        }
    },
    {
        "title": "Innovation Leader",
        "company": "Honeywell",
        "location": "Honeywell Technology Solutions Ltd, Bangalore",
        "time_period": "2010 - 2012",
        "responsibilities": [
            "Lead Technology Innovation & Product Differentiation for an Intrapreneurial effort to grow in Emerging Markets (India, China, Middle East)",
            "Lead New Product Development – Technology PoCs & Innovation projects",
            "Design for Six Sigma - NPD",
            "Reported to the Vice President, Emerging Market Growth."
        ],
        "remaining_related_data": {
            "department": "Emerging Markets Growth Initiative"
        }
    },
    {
        "title": "General Manager",
        "company": "Dow Chemical",
        "location": "Dow Chemical India Research Center, Pune",
        "time_period": "2008 - 2010",
        "responsibilities": [
            "Lead the Research & Development of new carrier materials for EOEG Catalysts",
            "Technology Lead for a Global Technology Scouting team",
            "Reported to the Global Director, R&D, EOEG Business"
        ],
        "remaining_related_data": {
            "department": "Business R&D – EOEG Catalyst Carriers"
        }
    },
    {
        "title": "Deputy General Manager",
        "company": "Tata Chemicals India",
        "location": "",
        "time_period": "2007 - 2008",
        "responsibilities": [
            "Lead the Development of Scalable Synthesis of Nano Metal Oxides for Commercial Applications",
            "Reported to the CSO."
        ],
        "remaining_related_data": {
            "department": "Advanced Materials Corporate R&D, Pune"
        }
    },
    {
        "title": "Lead Scientist",
        "company": "GE",
        "location": "GE India Technology Center, Bangalore",
        "time_period": "2005 - 2007",
        "responsibilities": [
            "Lead the development of luminescent phosphor materials for application in White LEDs",
            "Built a strong portfolio of 10 US Patents that accelerated the growth of a new business based on Solid State Lighting technology in the US and Europe.",
            "Built a state-of-the-art Materials Research Lab for Functional Ceramics.",
            "Design for Six Sigma – R&D"
        ],
        "remaining_related_data": {
            "department": "Intellectual Property & Innovation"
        }
    },
    {
        "title": "Scientist",
        "company": "ISRO",
        "location": "",
        "time_period": "1997 - 1999",
        "responsibilities": [
            "Lead the Indigenous Technology Development program for silicon - based Photosensors and Qualified them for Space Applications",
            "Built state-of-the-art Fabrication Facility for Thin Films & Detectors for Space Applications."
        ],
        "remaining_related_data": {
            "department": "Laboratory for Electro Optics, Bangalore"
        }
    }
    ],
    "education": [{
        "degree": "Executive General Management Program",
        "university": "Indian Institute of Management, Bangalore",
        "location": "",
        "time_period": "2011 - 2012",
        "remaining_related_data": {}
    },
    {
        "degree": "PhD in Materials Science",
        "university": "Indian Institute of Science, Bangalore",
        "location": "",
        "time_period": "1992 – 1997",
        "remaining_related_data": {}
    },
    {
        "degree": "Master and Bachelor of Physics",
        "university": "",
        "location": "",
        "time_period": "1987 – 1992",
        "remaining_related_data": {}
    }
    ],
    "skills": [{
        "heading": "",
        "related_data": ""
    }],
    "certifications": [{
        "certification_details": "Innovation - TRIZ International Certification (Level 2)",
        "date": "",
        "remaining_related_data": {}
    },
    {
        "certification_details": "Design for Six Sigma – GE, Honeywell, Cummins",
        "date": "",
        "remaining_related_data": {}
    }
    ],
    "additional": {
        "awards": [{
            "award": "Top 50 Innovative Leaders, World Innovation Congress",
            "year": "2020"
        },
        {
            "award": "Top 50 Alumni of IIM Bangalore, Executive General Management Program",
            "year": "2019"
        },
        {
            "award": "Outstanding Innovation Award, Honeywell",
            "year": "2011"
        },
        {
            "award": "Edison Inventor Medal, GE Global Research, (Bronze - 2001, Silver – 2006)"
        },
        {
            "award": "IISc Gold Medal for the Best Doctoral Thesis, Indian Institute of Science",
            "year": "1998"
        }
        ],
        "others": [
            "Member, B20 India Task Force for Technology, Innovation and R&D",
            "Chairman, CTO Forum, CII South India (2018 – 2023)",
            "Member, CII National Committee of Technology, Innovation and R&D (2021-23)",
            "Member, CII National IP Committee (2019 – 2023)",
            "Member, NIDHI PRAYAS and EIR – DST Panel for Technology Entrepreneurship, NCL Venture Center, Pune (2017 - 22)",
            "Chairman, Branding & Committee Board, Society of Automotive Engineers India (2020– 24)",
            "Chairman, MWC Chapter, Madras Management Association"
        ]
    },
    "alert": false
}

export const candidateResumeData2 = {
    "name": "Joseph Iaconis",
    "location": "446 East 86th Street, New York, NY 10028",
    "email": "josephiaconis22@gmail.com",
    "phone": "(917) 773-5882",
    "work_experience": [
        {
            "title": "Finance Senior Billing Coordinator",
            "company": "IPC Systems, Inc.",
            "location": "Jersey City, NJ",
            "dates": "October 2018 – Present",
            "responsibilities": [
                "Conduct Network Services monthly invoicing process consisting of 1,200 invoices for $10mm using NSB internal billing system",
                "Execute daily bookings in Salesforce by analyzing and ensuring compliance",
                "Process daily transactions/invoices in Oracle Cloud consisting of 8 million dollars per month",
                "Trained coordinators in the U.K., Madrid, and Malaysia on billing, accounts receivable, and collections tasks",
                "Collaborated with Sales, Operations, and IT on various cost/process improvements projects to represent the Finance department with ERP, Portal, and Salesforce system upgrades and enhancements"
            ]
        },
        {
            "title": "Business Process Outsourcing Financial Analyst",
            "company": "Vestmark, Inc.",
            "location": "Jersey City, NJ",
            "dates": "January 2017 – October 2018",
            "responsibilities": [
                "Managed the new outsourcing billing service for the firm and clients utilizing Revport and provide subject matter expertise for the Revport implementation project",
                "Communicated on an ongoing basis with executive-level clients and internal operations/technology teams to coordinate synergies",
                "Reconciled client invoices pertaining to their Assets Under Management (AUM) and investment portfolios",
                "Presented project and client status updates in weekly meetings to Senior Management and staff"
            ]
        },
        {
            "title": "Accounts Receivable/Collections/Financial Specialist",
            "company": "Operative Media/Sintec Media",
            "location": "New York, NY",
            "dates": "November 2016 – January 2017",
            "responsibilities": [
                "Oversaw multiple banking websites (J.P. Morgan, Citibank) for payment processing and other transactions",
                "Utilized the GreatPlains application for the A/R and Collection processes and analyzed monthly Google ADX billing",
                "Compiled reports of daily international cash flow for the CFO and further strategized the Collection process for increasing cash flow"
            ]
        },
        {
            "title": "Business System Analyst/Legal Operations Division",
            "company": "American International Group",
            "location": "New York, NY",
            "dates": "April 2016 – November 2016",
            "responsibilities": [
                "Administrated all legal Matter Based Agreement Requests and timekeeper updates received through the Onit application",
                "Maintained, updated, and approved requests in the TeamConnect application",
                "Reported and presented biweekly to executives and departments on the status of transactions"
            ]
        },
        {
            "title": "Billing, Accounts Receivable & Collections Financial Specialist",
            "company": "Midea America & Canada Corporation",
            "location": "Parsippany, NJ",
            "dates": "June 2015 – April 2016",
            "responsibilities": [
                "Enhanced the E-Billing, Accounts Receivables, and Collections processes for MACC and led the MACC Sales Representatives’ Monthly Commissions process",
                "Liaised with Midea IT for needed improvements for Oracle Business System (OBS)/JD Edwards",
                "Processed daily average of 50 cash receipts and wire payments"
            ]
        },
        {
            "title": "Billing System Administrator/Billing Specialist",
            "company": "DTCC",
            "location": "New York, NY",
            "dates": "September 2002 – August 2014",
            "responsibilities": [
                "Governed distribution of the DTC and Omgeo monthly invoices of $50mm a month as the e-billing Team's subject matter expert",
                "Coordinated data files transfers between Metranet Billing System JD Edwards accounting system",
                "Established the 'End-to-End' billing project resulting in more transparency from source systems to customer invoices"
            ]
        }
    ],
    "education": [
        {
            "degree": "Pre-MBA Courses",
            "university": "Fordham University",
            "location": "New York, NY",
            "dates": "September 2012"
        },
        {
            "degree": "IASSC Certified Lean Six Sigma Green Belt, IASSC Certified Lean Six Sigma Yellow Belt",
            "university": "BMGI – Six Sigma Certifications",
            "location": "New York, NY",
            "dates": "September 2010"
        },
        {
            "degree": "Bachelor of Business Administration, Business",
            "university": "CUNY Baruch College",
            "location": "New York, NY",
            "dates": "September 2002"
        }
    ],
    "skills": {
        "Languages": ["English Fluent", "Italian Intermediate", "Spanish Basic"],
        "Community Involvement": ["Chase Corporate Challenges", "America SCORES New York", "Habitat for Humanity"],
        "Applications": ["Oracle Cloud", "Microsoft Office Suite", "Microsoft Visio", "JD Edwards", "Metranet", "TeamConnect", "ONIT", "SAP Business Objects", "Google ADX", "J.P. Morgan Receivables Edge", "GreatPlains", "Salesforce", "Jira", "RevPort", "Fidelity WealthScape", "Charles Schwab", "Ariba", "Coupa"]
    },
    "certifications": [
        {
            "certification": "IASSC Certified Lean Six Sigma Green Belt",
            "institution": "BMGI – Six Sigma Certifications",
            "dates": "September 2010"
        },
        {
            "certification": "IASSC Certified Lean Six Sigma Yellow Belt",
            "institution": "BMGI – Six Sigma Certifications",
            "dates": "September 2010"
        }
    ]
}

export const candidateResumeData3 = {
    "name": "ASHLEY OWENS",
    "location": "18 Sixth Ave. 820, Brooklyn, NY 11217",
    "email": "a.y.owens@outlook.com",
    "phone": "(845)220-0991",
    "summary": "Versatile professional with expertise in logistics optimization, executive support, reception management, transport dispatching, call center operations, and IT. Proven track record in coordinating responsibilities, solving challenges, and delivering exceptional service. Proactive team player dedicated to enhancing operational efficiency and customer experiences across diverse domains.",
    "work_experience": [
        {
            "title": "Administrative Assistant",
            "company": "Tempwork Staffing Solutions, Inc.",
            "location": "8 Spruce, New York",
            "dates": "Jan 2024 — Present",
            "responsibilities": [
                "Greeted and engaged clients, ensuring a positive first impression and exceptional customer service.",
                "Coordinated and conducted property tours, illustrating the appeal of the building and establishing rapport with prospects.",
                "Verified client information during check-ins, entering details into the Residential Management System accurately.",
                "Maintained detailed daily and weekly traffic reports, contributing to the achievement of monthly leasing goals.",
                "Addressed client inquiries regarding the leasing process, ensuring a smooth and informative experience.",
                "Adapted to changing situations, demonstrating flexibility and effective problem-solving skills."
            ]
        },
        {
            "title": "Executive Assistant & IT Technician",
            "company": "Kleechi",
            "location": "Brooklyn, NY",
            "dates": "Jun 2021 — 2023",
            "responsibilities": [
                "Precision in Schedule Coordination for High-Profile Executives",
                "Expertise in Coordinating Luxury Travel Logistics",
                "Mastery in Organizing Exclusive Brand Events",
                "Proficient Communication Liaison for VIP Clients",
                "Diligent Management of Confidential Correspondence",
                "Maintenance Expertise for Cutting-Edge Systems",
                "Advanced Troubleshooting Skills for High-End Technology",
                "Specialized Focus on Network Security for Exclusive Designs",
                "Up-to-date Software Management for Luxury Tech Integration",
                "First-Class User Support for a Refined Customer Experience"
            ]
        },
        {
            "title": "Sales Service Distribution Clerk",
            "company": "United States Postal Service",
            "location": "South Ozone Park, NY",
            "dates": "Feb 2017 — Jun 2021",
            "responsibilities": [
                "Implemented streamlined shipping and receiving protocols, emphasizing efficiency.",
                "Managed incoming and outgoing mail and parcels with precision.",
                "Provided outstanding customer service, handling high-volume calls and emails effectively.",
                "Ensured compliance with postal regulations and documented address changes.",
                "Maintained meticulous records of daily transactions, ensuring accuracy.",
                "Assisted with product sales, PO Box rent, and money orders.",
                "Expertly sorted mail and packages by type and destination while coordinating schemes for incoming mail.",
                "Upheld safe work practices and adherence to established procedures.",
                "Balanced money drawers, handled cash transactions, credit card machine, processed USPS money orders, and other electronic equipment accurately and efficiently",
                "Weighed letters and parcels, computed mailing costs, and affixed correct postage.",
                "Handled customer complaints calmly and professionally, resolving customer issues in a timely manner",
                "Processed mail for international and domestic shipments and ensured compliance with postal regulations",
                "Provided training and guidance to new employees on postal services"
            ]
        },
        {
            "title": "Customer Service Dispatcher",
            "company": "NYC 311 Call Center",
            "location": "New York, NY",
            "dates": "Nov 2014 — Dec 2015",
            "responsibilities": [
                "Conducted in-depth customer service analysis, enhancing overall experience.",
                "Utilized GPS for real-time updates on sanitation vehicles, ensuring efficient communication.",
                "Handled customer complaints professionally, maintained high satisfaction levels.",
                "Monitored weather for snow procedures, prioritized responses during storms.",
                "Acted as a central point for City service requests, optimizing workflow efficiency.",
                "Proactively redirected issues to appropriate departments, monitored social media for emerging concerns.",
                "Utilized advanced technology skills for multi-channel service delivery."
            ]
        },
        {
            "title": "Pet Care Entrepreneur & Sitter",
            "company": "Rover",
            "location": "Brooklyn, NY",
            "dates": "Jan 2016 — Aug 2023",
            "responsibilities": [
                "Managed successful social media business, expanding followers and engaging pet lovers for increased business.",
                "Developed strong client relationships, recruiting and training employees for safe kennel operations.",
                "Implemented effective communication strategies, orchestrated streamlined operations, and handled financial responsibilities.",
                "Planned and executed marketing campaigns, ensuring the safety and well-being of dogs during boarding and daycare.",
                "Achieved consistently high ratings through exemplary service, including walking multiple dogs in various conditions, administering medication, and providing overnight care for various breeds.",
                "Maintained a 5-star rating through outstanding performance and client satisfaction."
            ]
        }
    ],
    "education": {
        "degree": "Computer Science",
        "university": "LaGuardia Community College",
        "location": "New York, NY",
        "dates": "Mar 2014 — Dec 2016"
    },
    "skills": [
        "Adaptability to evolving technologies in a support context",
        "Critical thinking and problem solving",
        "Client relationship building",
        "Reception and Mail Management",
        "Scheduling",
        "Invoicing Expertise",
        "Accounts payable/receivables",
        "Customer service excellence",
        "Proficient in Microsoft Office and support tools",
        "Collaborative team engagement",
        "Coordination of tech logistics and resources",
        "Marketing and branding",
        "Effective time management for timely issue resolution",
        "Analytical problem-solving skills in technology support",
        "Organizational efficiency in handling support processes",
        "Timely reporting of support ticket resolutions",
        "Customer satisfaction"
    ],
    "certifications": [],
    "additional": {}
}

export const candidateResumeData9 = {
    "name": "SHERIKA MOORE",
    "location": "Brooklyn, NY, 11203, US",
    "email": "r7s8h56rxj@privaterelay.appleid.com",
    "phone": "+1 347 463 7044",
    "summary": "Dynamic and dedicated educator experienced with assisting historically underserved students. I am committed to building a community.",
    "work_experience": [
        {
            "title": "Paraprofessional",
            "company": "AHRC Middle High School",
            "location": "Brooklyn, NY",
            "dates": "January 2022 to Present",
            "responsibilities": [
                "Work with teacher to develop learning strategies for success",
                "Use techniques like sign language, braille, or remedial programs to help students learn materials",
                "Help students with social skills",
                "Support teachers by supervising during recess, on field trips, and for other events"
            ]
        },
        {
            "title": "Direct Support Care (DSC)",
            "company": "Life Worc",
            "location": "Queens, NY",
            "dates": "October 2020 to Present",
            "responsibilities": [
                "Administer client’s medications",
                "Accompany clients to and from doctor’s appointments",
                "Plan, coordinate and participate in social, recreational, and community activities.",
                "Encourage growth and recognize the autonomy of clients while reducing risk.",
                "Assists clients with personal care and hygiene needs as needed, including but not limited to: toileting, dining, showering, dressing, and grooming."
            ]
        },
        {
            "title": "Client Support Aide",
            "company": "Heavens Hands Community Service",
            "location": "Brooklyn, NY",
            "responsibilities": [
                "Assist with ADL’s",
                "Ensuring safety of residents",
                "Providing care for residents with disabilities"
            ]
        }
    ],
    "education": {
        "degree": "High School diploma"
    },
    "skills": [
        "Active Listening",
        "Proficient in MS word, Excel, PowerPoint and access",
        "Solid interpersonal and motivational communication",
        "Social Perspective",
        "Critical Thinking",
        "People Skills",
        "De-escalation techniques",
        "Crisis Intervention & Support",
        "Classroom experience",
        "Experience working with students",
        "Special education",
        "Childcare",
        "Data collection",
        "Microsoft Access",
        "Sales"
    ],
    "certifications": [
        {
            "certification": "CNA"
        },
        {
            "certification": "CPR Certification"
        },
        {
            "license": "Driver's License",
            "validity": "November 2023 to October 2033"
        }
    ]
}

export const candidateResumeData10 = {
    "name": "HAROON AHMED",
    "location": "88-04 63rd Drive Rego Park, NY",
    "current_designation": "Financial Billing Operations Analyst",
    "email": "Haroonahmed101@gmail.com",
    "phone": "917-370-6748",
    "summary": "",
    "work_experience": [
        {
            "title": "Financial Billing Operations Analyst",
            "company": "HealthFirst",
            "location": "New York, NY",
            "time_period": "10/2021 to 10/2023",
            "responsibilities": [
                "Identify Variance: Investigate cases of imbalanced cash received against actual billed which leads to recoupments",
                "Profit Analysis: Determine profits margins for all lines of business which lead to strategic positioning by the sales team",
                "SQL Queries: Create refined primary sources of data by developing SQL objects such as tables and views in order to automatically complete data acquisition process for reporting",
                "Quality Assurance: Validate total dollar amount billed based on current state rates to ensure accuracy of billing",
                "Training: Develop new hires by sharing knowledge and processes empowering colleagues to be proficient",
                "Collaborate: Manufactured tracking systems for cross functional teams to identify members' denials that already have been billed in order to eliminate duplicate billing of members."
            ],
            "remaining_related_data": ""
        },
        {
            "title": "Senior Finance Specialist",
            "company": "Riverspring Health",
            "location": "New York, NY",
            "time_period": "08/2018 to 10/2021",
            "responsibilities": [
                "Reporting: Manufactured Microsoft Access reports which summarized weekly capitation and refunds",
                "General Ledger: Prepared month end reports in order to analyze financial goals and cost saving objectives",
                "Billing: Identified denied claims to rebill from the Accounts Receivable monthly billing process in order to maintain as close to 100% payment received",
                "Invoice: Created and maintained a surplus tracking system to view all payments that have been received against capitation that is still pending",
                "Data Accuracy: Identified and updated the monthly change of members billable hours of care in order to visualize revenue on a yearly basis"
            ],
            "remaining_related_data": ""
        },
        {
            "title": "Data Manager",
            "company": "Khan’s Tutorial",
            "location": "New York, NY",
            "time_period": "10/2014 to 10/2017",
            "responsibilities": [
                "Management: Updated weekly schedule by collaborating with employees to ensure all working time slots are filled",
                "Reconciliation: Responsible for reconciling account receivables each week by handling cash and credit transactions",
                "Marketing: Devised strategies to further increase brand awareness to local customers through online resources and pamphlets",
                "Liaison: Acted as subject matter expert in providing ad-hoc reporting to senior management",
                "Sales: Pitched variety of packages to prospective clients in line with client budget and student’s specific needs"
            ],
            "remaining_related_data": ""
        }
    ],
    "education": [
        {
            "degree": "BBA: Finance",
            "university": "Baruch College of the City University of New York",
            "location": "New York, NY",
            "time_period": "2017",
            "remaining_related_data": ""
        }
    ],
    "skills": [
        {
            "heading": "Technical Skills",
            "related_data": [
                "SQL",
                "MS Office",
                "DBeaver",
                "Quickbooks",
                "Monarch Pro",
                "Cash Forecasting",
                "Redshift",
                "Billing",
                "MHS"
            ]
        }
    ],
    "certifications": [
        {
            "certification_details": "",
            "date": "",
            "remaining_related_data": ""
        }
    ],
    "additional": {
        "Websites, Portfolios, Profiles": [
            "https://www.linkedin.com/in/haroon-ahmed-475296121/"
        ]
    }
}

export const candidateResumeData11 = {
    "name": "Joseph Iaconis",
    "location": "446 East 86th Street, New York, NY 10028",
    "current_designation": "Finance Senior Billing Coordinator",
    "email": "josephiaconis22@gmail.com",
    "phone": "(917) 773-5882",
    "summary": "",
    "work_experience": [
        {
            "title": "Finance Senior Billing Coordinator",
            "company": "IPC Systems, Inc.",
            "location": "Jersey City, NJ",
            "time_period": "October 2018 – Present",
            "responsibilities": [
                "Conduct Network Services monthly invoicing process consisting of 1,200 invoices for $10mm using NSB internal billing system",
                "Execute daily bookings in Salesforce by analyzing and ensuring compliance",
                "Process daily transactions/invoices in Oracle Cloud consisting of 8 million dollars per month",
                "Trained coordinators in the U.K., Madrid, and Malaysia on billing, accounts receivable, and collections tasks",
                "Collaborated with Sales, Operations, and IT on various cost/process improvements projects to represent the Finance department with ERP, Portal, and Salesforce system upgrades and enhancements"
            ],
            "remaining_related_data": ""
        },
        {
            "title": "Business Process Outsourcing Financial Analyst",
            "company": "Vestmark, Inc.",
            "location": "Jersey City, NJ",
            "time_period": "January 2017 – October 2018",
            "responsibilities": [
                "Managed the new outsourcing billing service for the firm and clients utilizing Revport and provide subject matter expertise for the Revport implementation project",
                "Communicated on an ongoing basis with executive-level clients and internal operations/technology teams to coordinate synergies",
                "Reconciled client invoices pertaining to their Assets Under Management (AUM) and investment portfolios",
                "Presented project and client status updates in weekly meetings to Senior Management and staff"
            ],
            "remaining_related_data": ""
        },
        {
            "title": "Accounts Receivable/Collections/Financial Specialist",
            "company": "Operative Media/Sintec Media",
            "location": "New York, NY",
            "time_period": "November 2016 – January 2017",
            "responsibilities": [
                "Oversaw multiple banking websites (J.P. Morgan, Citibank) for payment processing and other transactions",
                "Utilized the GreatPlains application for the A/R and Collection processes and analyzed monthly Google ADX billing",
                "Compiled reports of daily international cash flow for the CFO and further strategized the Collection process for increasing cash flow"
            ],
            "remaining_related_data": ""
        },
        {
            "title": "Business System Analyst/Legal Operations Division",
            "company": "American International Group",
            "location": "New York, NY",
            "time_period": "April 2016 – November 2016",
            "responsibilities": [
                "Administrated all legal Matter Based Agreement Requests and timekeeper updates received through the Onit application",
                "Maintained, updated, and approved requests in the TeamConnect application",
                "Reported and presented biweekly to executives and departments on the status of transactions"
            ],
            "remaining_related_data": ""
        },
        {
            "title": "Billing, Accounts Receivable & Collections Financial Specialist",
            "company": "Midea America & Canada Corporation",
            "location": "Parsippany, NJ",
            "time_period": "June 2015 – April 2016",
            "responsibilities": [
                "Enhanced the E-Billing, Accounts Receivables, and Collections processes for MACC and led the MACC Sales Representatives’ Monthly Commissions process",
                "Liaised with Midea IT for needed improvements for Oracle Business System (OBS)/JD Edwards",
                "Processed daily average of 50 cash receipts and wire payments"
            ],
            "remaining_related_data": ""
        },
        {
            "title": "Billing System Administrator/Billing Specialist",
            "company": "DTCC",
            "location": "New York, NY",
            "time_period": "September 2002 – August 2014",
            "responsibilities": [
                "Governed distribution of the DTC and Omgeo monthly invoices of $50mm a month as the e-billing Team's subject matter expert",
                "Coordinated data files transfers between Metranet Billing System JD Edwards accounting system",
                "Established the 'End-to-End' billing project resulting in more transparency from source systems to customer invoices"
            ],
            "remaining_related_data": ""
        }
    ],
    "education": [
        {
            "degree": "Pre-MBA Courses",
            "university": "Fordham University",
            "location": "New York, NY",
            "time_period": "September 2012",
            "remaining_related_data": ""
        },
        {
            "degree": "IASSC Certified Lean Six Sigma Green Belt, IASSC Certified Lean Six Sigma Yellow Belt",
            "university": "BMGI – Six Sigma Certifications",
            "location": "New York, NY",
            "time_period": "September 2010",
            "remaining_related_data": ""
        },
        {
            "degree": "Bachelor of Business Administration, Business",
            "university": "CUNY Baruch College",
            "location": "New York, NY",
            "time_period": "September 2002",
            "remaining_related_data": ""
        }
    ],
    "skills": [
        {
            "heading": "Languages",
            "related_data": [
                "English Fluent",
                "Italian Intermediate",
                "Spanish Basic"
            ]
        },
        {
            "heading": "Community Involvement",
            "related_data": [
                "Chase Corporate Challenges",
                "America SCORES New York",
                "Habitat for Humanity"
            ]
        },
        {
            "heading": "Applications",
            "related_data": [
                "Oracle Cloud",
                "Microsoft Office Suite",
                "Microsoft Visio",
                "JD Edwards",
                "Metranet",
                "TeamConnect",
                "ONIT",
                "SAP Business Objects",
                "Google ADX",
                "J.P. Morgan Receivables Edge",
                "GreatPlains",
                "Salesforce",
                "Jira",
                "RevPort",
                "Fidelity WealthScape",
                "Charles Schwab",
                "Ariba",
                "Coupa"
            ]
        }
    ],
    "certifications": [
        {
            "certification_details": "",
            "date": "",
            "remaining_related_data": ""
        }
    ],
    "additional": ""
}

export const candidateResumeData12 = {
    "name": "Victoria Ochs",
    "location": "Montvale, NJ 07645",
    "current_designation": "Registered Dietitian",
    "email": "Victoria.Ochs.2@gmail.com",
    "phone": "(845) 538-0047",
    "summary": {
        "current_position_duration": "2021-Present"
    },
    "work_experience": [
        {
            "title": "Dietitian",
            "company": "Good Samaritan Hospital",
            "location": "Suffern, NY",
            "time_period": "July 2022-Present",
            "responsibilities": [
                "Designated Dietitian for the Med-Surg, Detox-Rehabilitation Units, and Oncology Outpatient.",
                "Screen in new admissions using the MST screening tool.",
                "Complete full nutritional assessments using the EMR to analyze and document the nutrition care process for Positive Nutrition Screen, Consults, Low BMI, Wounds, Enteral/Parenteral Nutrition recommendations, Calorie Counts, etc.",
                "Perform Nutritional Physical Focused Findings on new patients who may have developed mild, moderate, or severe malnutrition.",
                "Provide nutritional education/counseling for patients on special diets (Cardiac, Renal, Diabetic, Low Fiber, GI bland, etc)",
                "Teach a monthly Behavioral Health Class providing resources and handouts on the nutritional impact (vitamin deficiencies/symptoms) one may experience during a time of detox.",
                "Participate in a Food Service weekly meetings-discussing updates on clinical, food production, food sanitation, safety, and employment."
            ],
            "remaining_related_data": {
                "hours_per_week": 40
            }
        },
        {
            "title": "Dietitian",
            "company": "The Grove at Valhalla Rehabilitation and Nursing Center, NutraCo",
            "location": "Valhalla, NY",
            "time_period": "December 2021-July 2022",
            "responsibilities": [
                "Overseen 40 Rehab beds and 40 long term beds.",
                "Educated Residents on Medical Nutrition Therapy when necessary.",
                "Attended daily IDT morning meetings, Pressure Injury Prevention (PIP) meetings, and Care Plan meetings for Long Term Care and Short Term Care.",
                "Preformed Nutrition Physical Focused Findings on new residents for MDS- Patient Driven Payment Model (PDPM).",
                "Documented on tube feeding and dialysis progress notes, weekly weights and wound notes; Monthly weight audit on Long Term Care."
            ],
            "remaining_related_data": {
                "hours_per_week": 40
            }
        },
        {
            "title": "Dietitian",
            "company": "Lutheran Care Center- Sodexo",
            "location": "Poughkeepsie, NY",
            "time_period": "November 2020 - August 2021",
            "responsibilities": [
                "Overseen 80 long term beds and 20 Rehab beds.",
                "Attended daily IDT morning meetings, Pressure Injury Prevention (PIP) meetings, and Care Plan meetings for Long Term Care and Short Term Care.",
                "Documented on tube feeding progress notes.",
                "Preformed Nutrition Physical Focused Findings on new residents for MDS- Patient Driven Payment Model (PDPM).",
                "Weekly weights and wound notes; Monthly weight audit on Long Term Care.",
                "Completed a dining observation audit and waste reduction audit - Fortified foods, PO supplements as per Sodexo’s KPI (Performance Improvement)."
            ],
            "remaining_related_data": {
                "hours_per_week": 30
            }
        }
    ],
    "education": [
        {
            "degree": "",
            "university": "Priority Nutrition Care (PNC) Distance Dietetic Internship",
            "location": "",
            "time_period": "January 2019 - August 2019",
            "remaining_related_data": {
                "details": [
                    "8 Month Distance Program",
                    "1200 Hours of supervised practice and coursework (see Career Related Experience)",
                    "Consecutive Top Scores for Rotation Assignments"
                ]
            }
        },
        {
            "degree": "Bachelor of Science, Nutrition and Food Science, concentration in Dietetics",
            "university": "Montclair State University",
            "location": "Montclair, NJ",
            "time_period": "December 2018",
            "remaining_related_data": {
                "achievements": [
                    "Dean’s List 2018, GPA 3.59"
                ]
            }
        }
    ],
    "skills": {
        "heading": "",
        "related_data": [
            "Microsoft Excel",
            "Word",
            "PowerPoint",
            "Dietitian Electronic Health Records (EPIC, Point Click Care, Visual)"
        ]
    },
    "certifications": [
        {
            "certification_details": "ServSafe Certified",
            "date": "",
            "remaining_related_data": ""
        }
    ],
    "additional": {
        "career_related_experience": [
            {
                "company": "ShopRite",
                "location": "Ramsey, NJ",
                "position": "Community Dietetic Intern",
                "duration": "March - May 2019 (8 weeks) & July - August 2019 (6 weeks)",
                "responsibilities": [
                    "Assisted dietitian with planning, marketing and facilitating monthly nutrition events such as store tours and cooking classes.",
                    "Observed counseling sessions with customers and regarding weight loss, diabetes, and other health conditions.",
                    "Spoke to a group of young adults on nutritional meal planning."
                ]
            },
            {
                "company": "Care-One of Oradell",
                "location": "Oradell, NJ",
                "position": "Clinical Dietetic Intern",
                "duration": "January - February 2019 (8 weeks)",
                "responsibilities": [
                    "Completed nutritional assessments (under supervision of clinical dietitians) on patients using data obtained from their diet history, psychosocial information, anthropometric measurements and laboratory tests.",
                    "Evaluated each resident on quarterly & annual basis to determine nutritional changes.",
                    "Presented a case study on Cardiovascular Disease and Anemia to faculty dieticians."
                ]
            },
            {
                "position": "Food Service Dietetic Intern",
                "duration": "May - June 2019 (8 weeks)",
                "responsibilities": [
                    "Directly assisted patients with meal selections, delivered meals to patients’ rooms and assisted serving buffet food.",
                    "Responsible for ensuring patients received correct menu options based on the assigned therapeutic diet."
                ]
            }
        ],
        "volunteer_experience": [
            {
                "company": "ShopRite",
                "position": "Dietitian Volunteer",
                "location": "Ramsey, NJ",
                "duration": "March 2017 - December 2018"
            },
            {
                "company": "Valley Hospital",
                "position": "Dietitian Volunteer",
                "location": "Ridgewood, NJ",
                "duration": "March 2017 - June 2018"
            },
            {
                "company": "City Green",
                "position": "Volunteer",
                "location": "Clifton, NJ",
                "duration": "September 2016 - May 2017"
            }
        ],
        "affiliations_certifications": [
            {
                "organization": "Montclair State Dietetics Association",
                "status": "Active Member",
                "duration": "September 2016 - May 2017"
            }
        ]
    }
}

export const candidateResumeData13 = {
    "name": "Ingrid Priscilla Wiggberg",
    "location": "",
    "current_designation": "",
    "email": "ingrid.priscilla.w@gmail.com",
    "phone": "(347) 786-3256",
    "summary": "",
    "work_experience": [
        {
            "title": "Event Assistant Coordinator",
            "company": "Psychoanalytic Association of New York, New York, NY",
            "location": "",
            "time_period": "6/2023 to Present",
            "responsibilities": [
                "Arrange all onsite coordination and logistics for association members for workshops, classes, conferences, and receptions",
                "Send all relevant correspondence to instructors and professional association members and instructors"
            ],
            "remaining_related_data": ""
        },
        {
            "title": "Catering Staff, Team Lead, Server, Brand Ambassador, Coat Check Attendant",
            "company": "Cutting Edge Elite, New York, NY",
            "location": "",
            "time_period": "5/2018 to Present",
            "responsibilities": [
                "Direct catering team, assign tasks and roles to team members on day of event",
                "Write and submit event reports after every event",
                "Deliver above-par service to clients and guests by serving, bartending, assisting in event setup and cleanup"
            ],
            "remaining_related_data": ""
        },
        {
            "title": "Associate Event Manager",
            "company": "Gourmet Advisory Services, New York, NY",
            "location": "",
            "time_period": "2/2023 to 3/2023",
            "responsibilities": [
                "Coordinated events onsite, verified invoices with vendors during setup to ensure all ordered items were onsite and in the correct quantities",
                "Attended tastings, site tours, and vendor meetings for upcoming events",
                "Assembled event-day folders, took thorough notes on video calls with clients, and responded promptly to emails"
            ],
            "remaining_related_data": ""
        },
        {
            "title": "Event Operations Manager",
            "company": "Studio 525, New York, NY",
            "location": "",
            "time_period": "4/2022 to 2/2023",
            "responsibilities": [
                "Conducted site tours to prospective clients and online inquiries",
                "Cultivated and maintained vendor relationships",
                "Oversaw day-to-day operations of venue"
            ],
            "remaining_related_data": ""
        },
        {
            "title": "Lead NYC Coordinator",
            "company": "Kevin Lee Weddings, New York, NY",
            "location": "",
            "time_period": "10/2022 to 2/2023",
            "responsibilities": [
                "Assisted couples with logistics, planning, and contracts leading up to their wedding",
                "Lead and assigned duties to other day-of coordinators, enforced wedding-day timeline",
                "Provided impeccable service to the couple, bridal party, and family throughout the day of the wedding"
            ],
            "remaining_related_data": ""
        },
        {
            "title": "Intern Event Coordinator",
            "company": "Vision Events Co, New York, NY",
            "location": "",
            "time_period": "5/2021 to 9/2021",
            "responsibilities": [
                "Assisted in planning weddings, nonprofit event for the Covenant Foundation, and Melee The Show",
                "Conducted vendor research, gathered price quotes, arranged event floorplans, developed event timelines, coordinated transportation",
                "Served as bridal attendant, registration, and exhibitor liaison on the day of event(s)"
            ],
            "remaining_related_data": ""
        },
        {
            "title": "Wedding Coordinator, Marriage Officiant, Emcee",
            "company": "Kreative Drive, Bayonne, NJ",
            "location": "",
            "time_period": "4/2014 to 12/2016",
            "responsibilities": [
                "Executed plans by confirming food orders, florals, playlists, and room setup with Royal Caribbean staff",
                "Served as bridal concierge to ensure all needs of the bride were met throughout the day",
                "Officiated wedding ceremonies, served as emcee during weddings, announcing key moments points during reception"
            ],
            "remaining_related_data": ""
        },
        {
            "title": "Business Development",
            "company": "Blu Garden Events, New York, NY",
            "location": "",
            "time_period": "2/2014 to 6/2016",
            "responsibilities": [
                "Assisted in planning and execution of New York Bridal & Quinceanera Expo (NYBQE), AfroLatino Festival, and networking events",
                "Developed relationships with locally based event planners, vendors, and suppliers to raise awareness of events",
                "Engaged in community outreach, sold booths to vendors, served as exhibitor liaison"
            ],
            "remaining_related_data": ""
        }
    ],
    "education": {
        "degree": "Masters of Science (MS) in Event Management",
        "university": "New York University, School of Professional Studies, New York, NY",
        "location": "",
        "time_period": "May 2022",
        "remaining_related_data": {
            "Relevant Courses": [
                "Facilities & Venue Management",
                "Permitting for Events",
                "Managing Contracts and Risks",
                "Project Management",
                "Event Production & Design",
                "Event Marketing",
                "Festivals and Social Events"
            ]
        }
    },
    "skills": {
        "heading": "",
        "related_data": ""
    },
    "certifications": {
        "certification_details": "Candidate for Certified Meeting Professional (CMP) Certification",
        "date": "",
        "remaining_related_data": {
            "Certification Status": "In Progress"
        }
    },
    "additional": {
        "LinkedIn Profile": "https://www.linkedin.com/in/ingrid-priscilla-wiggberg-a92619a/",
        "Professional Involvement": [
            {
                "Organization": "Meeting Professionals International, Greater New York Chapter, New York, NY",
                "Duration": "9/2021 to Present",
                "Positions": [
                    {
                        "Title": "Fundraising Director",
                        "Term": "7/2023 to 6/2024",
                        "Responsibilities": [
                            "Delegate tasks to fundraising committee",
                            "seek out venues for annual event",
                            "obtain donations from sponsors for chapter auction event"
                        ]
                    },
                    {
                        "Title": "Co-Chair of Power of Two Mentorship Program",
                        "Term": "10/2022 to 6/2023"
                    }
                ]
            },
            {
                "Organization": "Toastmasters International, New York, NY",
                "Duration": "2/2018 to 9/2020",
                "Positions": [
                    {
                        "Title": "Area Director, Area 72",
                        "Term": "7/2019-6/2020"
                    },
                    {
                        "Title": "Vice President Education, Advanced Expressions Club",
                        "Term": "7/2019-6/2020"
                    },
                    {
                        "Title": "President, Advanced Expressions Club",
                        "Term": "7/2018-6/2019",
                        "Responsibilities": [
                            "Chaired speech contests at the club, area, division, and district levels",
                            "Mentored club members on their public speaking technique, speech delivery, and overall presentation",
                            "Planned monthly club mixers and outings, organized executive committee meetings"
                        ]
                    }
                ]
            },
            {
                "Organization": "Brides Against Breast Cancer, New York, NY",
                "Duration": "3/2013 to 10/2015",
                "Position": "City Coordinator",
                "Responsibilities": [
                    "Promoted local BABC events throughout NYC and NJ via fliers, social media, and community outreach",
                    "Facilitated activities on the day of BABC fundraising events, assisted brides in dress shopping"
                ]
            }
        ]
    }
}

const candidateResumeData14 = {
    "name": "S u l l y a n n  S a e z",
    "current_designation": "C a s e  M a n a g e r",
    "location": "Queens, New York",
    "phone": "347-299-8370",
    "email": "ssullyann@gmail.com",
    "summary": "Compassionate Case Manager accustomed to treating diverse patient needs while managing dynamic conditions. Collaborative professional possessing excellent clinical knowledge and judgment combined with strong documentation and problem-solving abilities. Well-versed in managing emergent cases with poise.",
    "education": [
        {
            "university": "Marymount Manhattan College",
            "location": "New York, N.Y",
            "time_period": "May 2019",
            "degree": "Bachelor Degree of Science in Sociology",
            "remaining_related_data": ""
        },
        {
            "university": "Marymount Manhattan College",
            "location": "New York, N.Y",
            "time_period": "May 2014",
            "degree": "Associate Degree of Science in Sociology",
            "remaining_related_data": ""
        }
    ],
    "work_experience": [
        {
            "time_period": "March 2023- Current",
            "title": "Case Manager",
            "company": "Acacia Network",
            "location": "",
            "responsibilities": "",
            "remaining_related_data": ""
        },
        {
            "time_period": "October 2006- December 2018",
            "title": "Program Facilitator",
            "company": "Bedford Academy",
            "location": "",
            "responsibilities": "Facilitated communication between clients, families, care givers, social services and other agencies to ensure client needs were met. Maintained accurate case records and documentation according to agency guidelines. Assessed clients needs, developed service plans and monitored progress towards goals. Created an engaging learning atmosphere through interactive activities. Compiled reports on cases and submitted them to supervisors as required.",
            "remaining_related_data": ""
        }
    ],
    "skills": [
        {
            "heading": "Key Skills",
            "related_data": [
                "Bilingual",
                "Social work",
                "Positive Attitude",
                "Group Therapy",
                "Case Planning",
                "Assessments",
                "Client Advocacy"
            ]
        }
    ],
    "certifications": [
        {
            "certification_details": "",
            "date": "",
            "remaining_related_data": ""
        }
    ],
    "additional": {
        "Leadership": "Established positive relationships with community partners to expand programming opportunities. Coordinated reentry programs for youths and adults learners accessing community resources.",
        "References": "Available upon request."
    }
}