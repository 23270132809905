import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { requestPassword } from '../core/_requests'

const initialValues = {
    email: 'admin@demo.com',
}

const forgotPasswordSchema = Yup.object().shape({
    password: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Password is required")
})

export const HalLogin = () => {

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
    const [password, setPassword] = useState<string>("")

    const setCookie = (name: string, value: string) => {
        const expirationTime = new Date(Date.now() + 60 * 60 * 1000);
        document.cookie = `${name}=${value}; expires=${expirationTime.toUTCString()}; path=/`;
    };

    const handleLogin = (e: any) => {
        e.preventDefault()
        if (password == "hal1234") {
            setCookie('_Secure-Hal', 'K3V9XN7C4ZPUBQ5ODJRAS02EW1IGYHFMTL68');
            setTimeout(() => {
                navigate('/R3fW0QdYNlGmKp7e')
            }, 1000);
        }
    }

    return (
        <div className='card'>
            <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_password_reset_form'
            >
                <div className='text-center mb-10'>
                    {/* begin::Title */}
                    <h1 className='text-dark fw-bolder mb-3'>HAL Login</h1>
                    {/* end::Title */}

                    {/* begin::Link */}
                    <div className='text-gray-500 fw-semibold fs-6'>
                        Enter your password.
                    </div>
                    {/* end::Link */}
                </div>

                {/* begin::Title */}
                {hasErrors === true && (
                    <div className='mb-lg-15 alert alert-danger'>
                        <div className='alert-text font-weight-bold'>
                            Sorry, looks like there are some errors detected, please try again.
                        </div>
                    </div>
                )}

                {hasErrors === false && (
                    <div className='mb-10 bg-light-info p-8 rounded'>
                        <div className='text-info'>Sent password reset. Please check your email</div>
                    </div>
                )}
                {/* end::Title */}

                {/* begin::Form group */}
                <div className="fv-row mb-3">
                    <label className='form-label fw-bolder text-gray-900 fs-6'>
                        Password
                    </label>
                    <input
                        className='form-control bg-transparent'
                        type="password"
                        autoComplete="off"
                        value={password}
                        onChange={(e) => { setPassword(e.target.value) }}
                    />
                </div>
                {/* end::Form group */}

                {/* begin::Form group */}
                <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                    <button type='submit' id='kt_password_reset_submit' className='btn btn-success me-4' onClick={(e) => handleLogin(e)}>
                        <span className='indicator-label'>Submit</span>
                        {loading && (
                            <span className='indicator-progress'>
                                Please wait...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
                {/* end::Form group */}
            </form>
        </div>
    )
}
