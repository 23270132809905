// import { candidateResumeData10 as candidateResumeData } from './resumeData';
import './template1.css';

export default function Template1({ candidateResumeData }) {


    // Function to check if a key is empty
    function isKeyEmpty(obj, key) {
        return !obj.hasOwnProperty(key) || isEmpty(obj[key]);
    }

    // Function to check if a value is empty
    function isEmpty(value) {
        return (
            value === null ||
            value === undefined ||
            (typeof value === "string" && value.trim() === "") ||
            (Array.isArray(value) && value.length === 0) ||
            (typeof value === "object" && Object.keys(value).length === 0)
        );
    }

    // Helper function to capitalize and replace underscores with spaces
    function capitalizeAndReplace(str) {
        return str.charAt(0).toUpperCase() + str.slice(1).replace(/_/g, " ");
    }

    // Replace with - and space around it
    function addSpacesAroundDash(str) {
        // Check if the string contains a dash with spaces around it
        if (str.includes(" - ")) {
            str = str.replace(/ - /g, "-");
        }
        if (str.includes(" -")) {
            str = str.replace(/ -/g, "-");
        }
        if (str.includes("- ")) {
            str = str.replace(/- /g, "-");
        }

        // Check if the string contains an en dash with spaces around it
        if (str.includes(" – ")) {
            str = str.replace(/ – /g, "-");
        }
        if (str.includes(" –")) {
            str = str.replace(/ –/g, "-");
        }
        if (str.includes("– ")) {
            str = str.replace(/– /g, "-");
        }

        // Check if the string contains ' to ' with spaces around it
        if (str.includes(" to ")) {
            str = str.replace(/ to /g, " - ");
        }

        return str;
    }

    // Helper function to render value based on its type
    function renderValue(value) {
        if (typeof value === "string" || typeof value === "number") {
            return <span className='template-content'>{value}</span>;
        } else if (Array.isArray(value)) {
            return (
                <ul>
                    {value.map((item) =>
                        typeof item === "string" ? (
                            <li key={item}><span className='template-content'>{item}</span></li>
                        ) : (
                            <li key={item}><span style={{ marginLeft: -10 }}>{renderValue(item)}</span></li>
                        )
                    )}
                </ul>
            );
        } else if (typeof value === "object") {
            return (
                <ul>
                    {Object.entries(value).map(([key, val]) => (
                        <li key={key}>
                            <span className='fw-bold template-content'>{capitalizeAndReplace(key)}: </span>
                            <span className='template-content'>{renderValue(val)}</span>
                        </li>
                    ))}
                </ul>
            );
        }
        return null;
    }

    // Function to render the header section
    function renderHeaderSection() {
        return (
            <div className="">
                <div className='d-flex justify-content-between align-items-start'>
                    <p></p>
                    <img src="https://formrecognizer12345.blob.core.windows.net/logos/3pconsultants-logo.svg" alt="Thirdeye data" height="120px" />
                </div>
                <p className='fs-5 mt-2 text-center'>
                    {candidateResumeData.current_designation ? `- ${candidateResumeData.current_designation}` : ``}
                    {candidateResumeData.name}
                </p>
            </div>
        );
    }

    // Function to render the resume header section
    function renderResumeHeaderSection() {
        return (
            <section>
                <div className="table-heading">RESUME</div>
                <hr />
                <div className="fs-7">(AS DRAFTED BY {candidateResumeData.name})</div>
            </section>
        );
    }

    // Function to render profile section
    function renderObjectiveSection() {
        return (
            <section>
                <div className='row mb-0'>
                    <p className='fw-bold mb-0 pb-0'>OBJECTIVE:</p>
                </div>
                {(!isKeyEmpty(candidateResumeData, "objective")) && (
                    <>
                        {renderValue(candidateResumeData.objective)}
                        <br />
                    </>
                )}
            </section>
        );
    }

    // Function to render personal details table
    function renderPersonalDetailsTable() {
        return (
            <section className="my-4">
                <div className="table-heading">PERSONAL DETAILS</div>
                <hr />
                <table>
                    <tbody>
                        <tr>
                            <td>Date of Birth/Age</td>
                            <td>{candidateResumeData.dob ? candidateResumeData.dob : ''}</td>
                        </tr>
                        <tr>
                            <td>Current Location</td>
                            <td>{candidateResumeData.location ? candidateResumeData.location : ''}</td>
                        </tr>
                        <tr>
                            <td>Compensation Per Annum</td>
                            <td>{candidateResumeData.ctc ? candidateResumeData.ctc : ''}</td>
                        </tr>
                        <tr>
                            <td>Expected CTC Per Annum</td>
                            <td>{candidateResumeData.expectedCtc ? candidateResumeData.expectedCtc : ''}</td>
                        </tr>
                        <tr>
                            <td>Notice Period</td>
                            <td>{candidateResumeData.noticePeriod ? candidateResumeData.noticePeriod : ''}</td>
                        </tr>
                        <tr>
                            <td>Family Details</td>
                            <td>{candidateResumeData.family ? candidateResumeData.family : ''}</td>
                        </tr>
                    </tbody>
                </table>
            </section>
        );
    }

    // Function to render profile section
    function renderProfileSection() {
        return (
            <section>
                <div className='row mb-0'>
                    <p className='fw-bold mb-0 pb-0'>PROFILE SUMMARY</p>
                </div>
                {(!isKeyEmpty(candidateResumeData, "summary")) && (
                    <>
                        {renderValue(candidateResumeData.summary)}
                        <br />
                    </>
                )}
            </section>
        );
    }


    // Function to render work experience table
    function renderExperienceTable() {
        if (!isKeyEmpty(candidateResumeData, "work_experience")) {
            const { work_experience } = candidateResumeData;

            if (Array.isArray(work_experience)) {
                if (work_experience.every((exp) => isEmptyExperience(exp))) {
                    return null;
                }

                // Calculate total time period
                const firstExpTime = new Date(
                    work_experience[0].time_period.split("–")[0].trim()
                );
                const lastExpTime = new Date(
                    work_experience[work_experience.length - 1].time_period
                        .split("-")[1]
                        .trim()
                );
                const totalExperience = calculateTotalExperience(
                    firstExpTime,
                    lastExpTime
                );

                const experienceHTML = work_experience.map(renderExperienceDetailsInTable);

                return (
                    <section className="my-4">
                        <div className="table-heading">CAREER PATH: {totalExperience}</div>
                        <hr />
                        <table>
                            <thead>
                                <tr>
                                    <th>Year</th>
                                    <th>Organization</th>
                                    <th>Designation</th>
                                </tr>
                            </thead>
                            <tbody>
                                {experienceHTML}
                            </tbody>
                        </table>
                    </section>
                );
            }
        }
        return null;
    }

    // Function to render each work experience item as a table row
    function renderExperienceDetailsInTable(exp) {
        const { title, company, time_period } = exp;
        return (
            <tr key={time_period}>
                <td>{time_period}</td>
                <td>{company}</td>
                <td>{title}</td>
            </tr>
        );
    }

    // Function to calculate total experience duration
    function calculateTotalExperience(firstDate, lastDate) {
        const diffTime = Math.abs(lastDate - firstDate);
        const diffYears = diffTime / (1000 * 60 * 60 * 24 * 365);
        const years = Math.floor(diffYears);
        const months = Math.floor((diffYears - years) * 12);
        return `TOTAL ${years} YEARS & ${months} MONTHS`;
    }

    // Function to render work experience section
    function renderExperienceSection() {
        if (!isKeyEmpty(candidateResumeData, "work_experience")) {
            const { work_experience } = candidateResumeData;

            if (Array.isArray(work_experience)) {
                if (work_experience.every((exp) => isEmptyExperience(exp))) {
                    return null;
                }

                const experienceHTML = work_experience.map(renderExperienceDetails);

                return (
                    <section>
                        {renderExperienceHeader()}
                        {experienceHTML}
                    </section>
                );
            }
        }
        return null;
    }

    // Function to render experience header
    function renderExperienceHeader() {
        return (
            <div className='row'>
                <p className='fw-bold'>EMPLOYMENT SCAN</p>
            </div>
        );
    }

    // Function to render each work experience item
    function renderExperienceDetails(exp) {
        const {
            title,
            company,
            location,
            time_period,
            responsibilities,
            remaining_related_data,
        } = exp;

        return (
            <div key={time_period}>
                <div className='d-flex justify-content-start'>
                    {!company && !location ? (
                        <div>
                            <p className='p-0 m-0 fw-bold'>{title} {addSpacesAroundDash(time_period)}</p>
                        </div>
                    ) : (
                        <div>
                            <p className='p-0 m-0 fw-bold'>
                                {company} {location ? `, ${location}` : ""}
                                {time_period ? `, ${addSpacesAroundDash(time_period)}` : ""}
                            </p>
                            <p className='p-0 m-0 fw-bold'>{title}</p>
                        </div>
                    )}
                </div>
                {renderValue(responsibilities)}
                {renderValue(remaining_related_data)}
                <br />
            </div>
        );
    }

    // Function to check if a work experience item is empty
    function isEmptyExperience(exp) {
        const {
            title,
            company,
            location,
            time_period,
            responsibilities,
            remaining_related_data,
        } = exp;
        if (
            typeof title === "string" &&
            title.trim() === "" &&
            typeof company === "string" &&
            company.trim() === "" &&
            typeof location === "string" &&
            location.trim() === "" &&
            typeof time_period === "string" &&
            time_period.trim() === "" &&
            typeof responsibilities === "string" &&
            responsibilities.trim() === "" &&
            typeof remaining_related_data === "string" &&
            remaining_related_data.trim() === ""
        ) {
            return true;
        }
        return false;
    }

    function renderAdditionalExperienceData(experience) {
        return Object.entries(experience).map(([key, value]) => {
            if (!['title', 'location', 'time_period', 'company', 'responsibilities', 'remaining_related_data'].includes(key)) {
                return (
                    <div key={key}>
                        <p className='p-0 m-0 fw-bold'>{key}: </p>
                        {renderValue(value)}
                    </div>
                );
            }
            return null;
        });
    }

    // Function to render certifications section
    function renderCertificationsSection() {
        if (!isKeyEmpty(candidateResumeData, "certifications")) {
            const certifications = candidateResumeData.certifications;
            if (
                Array.isArray(certifications) &&
                certifications.some(
                    (certification) =>
                        certification.certification_details.trim() !== ""
                )
            ) {
                const certificationsHTML = certifications.map(renderCertification);
                return (
                    <section>
                        <div className='row'>
                            <p className='fw-bold  mb-0 pb-0'>CERTIFICATIONS</p>
                        </div>
                        <ul>
                            {certificationsHTML}
                        </ul>
                    </section>
                );
            } else if (
                !Array.isArray(certifications) &&
                certifications.certification_details.trim() !== ""
            ) {
                return (
                    <section>
                        <div className='row'>
                            <p className='fw-bold  mb-0 pb-0'>CERTIFICATIONS:</p>
                        </div>
                        <ul>
                            <li>
                                <p className='p-0 m-0'>
                                    {certifications.certification_details}
                                    {certifications.date ? `● ${certifications.date}` : ""}
                                </p>
                            </li>
                        </ul>
                    </section>
                );
            }
        }
        return null;
    }

    // Function to render each certification item
    function renderCertification(certification, index) {
        return (
            <li key={index}>
                <p className='p-0 m-0'>
                    {certification.certification_details}
                    {certification.date ? `● ${certification.date}` : ""}
                </p>
                {certification.remaining_related_data &&
                    typeof certification.remaining_related_data === "object" &&
                    Object.keys(certification.remaining_related_data).length !== 0 && (
                        <p>{renderValue(certification.remaining_related_data)}</p>
                    )}
            </li>
        );
    }

    // Function to render education table
    function renderEducationTable() {
        if (!isKeyEmpty(candidateResumeData, "education")) {
            const { education } = candidateResumeData;

            if (Array.isArray(education)) {
                if (education.every((edu) => isEmptyEducation(edu))) {
                    return null;
                }

                const educationHTML = education.map(renderEducationDetailsInTable);

                return (
                    <section className="my-4">
                        <div className="table-heading">EDUCATION</div>
                        <hr />
                        <table border="1">
                            <thead>
                                <tr>
                                    <th>Year</th>
                                    <th>Qualification</th>
                                    <th>Institution / University</th>
                                </tr>
                            </thead>
                            <tbody>
                                {educationHTML}
                            </tbody>
                        </table>
                    </section>
                );
            } else if (typeof education === "object") {
                const {
                    university,
                    location,
                    time_period,
                    degree,
                    remaining_related_data,
                    ...additionalData
                } = education;
                if (
                    isEmptyEducation({
                        university,
                        location,
                        time_period,
                        degree,
                        remaining_related_data,
                        ...additionalData,
                    })
                ) {
                    return null;
                }

                return (
                    <section className="my-4">
                        <div className="table-heading">EDUCATION</div>
                        <hr />
                        <table border="1">
                            <thead>
                                <tr>
                                    <th>Year</th>
                                    <th>Qualification</th>
                                    <th>Institution / University</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderEducationDetailsInTable({
                                    university,
                                    location,
                                    time_period,
                                    degree,
                                    remaining_related_data,
                                    ...additionalData,
                                })}
                            </tbody>
                        </table>
                    </section>
                );
            }
        }
        return null;
    }

    // Function to render each education item as a table row
    function renderEducationDetailsInTable(edu) {
        const { university, location, time_period, degree } = edu;

        return (
            <tr key={time_period}>
                <td>{time_period}</td>
                <td>{degree}</td>
                <td>{university} {location ? `, ${location}` : ""}</td>
            </tr>
        );
    }


    // Function to render education section
    function renderEducationSection() {
        if (!isKeyEmpty(candidateResumeData, "education")) {
            const { education } = candidateResumeData;

            if (Array.isArray(education)) {
                if (education.every((edu) => isEmptyEducation(edu))) {
                    return null;
                }

                const educationHTML = education.map(renderEducationDetails).join("");

                return (
                    <section>
                        {renderEducationHeader()}
                        {educationHTML}
                    </section>
                );
            } else if (typeof education === "object") {
                const {
                    university,
                    location,
                    time_period,
                    degree,
                    remaining_related_data,
                    ...additionalData
                } = education;
                if (
                    isEmptyEducation({
                        university,
                        location,
                        time_period,
                        degree,
                        remaining_related_data,
                        ...additionalData,
                    })
                ) {
                    return null;
                }

                return (
                    <section>
                        {renderEducationHeader()}
                        <div>
                            {renderEducationDetails({
                                university,
                                location,
                                time_period,
                                degree,
                                remaining_related_data,
                                ...additionalData,
                            })}
                        </div>
                    </section>
                );
            }
        }
        return null;
    }

    // Function to render education header
    function renderEducationHeader() {
        return (
            <div className='row'>
                <p className='fw-bold  mb-0 pb-0'>EDUCATION:</p>
            </div>
        );
    }

    // Function to render each education item
    function renderEducationDetails(edu) {
        const {
            university,
            location,
            time_period,
            degree,
            remaining_related_data,
            ...additionalData
        } = edu;

        return (
            <div key={`${university}-${time_period}`}>
                <p className='p-0 m-0 fw-bold'>{university} {location ? `● ${location}` : ""} {time_period ? `● ${time_period}` : ""}</p>
                <p className='p-0 m-0 fw-bold fst-italic'>{degree}</p>
                {renderValue(remaining_related_data)}
                {renderAdditionalEducationData(additionalData)}
                <br />
            </div>
        );
    }

    // Function to render additional data for each education item
    function renderAdditionalEducationData(edu) {
        return Object.entries(edu)
            .map(([key, value]) => {
                if (![
                    "university",
                    "location",
                    "time_period",
                    "degree",
                    "remaining_related_data",
                ].includes(key)) {
                    return (
                        <div key={key}>
                            <p className='p-0 m-0 fw-bold'>{key}: </p>
                            {renderValue(value)}
                        </div>
                    );
                }
                return null;
            });
    }

    // Function to check if an education item is empty
    function isEmptyEducation(edu) {
        const {
            university,
            location,
            time_period,
            degree,
            remaining_related_data,
            ...additionalData
        } = edu;
        const additionalDataValues = Object.values(additionalData);
        if (
            typeof university === "string" &&
            university.trim() === "" &&
            typeof location === "string" &&
            location.trim() === "" &&
            typeof degree === "string" &&
            degree.trim() === "" &&
            typeof time_period === "string" &&
            time_period.trim() === "" &&
            typeof remaining_related_data === "string" &&
            remaining_related_data.trim() === "" &&
            additionalDataValues.every(
                (value) => typeof value === "string" && value.trim() === ""
            )
        ) {
            return true;
        }
        return false;
    }

    // Function to render skills section
    function renderSkillsSection() {
        const skillsSection = document.getElementById("skills-section");

        if (!isKeyEmpty(candidateResumeData, "skills")) {
            const { skills } = candidateResumeData;

            if (Array.isArray(skills)) {
                if (skills.every((skill) => isEmptySkill(skill))) {
                    return null;
                }

                const skillsHTML = skills.map(renderSkill).join("");

                return (
                    <section>
                        {renderSkillsHeader()}
                        {skillsHTML}
                    </section>
                );
            } else if (typeof skills === "object") {
                const { heading, related_data } = skills;

                if (isEmptySkill({ heading, related_data })) {
                    return null;
                }

                return (
                    <section>
                        {renderSkillsHeader()}
                        <div>
                            {renderSkill({
                                heading,
                                related_data,
                            })}
                        </div>
                    </section>
                );
            }
        }
        return null;
    }

    // Function to render skills header
    function renderSkillsHeader() {
        return (
            <div className='row'>
                <p className='fw-bold  mb-0 pb-0'>SKILLS</p>
            </div>
        );
    }

    // Function to render a skill
    function renderSkill(skill) {
        const { heading, related_data } = skill;
        if (related_data === "") {
            return (
                <ul>
                    <li>
                        {renderValue(heading)}
                    </li>
                </ul>
            );
        } else if (heading === "") {
            return renderValue(related_data);
        } else {
            return (
                <>
                    {renderValue(heading)}
                    {Array.isArray(related_data) ? renderValue(related_data) : <ul>{renderValue(related_data)}</ul>}
                </>
            );
        }
    }

    // Function to check if a skill is empty
    function isEmptySkill(skill) {
        const { heading, related_data } = skill;
        if (
            typeof heading === "string" &&
            heading.trim() === "" &&
            typeof related_data === "string" &&
            related_data.trim() === ""
        ) {
            return true;
        }
        return false;
    }

    // Function to render additional section
    // function renderAdditionalSection() {
    //     const additionalSection = document.getElementById("additional-section");

    //     if (!isKeyEmpty(candidateResumeData, "additional")) {
    //         additionalSection.innerHTML = (
    //             <section>
    //                 <div className='row'>
    //                     <p className='fw-bold  mb-0 pb-0'>ADDITIONAL</p>
    //                 </div>
    //                 {renderValue(candidateResumeData.additional)}
    //             </section>
    //         );
    //     } else {
    //         additionalSection.innerHTML = (
    //             <section>
    //                 <div className='row'>
    //                     <p className='fw-bold  mb-0 pb-0'>ADDITIONAL</p>
    //                 </div>
    //                 {renderValue(candidateResumeData.additional)}
    //             </section>
    //         );
    //     }
    // }

    return (
        <>
            <div className='template-body'>
                {[
                    renderEducationTable(),
                    renderExperienceTable(),
                    renderPersonalDetailsTable(),
                    renderResumeHeaderSection(),
                    renderHeaderSection(),
                    renderObjectiveSection(),
                    renderProfileSection(),
                    renderExperienceSection(),
                    renderCertificationsSection(),
                    //   renderEducationSection();
                    renderSkillsSection(),
                    // renderAdditionalSection(),
                ]}
            </div>
        </>
    );

}

