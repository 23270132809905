/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { HTMLAttributes, useEffect, useState } from 'react';
import { KTIcon, toAbsoluteUrl } from '../../../helpers';
import { statistic } from '../../../../app/api';
import { useAuth } from '../../../../app/modules/auth';
import { Link } from "react-router-dom";
type Props = {
  className: string;
  color: string;
  svgIcon?: string;
  iconColor: string;
  title: string;
  titleColor?: string;
  description: string;
  descriptionColor?: string;
  image?: string;
  tag?: string;
} & HTMLAttributes<HTMLDivElement>;

const StatisticsWidget5: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor,
  image,
  tag,
  ...divProps
}) => {

  type MyObject = {
    data: {
      processed_count: number;
      unprocessed_count: number;
      total_count: number;
    };
  };
  const [statisticsData, setStatisticsData] = useState<MyObject>({
    data: {
      processed_count: 0,
      unprocessed_count: 0,
      total_count: 0
    }
  });

  const { currentUser } = useAuth();
  useEffect(() => {
    if (tag) {
      console.log(tag)
      statistic({ useremail: currentUser?.email, tag: tag })
        .then((res) => {
          if (res && res.data) {
            setStatisticsData({
              data: {
                processed_count: Number(res.data?.processed_count) || 0,
                unprocessed_count: Number(res.data?.unprocessed_count) || 0,
                total_count: Number(res.data?.total_count) || 0
              }
            });
          } else {
            setStatisticsData({
              data: {
                processed_count: 0,
                unprocessed_count: 0,
                total_count: 0
              }
            });
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          // Handle API call error if necessary
        });
    }
  }, []);



  return (
    <Link to={tag ? tag : ""} onClick={(e) => localStorage.setItem('tag', tag ? tag : "")} >
      <div className={`card bg-${color} hoverable ${className}`} style={{ paddingTop: '10px', paddingLeft: '10px' }}>
        <div className='card-header align-items-center' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className={`text-${titleColor} fw-bold fs-1 mb-2 mt-5`} style={{ marginRight: '10px' }}>{title}</div>
            {svgIcon && <KTIcon iconName={svgIcon} className={`text-${iconColor} fs-3x`} />}
          </div>
          {image && (
            <img
              src={toAbsoluteUrl('/media/dsicon/' + image + '.png')}
              className={`text-${iconColor} fs-3x ms-n1`}
              alt={title}
              height={50}
            />
          )}
        </div>
        <hr />
        <div className='card-body'>
          <div className={`fw-semibold text-${descriptionColor}`}>{description}</div>
        </div>
      </div>
    </Link>
  );











};

export { StatisticsWidget5 };



