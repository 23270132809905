export const resData: { [key: string]: any } = {
    "https://formrecognizer12345.blob.core.windows.net/form-recognizer/formrecognizer12345/test/HAL/20231124-114850%25LC140001.pdf": {
        "message": "success",
        "data": [
            [
                {
                    "Opened at Unit/Station": "BANGALORE",
                    "Opened by": "HAL H/C",
                    "Nomenclature": "REAR MAIN TANK",
                    "Type & MK": "ALH MK-III",
                    "Manufacturers Part No": "FT 25424 Iss'J'",
                    "Date of Manufacture": "03/2010",
                    "Serial No": "391354",
                    "Date of Receipt in India": "05/2010",
                    "Details of Major equipment or installation of which the item forms Part": "FUEL SYSTEM.",
                    "Type of A/C or A/E on which installed": "MK 711",
                    "Overhaul agency": "GKN AEROSPACE SERVICE LTD",
                    "TTL": "15 YEARS FROM DATE OF INSTALLATION (ONLY FOR UK MADE FUEL TANKS).SUBJECTED TO SATISFACTORY LEAK TEST AT EVERY 01 YEAR AFTER 10 YEARS.",
                    "AUTHY": "RCMA/ (H/C)/1622/1 DTD 17/11/14) & MSR REVISION NO 01/10 DTD 14 DEC 2019",
                    "Certified that": "REAR MAIN TANK",
                    "Mark": "FT 25424. Jss J",
                    "No": "391354",
                    "Job No.": "u0918u0902u091fu0947.",
                    "Maintenance Unit": "0.0 00",
                    "Modification embodied": "TTL :- 10 yr's for DOM 15 yrs from DOI TBO - leak check to be carried out every year after 10 yrs Remarks : Max Service life is 15yrs including storage of",
                    "DATE": "21/12/10",
                    "INSTALLATION RECORD": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E SI No. on which installed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    1.0117,
                                                    7.7751
                                                ],
                                                [
                                                    1.5099,
                                                    7.7751
                                                ],
                                                [
                                                    1.5099,
                                                    7.9631
                                                ],
                                                [
                                                    1.0117,
                                                    7.9631
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "DS-116",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1692
                                        }
                                    ],
                                    "value": "DS-116",
                                    "value_type": "string"
                                },
                                "Component Hrs at installation": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    2.2928,
                                                    7.7954
                                                ],
                                                [
                                                    2.8367,
                                                    7.7954
                                                ],
                                                [
                                                    2.8367,
                                                    7.9733
                                                ],
                                                [
                                                    2.2928,
                                                    7.9733
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "00:00",
                                    "spans": [
                                        {
                                            "length": 5,
                                            "offset": 1699
                                        }
                                    ],
                                    "value": "00:00",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.2993,
                                                    7.7954
                                                ],
                                                [
                                                    3.7823,
                                                    7.7954
                                                ],
                                                [
                                                    3.7823,
                                                    7.9733
                                                ],
                                                [
                                                    3.2993,
                                                    7.9733
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "21/12/10",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 1705
                                        }
                                    ],
                                    "value": "21/12/10",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.9196,
                                                    7.8157
                                                ],
                                                [
                                                    4.3517,
                                                    7.8157
                                                ],
                                                [
                                                    4.3517,
                                                    7.9682
                                                ],
                                                [
                                                    3.9196,
                                                    7.9682
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "00:00",
                                    "spans": [
                                        {
                                            "length": 5,
                                            "offset": 1714
                                        }
                                    ],
                                    "value": "00:00",
                                    "value_type": "string"
                                },
                                "Component Hours at removal coi. 2": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    5.6277,
                                                    7.7801
                                                ],
                                                [
                                                    6.8885,
                                                    7.7801
                                                ],
                                                [
                                                    6.8885,
                                                    7.9885
                                                ],
                                                [
                                                    5.6277,
                                                    7.9885
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Instod al-HAC",
                                    "spans": [
                                        {
                                            "length": 13,
                                            "offset": 1720
                                        }
                                    ],
                                    "value": "Instod al-HAC",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E At removal Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.4279,
                                                    8.0393
                                                ],
                                                [
                                                    4.9363,
                                                    8.0393
                                                ],
                                                [
                                                    4.9363,
                                                    8.2121
                                                ],
                                                [
                                                    4.4279,
                                                    8.2121
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "14/3/23",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1753
                                        }
                                    ],
                                    "value": "14/3/23",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    5.0176,
                                                    8.0546
                                                ],
                                                [
                                                    5.5057,
                                                    8.0546
                                                ],
                                                [
                                                    5.5057,
                                                    8.2223
                                                ],
                                                [
                                                    5.0176,
                                                    8.2223
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1799,57",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1761
                                        }
                                    ],
                                    "value": "1799,57",
                                    "value_type": "string"
                                },
                                "Component Hours at removal coi. 2": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    5.648,
                                                    8.0342
                                                ],
                                                [
                                                    7.0156,
                                                    8.0342
                                                ],
                                                [
                                                    7.0156,
                                                    8.2223
                                                ],
                                                [
                                                    5.648,
                                                    8.2223
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1299.57 For AS an.",
                                    "spans": [
                                        {
                                            "length": 18,
                                            "offset": 1769
                                        }
                                    ],
                                    "value": "1299.57 For AS an.",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "PART DISCREPANCY REPORT (PDR)": [],
                    "PART DISCREPANCY REPORT - 2 (PDR-2)": [],
                    "MAJOR UNSERVICEABILITY RECORD": [],
                    "PERIODICAL SERVICING RECORD": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    0.8795,
                                                    2.0276
                                                ],
                                                [
                                                    1.3675,
                                                    2.0276
                                                ],
                                                [
                                                    1.3675,
                                                    2.2309
                                                ],
                                                [
                                                    0.8795,
                                                    2.2309
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "21/7/19",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 3628
                                        }
                                    ],
                                    "value": "21/7/19",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    1.6115,
                                                    2.0276
                                                ],
                                                [
                                                    2.2063,
                                                    2.0276
                                                ],
                                                [
                                                    2.2063,
                                                    2.2207
                                                ],
                                                [
                                                    1.6115,
                                                    2.2207
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1248:59",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 3636
                                        }
                                    ],
                                    "value": "1248:59",
                                    "value_type": "string"
                                },
                                "Details of work done and components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    2.7147,
                                                    1.987
                                                ],
                                                [
                                                    5.2362,
                                                    1.987
                                                ],
                                                [
                                                    5.2362,
                                                    2.236
                                                ],
                                                [
                                                    2.7147,
                                                    2.236
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "leak Check dove (full tank)",
                                    "spans": [
                                        {
                                            "length": 27,
                                            "offset": 3644
                                        }
                                    ],
                                    "value": "leak Check dove (full tank)",
                                    "value_type": "string"
                                },
                                "date declared serviceable or unserviceable": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    5.6074,
                                                    2.0276
                                                ],
                                                [
                                                    6.314,
                                                    2.0276
                                                ],
                                                [
                                                    6.314,
                                                    2.1902
                                                ],
                                                [
                                                    5.6074,
                                                    2.1902
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "21.02.19",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 3672
                                        }
                                    ],
                                    "value": "21.02.19",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    0.8897,
                                                    2.4596
                                                ],
                                                [
                                                    1.4844,
                                                    2.4596
                                                ],
                                                [
                                                    1.4844,
                                                    2.6832
                                                ],
                                                [
                                                    0.8897,
                                                    2.6832
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "16/05/23",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 3709
                                        }
                                    ],
                                    "value": "16/05/23",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    1.6776,
                                                    2.4596
                                                ],
                                                [
                                                    2.2826,
                                                    2.4596
                                                ],
                                                [
                                                    2.2826,
                                                    2.6679
                                                ],
                                                [
                                                    1.6776,
                                                    2.6679
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Repair",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 3718
                                        }
                                    ],
                                    "value": "Repair",
                                    "value_type": "string"
                                },
                                "Details of work done and components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    2.5571,
                                                    2.3478
                                                ],
                                                [
                                                    5.3583,
                                                    2.3478
                                                ],
                                                [
                                                    5.3583,
                                                    2.9169
                                                ],
                                                [
                                                    2.5571,
                                                    2.9169
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "lank in repaired and leaktest in satisfactor",
                                    "spans": [
                                        {
                                            "length": 25,
                                            "offset": 3681
                                        },
                                        {
                                            "length": 19,
                                            "offset": 3725
                                        }
                                    ],
                                    "value": "lank in repaired and leak test in satisfactor",
                                    "value_type": "string"
                                },
                                "date declared serviceable or unserviceable": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    5.5616,
                                                    2.6527
                                                ],
                                                [
                                                    6.6038,
                                                    2.6527
                                                ],
                                                [
                                                    6.6038,
                                                    2.9118
                                                ],
                                                [
                                                    5.5616,
                                                    2.9118
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "16/05/23",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 3745
                                        }
                                    ],
                                    "value": "16/05/23",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "TRANSFER RECORD": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    0.9252,
                                                    6.342
                                                ],
                                                [
                                                    1.5302,
                                                    6.342
                                                ],
                                                [
                                                    1.5302,
                                                    6.53
                                                ],
                                                [
                                                    0.9252,
                                                    6.53
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "24/02/23",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 4136
                                        }
                                    ],
                                    "value": "24/02/23",
                                    "value_type": "string"
                                },
                                "Received from": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    1.642,
                                                    6.3115
                                                ],
                                                [
                                                    6.68,
                                                    6.3115
                                                ],
                                                [
                                                    6.68,
                                                    6.7638
                                                ],
                                                [
                                                    1.642,
                                                    6.7638
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Transferred to NAL for 1 800 hrs servicing at 1799.51AF Has and11799. Si comp has",
                                    "spans": [
                                        {
                                            "length": 83,
                                            "offset": 4145
                                        }
                                    ],
                                    "value": "Transferred to NAL for 1 800 hrs servicing at 1799.51 AF Has and 11799. Si comp has",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "Canister Pt No.": null,
                    "TBO": null,
                    "AUTH": null,
                    "Contract No.": null,
                    "Delete as appropriate": null,
                    "SL No": null,
                    "Authorised overhaul life": null,
                    "I.A.F. Catalogue No": null,
                    "No. of overhauls done": null,
                    "LOG CARD No.": null,
                    "Section No": null,
                    "Opened at Date": null,
                    "Ref.No": null
                }
            ]
        ]
    },
    "https://formrecognizer12345.blob.core.windows.net/form-recognizer/formrecognizer12345/test/HAL/20231124-115033%25LC90001.pdf": {
        "message": "success",
        "data": [
            [
                {
                    "Opened at Unit/Station": "BANGALORE",
                    "Opened by": "HAL (H)-De",
                    "Nomenclature": "ROTOR BRAKE HAND PUMP",
                    "Type & MK": "ACH",
                    "Manufacturers Part No": "5501-000 Bev . B",
                    "Date of Manufacture": "01/2007.",
                    "Serial No": "105",
                    "Date of Receipt in India": "31.05. 07",
                    "Details of Major equipment or installation of which the item forms Part": "ROTOR BRAKE SYSTEM",
                    "Type of A/C or A/E on which installed": "26bv ALH.",
                    "Overhaul agency": "HAL CH)-DC.",
                    "TBO": "TBO:ON CONDITION",
                    "AUTH": "AUTH:HD/ALH/DLE/AW/TR/24/2012 ISS II REV A DATE 18-11-20149",
                    "Certified that": "ROTOR BRAKE HAND PUMP UNIT",
                    "Mark": "5501-000 Rev. 'B'",
                    "No": "105",
                    "Maintenance Unit": "00.00",
                    "Modification embodied": "TBO (Hrs) :- TTL (Hrs.) :- 10000 HRS. STORAGE LIFE 2 YEARS.",
                    "INSTALLATION RECORD": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E SI No. on which installed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    0.7778,
                                                    7.831
                                                ],
                                                [
                                                    1.4946,
                                                    7.831
                                                ],
                                                [
                                                    1.4946,
                                                    8.0139
                                                ],
                                                [
                                                    0.7778,
                                                    8.0139
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "J4058",
                                    "spans": [
                                        {
                                            "length": 5,
                                            "offset": 1589
                                        }
                                    ],
                                    "value": "J4058",
                                    "value_type": "string"
                                },
                                "Component Hrs at installation": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    2.1352,
                                                    7.8665
                                                ],
                                                [
                                                    2.8316,
                                                    7.8665
                                                ],
                                                [
                                                    2.8316,
                                                    8.0241
                                                ],
                                                [
                                                    2.1352,
                                                    8.0241
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "00:00",
                                    "spans": [
                                        {
                                            "length": 5,
                                            "offset": 1595
                                        }
                                    ],
                                    "value": "00:00",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.279,
                                                    7.8818
                                                ],
                                                [
                                                    3.7772,
                                                    7.8818
                                                ],
                                                [
                                                    3.7772,
                                                    8.0342
                                                ],
                                                [
                                                    3.279,
                                                    8.0342
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "18.10.07",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 1601
                                        }
                                    ],
                                    "value": "18.10.07",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.8738,
                                                    7.8869
                                                ],
                                                [
                                                    4.3923,
                                                    7.8869
                                                ],
                                                [
                                                    4.3923,
                                                    8.0342
                                                ],
                                                [
                                                    3.8738,
                                                    8.0342
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "396:58",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1610
                                        }
                                    ],
                                    "value": "396:58",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    5.343,
                                                    7.8716
                                                ],
                                                [
                                                    7.3206,
                                                    7.8716
                                                ],
                                                [
                                                    7.3206,
                                                    8.0749
                                                ],
                                                [
                                                    5.343,
                                                    8.0749
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "oninstallation at17HU",
                                    "spans": [
                                        {
                                            "length": 22,
                                            "offset": 1617
                                        }
                                    ],
                                    "value": "on installation at17HU",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E SI No. on which installed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    0.7168,
                                                    8.0698
                                                ],
                                                [
                                                    1.4794,
                                                    8.0698
                                                ],
                                                [
                                                    1.4794,
                                                    8.2527
                                                ],
                                                [
                                                    0.7168,
                                                    8.2527
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "J.4058",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1645
                                        }
                                    ],
                                    "value": "J.4058",
                                    "value_type": "string"
                                },
                                "Component Hrs at installation": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    1.9674,
                                                    8.0596
                                                ],
                                                [
                                                    4.311,
                                                    8.0596
                                                ],
                                                [
                                                    4.311,
                                                    8.2629
                                                ],
                                                [
                                                    1.9674,
                                                    8.2629
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Removaldone 24 HAL",
                                    "spans": [
                                        {
                                            "length": 18,
                                            "offset": 1652
                                        }
                                    ],
                                    "value": "Removaldone 24 HAL",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.3618,
                                                    8.0851
                                                ],
                                                [
                                                    4.9566,
                                                    8.0851
                                                ],
                                                [
                                                    4.9566,
                                                    8.2629
                                                ],
                                                [
                                                    4.3618,
                                                    8.2629
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "23310",
                                    "spans": [
                                        {
                                            "length": 5,
                                            "offset": 1671
                                        }
                                    ],
                                    "value": "23310",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    5.0278,
                                                    8.1003
                                                ],
                                                [
                                                    5.5616,
                                                    8.1003
                                                ],
                                                [
                                                    5.5616,
                                                    8.2782
                                                ],
                                                [
                                                    5.0278,
                                                    8.2782
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "79654",
                                    "spans": [
                                        {
                                            "length": 5,
                                            "offset": 1677
                                        }
                                    ],
                                    "value": "79654",
                                    "value_type": "string"
                                },
                                "Component Hours at removal coi. 2": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    5.9581,
                                                    8.08
                                                ],
                                                [
                                                    6.6851,
                                                    8.08
                                                ],
                                                [
                                                    6.6851,
                                                    8.268
                                                ],
                                                [
                                                    5.9581,
                                                    8.268
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "397:56",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1683
                                        }
                                    ],
                                    "value": "397:56",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E SI No. on which installed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    0.6965,
                                                    8.2934
                                                ],
                                                [
                                                    1.4133,
                                                    8.2934
                                                ],
                                                [
                                                    1.4133,
                                                    8.4916
                                                ],
                                                [
                                                    0.6965,
                                                    8.4916
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "J. 4058",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1690
                                        }
                                    ],
                                    "value": "J. 4058",
                                    "value_type": "string"
                                },
                                "Component Hrs at installation": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    2.1504,
                                                    8.3086
                                                ],
                                                [
                                                    2.9587,
                                                    8.3086
                                                ],
                                                [
                                                    2.9587,
                                                    8.5119
                                                ],
                                                [
                                                    2.1504,
                                                    8.5119
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "397:56",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1698
                                        }
                                    ],
                                    "value": "397:56",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.2333,
                                                    8.3341
                                                ],
                                                [
                                                    3.7315,
                                                    8.3341
                                                ],
                                                [
                                                    3.7315,
                                                    8.5119
                                                ],
                                                [
                                                    3.2333,
                                                    8.5119
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "4.4.10",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1705
                                        }
                                    ],
                                    "value": "4.4.10",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.8433,
                                                    8.329
                                                ],
                                                [
                                                    4.3873,
                                                    8.329
                                                ],
                                                [
                                                    4.3873,
                                                    8.5068
                                                ],
                                                [
                                                    3.8433,
                                                    8.5068
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "194:54",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1712
                                        }
                                    ],
                                    "value": "194:54",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.4584,
                                                    8.3239
                                                ],
                                                [
                                                    6.8478,
                                                    8.3239
                                                ],
                                                [
                                                    6.8478,
                                                    8.5272
                                                ],
                                                [
                                                    4.4584,
                                                    8.5272
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Inst done by H.AL",
                                    "spans": [
                                        {
                                            "length": 17,
                                            "offset": 1719
                                        }
                                    ],
                                    "value": "Inst done by H.AL",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E SI No. on which installed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    0.8083,
                                                    8.5627
                                                ],
                                                [
                                                    1.5912,
                                                    8.5627
                                                ],
                                                [
                                                    1.5912,
                                                    8.7203
                                                ],
                                                [
                                                    0.8083,
                                                    8.7203
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "J.4058",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1737
                                        }
                                    ],
                                    "value": "J.4058",
                                    "value_type": "string"
                                },
                                "Component Hrs at installation": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    2.4758,
                                                    8.6593
                                                ],
                                                [
                                                    2.5164,
                                                    8.6593
                                                ],
                                                [
                                                    2.5164,
                                                    8.7203
                                                ],
                                                [
                                                    2.4758,
                                                    8.7203
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "-",
                                    "spans": [
                                        {
                                            "length": 1,
                                            "offset": 1744
                                        }
                                    ],
                                    "value": "-",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.0517,
                                                    8.6339
                                                ],
                                                [
                                                    4.0975,
                                                    8.6339
                                                ],
                                                [
                                                    4.0975,
                                                    8.6999
                                                ],
                                                [
                                                    4.0517,
                                                    8.6999
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "-",
                                    "spans": [
                                        {
                                            "length": 1,
                                            "offset": 1746
                                        }
                                    ],
                                    "value": "-",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.4381,
                                                    8.5678
                                                ],
                                                [
                                                    4.9821,
                                                    8.5678
                                                ],
                                                [
                                                    4.9821,
                                                    8.766
                                                ],
                                                [
                                                    4.4381,
                                                    8.766
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "24/12/15",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 1748
                                        }
                                    ],
                                    "value": "24/12/15",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    5.0126,
                                                    8.6034
                                                ],
                                                [
                                                    5.648,
                                                    8.6034
                                                ],
                                                [
                                                    5.648,
                                                    8.7812
                                                ],
                                                [
                                                    5.0126,
                                                    8.7812
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1498:55",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1757
                                        }
                                    ],
                                    "value": "1498:55",
                                    "value_type": "string"
                                },
                                "Component Hours at removal coi. 2": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    5.7548,
                                                    8.5018
                                                ],
                                                [
                                                    6.4563,
                                                    8.5018
                                                ],
                                                [
                                                    6.4563,
                                                    8.6796
                                                ],
                                                [
                                                    5.7548,
                                                    8.6796
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1101:57",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1783
                                        }
                                    ],
                                    "value": "1101:57",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    5.6887,
                                                    8.6186
                                                ],
                                                [
                                                    7.4019,
                                                    8.6186
                                                ],
                                                [
                                                    7.4019,
                                                    8.7914
                                                ],
                                                [
                                                    5.6887,
                                                    8.7914
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "For Camibilisator",
                                    "spans": [
                                        {
                                            "length": 17,
                                            "offset": 1765
                                        }
                                    ],
                                    "value": "For Camibilisator",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E SI No. on which installed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    0.8286,
                                                    8.7863
                                                ],
                                                [
                                                    1.4743,
                                                    8.7863
                                                ],
                                                [
                                                    1.4743,
                                                    8.9642
                                                ],
                                                [
                                                    0.8286,
                                                    8.9642
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "5-4045",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1791
                                        }
                                    ],
                                    "value": "5-4045",
                                    "value_type": "string"
                                },
                                "Component Hrs at installation": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    2.1809,
                                                    8.8067
                                                ],
                                                [
                                                    2.7554,
                                                    8.8067
                                                ],
                                                [
                                                    2.7554,
                                                    8.9591
                                                ],
                                                [
                                                    2.1809,
                                                    8.9591
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1101:57",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1798
                                        }
                                    ],
                                    "value": "1101:57",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.2943,
                                                    8.7609
                                                ],
                                                [
                                                    3.64,
                                                    8.7609
                                                ],
                                                [
                                                    3.64,
                                                    8.9642
                                                ],
                                                [
                                                    3.2943,
                                                    8.9642
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "8/2/16 1.",
                                    "spans": [
                                        {
                                            "length": 9,
                                            "offset": 1806
                                        }
                                    ],
                                    "value": "8/2/16 1.",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.8586,
                                                    8.8219
                                                ],
                                                [
                                                    4.3568,
                                                    8.8219
                                                ],
                                                [
                                                    4.3568,
                                                    8.9642
                                                ],
                                                [
                                                    3.8586,
                                                    8.9642
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1487:30",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1816
                                        }
                                    ],
                                    "value": "1487:30",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.4127,
                                                    8.7863
                                                ],
                                                [
                                                    6.9393,
                                                    8.7863
                                                ],
                                                [
                                                    6.9393,
                                                    8.9642
                                                ],
                                                [
                                                    4.4127,
                                                    8.9642
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "On Installation at HAL Bangalore",
                                    "spans": [
                                        {
                                            "length": 32,
                                            "offset": 1824
                                        }
                                    ],
                                    "value": "On Installation at HAL Bangalore",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E At removal Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.4483,
                                                    9.0303
                                                ],
                                                [
                                                    4.9973,
                                                    9.0303
                                                ],
                                                [
                                                    4.9973,
                                                    9.2437
                                                ],
                                                [
                                                    4.4483,
                                                    9.2437
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "13/7/2",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1861
                                        }
                                    ],
                                    "value": "13/7/2",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    5.0329,
                                                    9.0303
                                                ],
                                                [
                                                    5.587,
                                                    9.0303
                                                ],
                                                [
                                                    5.587,
                                                    9.2234
                                                ],
                                                [
                                                    5.0329,
                                                    9.2234
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "2102:38",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1868
                                        }
                                    ],
                                    "value": "2102:38",
                                    "value_type": "string"
                                },
                                "Component Hours at removal coi. 2": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    5.7294,
                                                    9.0557
                                                ],
                                                [
                                                    6.4106,
                                                    9.0557
                                                ],
                                                [
                                                    6.4106,
                                                    9.2335
                                                ],
                                                [
                                                    5.7294,
                                                    9.2335
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "17/7:05",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1876
                                        }
                                    ],
                                    "value": "17/7:05",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    6.5478,
                                                    9.0557
                                                ],
                                                [
                                                    7.4782,
                                                    9.0557
                                                ],
                                                [
                                                    7.4782,
                                                    9.2488
                                                ],
                                                [
                                                    6.5478,
                                                    9.2488
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "K& Stewing",
                                    "spans": [
                                        {
                                            "length": 10,
                                            "offset": 1884
                                        }
                                    ],
                                    "value": "K& Stewing",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E SI No. on which installed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    0.8134,
                                                    9.264
                                                ],
                                                [
                                                    1.52,
                                                    9.264
                                                ],
                                                [
                                                    1.52,
                                                    9.4317
                                                ],
                                                [
                                                    0.8134,
                                                    9.4317
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "ZD 4116",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1895
                                        }
                                    ],
                                    "value": "ZD 4116",
                                    "value_type": "string"
                                },
                                "Component Hrs at installation": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    2.1606,
                                                    9.2589
                                                ],
                                                [
                                                    2.9181,
                                                    9.2589
                                                ],
                                                [
                                                    2.9181,
                                                    9.447
                                                ],
                                                [
                                                    2.1606,
                                                    9.447
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1717:05",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1903
                                        }
                                    ],
                                    "value": "1717:05",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.3095,
                                                    9.2081
                                                ],
                                                [
                                                    3.7772,
                                                    9.2081
                                                ],
                                                [
                                                    3.7772,
                                                    9.4571
                                                ],
                                                [
                                                    3.3095,
                                                    9.4571
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "24/6/2",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1911
                                        }
                                    ],
                                    "value": "24/6/2",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.9145,
                                                    9.2843
                                                ],
                                                [
                                                    4.4178,
                                                    9.2843
                                                ],
                                                [
                                                    4.4178,
                                                    9.4673
                                                ],
                                                [
                                                    3.9145,
                                                    9.4673
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1791:54",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1918
                                        }
                                    ],
                                    "value": "1791:54",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.6364,
                                                    9.2793
                                                ],
                                                [
                                                    7.0664,
                                                    9.2793
                                                ],
                                                [
                                                    7.0664,
                                                    9.4825
                                                ],
                                                [
                                                    4.6364,
                                                    9.4825
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "on Installation at HAL more",
                                    "spans": [
                                        {
                                            "length": 27,
                                            "offset": 1926
                                        }
                                    ],
                                    "value": "on Installation at HAL more",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "PART DISCREPANCY REPORT (PDR)": [],
                    "PART DISCREPANCY REPORT - 2 (PDR-2)": [],
                    "MAJOR UNSERVICEABILITY RECORD": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date placed U/S": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    0.7778,
                                                    1.9514
                                                ],
                                                [
                                                    1.3624,
                                                    1.9514
                                                ],
                                                [
                                                    1.3624,
                                                    2.1394
                                                ],
                                                [
                                                    0.7778,
                                                    2.1394
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "13/2/02",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 3769
                                        }
                                    ],
                                    "value": "13/2/02",
                                    "value_type": "string"
                                },
                                "Reason for unserviceability (U/S)": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    1.6217,
                                                    1.9616
                                                ],
                                                [
                                                    2.6385,
                                                    1.9616
                                                ],
                                                [
                                                    2.6385,
                                                    2.3427
                                                ],
                                                [
                                                    1.6217,
                                                    2.3427
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "pr NOT holderat park",
                                    "spans": [
                                        {
                                            "length": 13,
                                            "offset": 3777
                                        },
                                        {
                                            "length": 7,
                                            "offset": 3817
                                        }
                                    ],
                                    "value": "pr NOT holder at park",
                                    "value_type": "string"
                                },
                                "Hours done since last overhaul or since new": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    2.8621,
                                                    1.9565
                                                ],
                                                [
                                                    3.4874,
                                                    1.9565
                                                ],
                                                [
                                                    3.4874,
                                                    2.1394
                                                ],
                                                [
                                                    2.8621,
                                                    2.1394
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1717:05",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 3791
                                        }
                                    ],
                                    "value": "1717:05",
                                    "value_type": "string"
                                },
                                "Work done to rectify the fault & Components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    4.0416,
                                                    1.9209
                                                ],
                                                [
                                                    5.648,
                                                    1.9209
                                                ],
                                                [
                                                    5.648,
                                                    2.7543
                                                ],
                                                [
                                                    4.0416,
                                                    2.7543
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "0/H (10 Round Satas por vendo ReportHALBERNOMAKRE/PL /772",
                                    "spans": [
                                        {
                                            "length": 17,
                                            "offset": 3799
                                        },
                                        {
                                            "length": 28,
                                            "offset": 3825
                                        },
                                        {
                                            "length": 13,
                                            "offset": 3860
                                        }
                                    ],
                                    "value": "0/H (10 Round Sat as por vendo Report HALBERNO MAKRE/PL /772",
                                    "value_type": "string"
                                },
                                "Date placed 'S'": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    5.6989,
                                                    2.3528
                                                ],
                                                [
                                                    6.1818,
                                                    2.3528
                                                ],
                                                [
                                                    6.1818,
                                                    2.5256
                                                ],
                                                [
                                                    5.6989,
                                                    2.5256
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "20/hs",
                                    "spans": [
                                        {
                                            "length": 5,
                                            "offset": 3854
                                        }
                                    ],
                                    "value": "20/hs",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "PERIODICAL SERVICING RECORD": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Details of work done and components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    2.6029,
                                                    1.8345
                                                ],
                                                [
                                                    5.4345,
                                                    1.8345
                                                ],
                                                [
                                                    5.4345,
                                                    2.2817
                                                ],
                                                [
                                                    2.6029,
                                                    2.2817
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Insitu Checks Carreidont andfound Satisfactory",
                                    "spans": [
                                        {
                                            "length": 28,
                                            "offset": 4199
                                        },
                                        {
                                            "length": 18,
                                            "offset": 4242
                                        }
                                    ],
                                    "value": "Insitu Checks Carreidont and found Satisfactory",
                                    "value_type": "string"
                                },
                                "date declared serviceable or unserviceable": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    5.6938,
                                                    1.8548
                                                ],
                                                [
                                                    6.5936,
                                                    1.8548
                                                ],
                                                [
                                                    6.5936,
                                                    2.2614
                                                ],
                                                [
                                                    5.6938,
                                                    2.2614
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": ",S.16.07.08.",
                                    "spans": [
                                        {
                                            "length": 3,
                                            "offset": 4228
                                        },
                                        {
                                            "length": 9,
                                            "offset": 4261
                                        }
                                    ],
                                    "value": ",S. 16.07.08.",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    0.9862,
                                                    2.3173
                                                ],
                                                [
                                                    1.515,
                                                    2.3173
                                                ],
                                                [
                                                    1.515,
                                                    2.4494
                                                ],
                                                [
                                                    0.9862,
                                                    2.4494
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "15.4.10",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 4271
                                        }
                                    ],
                                    "value": "15.4.10",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.6624,
                                                    2.3071
                                                ],
                                                [
                                                    2.5012,
                                                    2.3071
                                                ],
                                                [
                                                    2.5012,
                                                    2.4799
                                                ],
                                                [
                                                    1.6624,
                                                    2.4799
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "750 HRS",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 4279
                                        }
                                    ],
                                    "value": "750 HRS",
                                    "value_type": "string"
                                },
                                "Details of work done and components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    2.5775,
                                                    2.2817
                                                ],
                                                [
                                                    5.6226,
                                                    2.2817
                                                ],
                                                [
                                                    5.6226,
                                                    2.9423
                                                ],
                                                [
                                                    2.5775,
                                                    2.9423
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "750 HRS Servicing Carriedoutas per Sch. RCJACH P DCRPI-144 issue'A' dt 15.1218",
                                    "spans": [
                                        {
                                            "length": 28,
                                            "offset": 4287
                                        },
                                        {
                                            "length": 24,
                                            "offset": 4321
                                        },
                                        {
                                            "length": 26,
                                            "offset": 4357
                                        }
                                    ],
                                    "value": "750 HRS Servicing Carriedout as per Sch. RCJACH P DCR PI-144 issue'A' dt 15.1218",
                                    "value_type": "string"
                                },
                                "date declared serviceable or unserviceable": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    5.8565,
                                                    2.4951
                                                ],
                                                [
                                                    6.5733,
                                                    2.4951
                                                ],
                                                [
                                                    6.5733,
                                                    2.9372
                                                ],
                                                [
                                                    5.8565,
                                                    2.9372
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "'S'194.10",
                                    "spans": [
                                        {
                                            "length": 3,
                                            "offset": 4346
                                        },
                                        {
                                            "length": 6,
                                            "offset": 4384
                                        }
                                    ],
                                    "value": "'S' 194.10",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    0.9913,
                                                    3.1812
                                                ],
                                                [
                                                    1.5099,
                                                    3.1812
                                                ],
                                                [
                                                    1.5099,
                                                    3.3489
                                                ],
                                                [
                                                    0.9913,
                                                    3.3489
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "15.17.12",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 4395
                                        }
                                    ],
                                    "value": "15.17.12",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.6217,
                                                    3.1812
                                                ],
                                                [
                                                    2.3182,
                                                    3.1812
                                                ],
                                                [
                                                    2.3182,
                                                    3.3489
                                                ],
                                                [
                                                    1.6217,
                                                    3.3489
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1000 Hrs",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 4404
                                        }
                                    ],
                                    "value": "1000 Hrs",
                                    "value_type": "string"
                                },
                                "Details of work done and components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    2.5876,
                                                    3.1405
                                                ],
                                                [
                                                    5.5057,
                                                    3.1405
                                                ],
                                                [
                                                    5.5057,
                                                    3.5928
                                                ],
                                                [
                                                    2.5876,
                                                    3.5928
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1000 Hrs Servicing carriedout found sat",
                                    "spans": [
                                        {
                                            "length": 26,
                                            "offset": 4413
                                        },
                                        {
                                            "length": 13,
                                            "offset": 4449
                                        }
                                    ],
                                    "value": "1000 Hrs Servicing carried out found sat",
                                    "value_type": "string"
                                },
                                "date declared serviceable or unserviceable": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    5.587,
                                                    3.2422
                                                ],
                                                [
                                                    6.4665,
                                                    3.2422
                                                ],
                                                [
                                                    6.4665,
                                                    3.4098
                                                ],
                                                [
                                                    5.587,
                                                    3.4098
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "15.12.12",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 4440
                                        }
                                    ],
                                    "value": "15.12.12",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    0.9557,
                                                    3.6233
                                                ],
                                                [
                                                    1.5099,
                                                    3.6233
                                                ],
                                                [
                                                    1.5099,
                                                    3.8062
                                                ],
                                                [
                                                    0.9557,
                                                    3.8062
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "24/12/15",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 4463
                                        }
                                    ],
                                    "value": "24/12/15",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.6217,
                                                    3.6334
                                                ],
                                                [
                                                    2.4961,
                                                    3.6334
                                                ],
                                                [
                                                    2.4961,
                                                    4.0247
                                                ],
                                                [
                                                    1.6217,
                                                    4.0247
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1500 HRHe Servicing",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 4472
                                        },
                                        {
                                            "length": 12,
                                            "offset": 4532
                                        }
                                    ],
                                    "value": "1500 HR He Servicing",
                                    "value_type": "string"
                                },
                                "Details of work done and components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    2.6181,
                                                    3.608
                                                ],
                                                [
                                                    5.4498,
                                                    3.608
                                                ],
                                                [
                                                    5.4498,
                                                    4.04
                                                ],
                                                [
                                                    2.6181,
                                                    4.04
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "During Servicing applicable checks?Carriedat and found Satisfactory",
                                    "spans": [
                                        {
                                            "length": 35,
                                            "offset": 4480
                                        },
                                        {
                                            "length": 32,
                                            "offset": 4545
                                        }
                                    ],
                                    "value": "During Servicing applicable checks? Carriedat and found Satisfactory",
                                    "value_type": "string"
                                },
                                "date declared serviceable or unserviceable": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    5.6379,
                                                    3.6639
                                                ],
                                                [
                                                    6.3801,
                                                    3.6639
                                                ],
                                                [
                                                    6.3801,
                                                    4.04
                                                ],
                                                [
                                                    5.6379,
                                                    4.04
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": ".SI08/02/16.",
                                    "spans": [
                                        {
                                            "length": 3,
                                            "offset": 4516
                                        },
                                        {
                                            "length": 9,
                                            "offset": 4578
                                        }
                                    ],
                                    "value": ".SI 08/02/16.",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "TRANSFER RECORD": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    0.9964,
                                                    6.2963
                                                ],
                                                [
                                                    1.5251,
                                                    6.2963
                                                ],
                                                [
                                                    1.5251,
                                                    6.4691
                                                ],
                                                [
                                                    0.9964,
                                                    6.4691
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "11/03/08",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 4953
                                        }
                                    ],
                                    "value": "11/03/08",
                                    "value_type": "string"
                                },
                                "Received from": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.7081,
                                                    6.2556
                                                ],
                                                [
                                                    7.3307,
                                                    6.2556
                                                ],
                                                [
                                                    7.3307,
                                                    6.713
                                                ],
                                                [
                                                    1.7081,
                                                    6.713
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "on transfer to HAL (BC)500:00 ths servicing at 493:48 AF Hors96:50 Component Hrs",
                                    "spans": [
                                        {
                                            "length": 23,
                                            "offset": 4962
                                        },
                                        {
                                            "length": 38,
                                            "offset": 4988
                                        },
                                        {
                                            "length": 19,
                                            "offset": 5031
                                        }
                                    ],
                                    "value": "on transfer to HAL (BC) 500:00 ths servicing at 493:48 AF Hors 96:50 Component Hrs",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    0.9812,
                                                    6.7384
                                                ],
                                                [
                                                    1.5505,
                                                    6.7384
                                                ],
                                                [
                                                    1.5505,
                                                    6.8959
                                                ],
                                                [
                                                    0.9812,
                                                    6.8959
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "29.10.08",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 5051
                                        }
                                    ],
                                    "value": "29.10.08",
                                    "value_type": "string"
                                },
                                "Received from": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.6675,
                                                    6.713
                                                ],
                                                [
                                                    5.6175,
                                                    6.713
                                                ],
                                                [
                                                    5.6175,
                                                    6.9264
                                                ],
                                                [
                                                    1.6675,
                                                    6.9264
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Theuste to 17 Heath 500 HRS Servicing",
                                    "spans": [
                                        {
                                            "length": 37,
                                            "offset": 5060
                                        }
                                    ],
                                    "value": "Theuste to 17 Heath 500 HRS Servicing",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    0.9608,
                                                    6.9518
                                                ],
                                                [
                                                    1.5556,
                                                    6.9518
                                                ],
                                                [
                                                    1.5556,
                                                    7.1297
                                                ],
                                                [
                                                    0.9608,
                                                    7.1297
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "24/12/15",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 5106
                                        }
                                    ],
                                    "value": "24/12/15",
                                    "value_type": "string"
                                },
                                "Received from": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.6624,
                                                    6.9467
                                                ],
                                                [
                                                    6.5478,
                                                    6.9467
                                                ],
                                                [
                                                    6.5478,
                                                    7.3431
                                                ],
                                                [
                                                    1.6624,
                                                    7.3431
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Transfer to HAL For 1500 Hr Servierts at 14/98:55 AF HB 4D1101:57 component Hus",
                                    "spans": [
                                        {
                                            "length": 58,
                                            "offset": 5115
                                        },
                                        {
                                            "length": 21,
                                            "offset": 5181
                                        }
                                    ],
                                    "value": "Transfer to HAL For 1500 Hr Servierts at 14/98:55 AF HB 4D 1101:57 component Hus",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    0.6202,
                                                    7.338
                                                ],
                                                [
                                                    1.5709,
                                                    7.338
                                                ],
                                                [
                                                    1.5709,
                                                    7.5515
                                                ],
                                                [
                                                    0.6202,
                                                    7.5515
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "NOV 201812",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 5217
                                        },
                                        {
                                            "length": 2,
                                            "offset": 5370
                                        }
                                    ],
                                    "value": "12 NOV 2018",
                                    "value_type": "string"
                                },
                                "Received from": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.6014,
                                                    7.3533
                                                ],
                                                [
                                                    5.8971,
                                                    7.3533
                                                ],
                                                [
                                                    5.8971,
                                                    7.5464
                                                ],
                                                [
                                                    1.6014,
                                                    7.5464
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Transferred to 151 Her Af after 1500 HRS H/C Seteicing.",
                                    "spans": [
                                        {
                                            "length": 55,
                                            "offset": 5226
                                        }
                                    ],
                                    "value": "Transferred to 151 Her Af after 1500 HRS H/C Seteicing.",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    0.9761,
                                                    7.6124
                                                ],
                                                [
                                                    1.581,
                                                    7.6124
                                                ],
                                                [
                                                    1.581,
                                                    7.7649
                                                ],
                                                [
                                                    0.9761,
                                                    7.7649
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "15.06.22",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 5286
                                        }
                                    ],
                                    "value": "15.06.22",
                                    "value_type": "string"
                                },
                                "Received from": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.8098,
                                                    7.5921
                                                ],
                                                [
                                                    6.8224,
                                                    7.5921
                                                ],
                                                [
                                                    6.8224,
                                                    7.9987
                                                ],
                                                [
                                                    1.8098,
                                                    7.9987
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Transfered To HAL for 210 hrs at 2102138AF HISand1717:05 component his.",
                                    "spans": [
                                        {
                                            "length": 74,
                                            "offset": 5295
                                        }
                                    ],
                                    "value": "Transfered To HAL for 210 hrs at 2102138 AF HIS and 1717:05 component his.",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "AUTHY": null,
                    "Job No.": null,
                    "DATE": null,
                    "Opened at Date": null,
                    "Authorised overhaul life": null,
                    "TTL": null,
                    "Section No": null,
                    "Canister Pt No.": null,
                    "Delete as appropriate": null,
                    "LOG CARD No.": null,
                    "I.A.F. Catalogue No": null,
                    "SL No": null,
                    "No. of overhauls done": null,
                    "Contract No.": null,
                    "Ref.No": null
                }
            ]
        ]
    },
    "https://formrecognizer12345.blob.core.windows.net/form-recognizer/formrecognizer12345/test/HAL/20231129-102004%25LC150001.pdf": {
        "message": "success",
        "data": [
            [
                {
                    "Opened at Unit/Station": "BANGALORE",
                    "Opened by": "HAL H/C",
                    "Opened at Date": "21/12/10",
                    "Nomenclature": "SUPPLY TANK (RH)",
                    "Type & MK": "ACH MK-111",
                    "Manufacturers Part No": "FT 25426 Iss.'H",
                    "Date of Manufacture": "05/2010",
                    "Serial No": "391553",
                    "Date of Receipt in India": "07/ 2010",
                    "Details of Major equipment or installation of which the item forms Part": "FUEL SYSTEM",
                    "Type of A/C or A/E on which installed": "NK-III Lich",
                    "Overhaul agency": "GKN AEROSPACE SERVICE",
                    "TTL": "15 YEARS FROM DATE OF INSTALLATION (ONLY FOR UK MADE FUEL TANKS).SUBJECTED TO SATISFACTORY LEAK TEST AT EVERY 01 YEAR AFTER 10 YEARS.",
                    "AUTHY": "RCMA/ (H/C)/1622/1 DTD 17/11/14) & MSR REVISION NO 01/10 DTD 14 DEC 2019",
                    "Certified that": "SUPPLY TANK (RH)",
                    "Mark": "FT 25426 Iss: 'H'",
                    "No": "391553",
                    "Maintenance Unit": "O.D .:.........",
                    "Modification embodied": "TTL 1 yrs (Leak check to be carried out every year after loyrs) 15 TBO O.C Remarks- Max Service life is 15 yrs 20 including storage life of 5 yrs",
                    "DATE": "21/12/10",
                    "INSTALLATION RECORD": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E SI No. on which installed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    0.9964,
                                                    7.7852
                                                ],
                                                [
                                                    1.5251,
                                                    7.7852
                                                ],
                                                [
                                                    1.5251,
                                                    7.9733
                                                ],
                                                [
                                                    0.9964,
                                                    7.9733
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "DS-116",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1664
                                        }
                                    ],
                                    "value": "DS-116",
                                    "value_type": "string"
                                },
                                "Component Hrs at installation": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    2.2419,
                                                    7.7903
                                                ],
                                                [
                                                    2.8621,
                                                    7.7903
                                                ],
                                                [
                                                    2.8621,
                                                    7.9682
                                                ],
                                                [
                                                    2.2419,
                                                    7.9682
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "00:00",
                                    "spans": [
                                        {
                                            "length": 5,
                                            "offset": 1671
                                        }
                                    ],
                                    "value": "00:00",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.2892,
                                                    7.7751
                                                ],
                                                [
                                                    3.8026,
                                                    7.7751
                                                ],
                                                [
                                                    3.8026,
                                                    7.958
                                                ],
                                                [
                                                    3.2892,
                                                    7.958
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "21/12/10",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 1677
                                        }
                                    ],
                                    "value": "21/12/10",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.9297,
                                                    7.8157
                                                ],
                                                [
                                                    4.3517,
                                                    7.8157
                                                ],
                                                [
                                                    4.3517,
                                                    7.9682
                                                ],
                                                [
                                                    3.9297,
                                                    7.9682
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "00:00",
                                    "spans": [
                                        {
                                            "length": 5,
                                            "offset": 1686
                                        }
                                    ],
                                    "value": "00:00",
                                    "value_type": "string"
                                },
                                "Component Hours at removal coi. 2": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    5.6379,
                                                    7.7395
                                                ],
                                                [
                                                    6.8224,
                                                    7.7395
                                                ],
                                                [
                                                    6.8224,
                                                    7.9529
                                                ],
                                                [
                                                    5.6379,
                                                    7.9529
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Insted at HAL",
                                    "spans": [
                                        {
                                            "length": 13,
                                            "offset": 1692
                                        }
                                    ],
                                    "value": "Insted at HAL",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E At removal Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.4432,
                                                    8.0088
                                                ],
                                                [
                                                    5.1092,
                                                    8.0088
                                                ],
                                                [
                                                    5.1092,
                                                    9.9348
                                                ],
                                                [
                                                    4.4432,
                                                    9.9348
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "14/3/23.",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1710
                                        },
                                        {
                                            "length": 1,
                                            "offset": 1746
                                        }
                                    ],
                                    "value": "14/3/23 .",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    5.0685,
                                                    8.0393
                                                ],
                                                [
                                                    5.5057,
                                                    8.0393
                                                ],
                                                [
                                                    5.5057,
                                                    8.2121
                                                ],
                                                [
                                                    5.0685,
                                                    8.2121
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "099.51",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1718
                                        }
                                    ],
                                    "value": "099.51",
                                    "value_type": "string"
                                },
                                "Component Hours at removal coi. 2": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    5.6379,
                                                    8.0088
                                                ],
                                                [
                                                    6.5377,
                                                    8.0088
                                                ],
                                                [
                                                    6.5377,
                                                    8.207
                                                ],
                                                [
                                                    5.6379,
                                                    8.207
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1799.51 Fan",
                                    "spans": [
                                        {
                                            "length": 11,
                                            "offset": 1725
                                        }
                                    ],
                                    "value": "1799.51 Fan",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "PART DISCREPANCY REPORT (PDR)": [],
                    "PART DISCREPANCY REPORT - 2 (PDR-2)": [],
                    "MAJOR UNSERVICEABILITY RECORD": [],
                    "PERIODICAL SERVICING RECORD": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    0.9557,
                                                    2.0327
                                                ],
                                                [
                                                    1.5709,
                                                    2.0327
                                                ],
                                                [
                                                    1.5709,
                                                    2.2309
                                                ],
                                                [
                                                    0.9557,
                                                    2.2309
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "21/7/19.",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 3527
                                        }
                                    ],
                                    "value": "21/7/19.",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    1.7285,
                                                    2.0429
                                                ],
                                                [
                                                    2.4046,
                                                    2.0429
                                                ],
                                                [
                                                    2.4046,
                                                    2.236
                                                ],
                                                [
                                                    1.7285,
                                                    2.236
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1248:59",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 3536
                                        }
                                    ],
                                    "value": "1248:59",
                                    "value_type": "string"
                                },
                                "Details of work done and components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    2.8011,
                                                    2.0276
                                                ],
                                                [
                                                    5.4498,
                                                    2.0276
                                                ],
                                                [
                                                    5.4498,
                                                    2.241
                                                ],
                                                [
                                                    2.8011,
                                                    2.241
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "leak Check done (fuel tank)",
                                    "spans": [
                                        {
                                            "length": 27,
                                            "offset": 3544
                                        }
                                    ],
                                    "value": "leak Check done (fuel tank)",
                                    "value_type": "string"
                                },
                                "date declared serviceable or unserviceable": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    5.6379,
                                                    2.0327
                                                ],
                                                [
                                                    6.253,
                                                    2.0327
                                                ],
                                                [
                                                    6.253,
                                                    2.2156
                                                ],
                                                [
                                                    5.6379,
                                                    2.2156
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "21.07.19",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 3572
                                        }
                                    ],
                                    "value": "21.07.19",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    0.971,
                                                    2.4545
                                                ],
                                                [
                                                    1.5963,
                                                    2.4545
                                                ],
                                                [
                                                    1.5963,
                                                    2.6832
                                                ],
                                                [
                                                    0.971,
                                                    2.6832
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "16/05/23",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 3589
                                        }
                                    ],
                                    "value": "16/05/23",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    1.8149,
                                                    2.4494
                                                ],
                                                [
                                                    2.4453,
                                                    2.4494
                                                ],
                                                [
                                                    2.4453,
                                                    2.6578
                                                ],
                                                [
                                                    1.8149,
                                                    2.6578
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Repair",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 3598
                                        }
                                    ],
                                    "value": "Repair",
                                    "value_type": "string"
                                },
                                "Details of work done and components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    2.6944,
                                                    2.4494
                                                ],
                                                [
                                                    5.4955,
                                                    2.4494
                                                ],
                                                [
                                                    5.4955,
                                                    2.9068
                                                ],
                                                [
                                                    2.6944,
                                                    2.9068
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Repair is carried out andLeak test in satisfactory",
                                    "spans": [
                                        {
                                            "length": 51,
                                            "offset": 3605
                                        }
                                    ],
                                    "value": "Repair is carried out and Leak test in satisfactory",
                                    "value_type": "string"
                                },
                                "date declared serviceable or unserviceable": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    5.7853,
                                                    2.6374
                                                ],
                                                [
                                                    6.6495,
                                                    2.6374
                                                ],
                                                [
                                                    6.6495,
                                                    2.8763
                                                ],
                                                [
                                                    5.7853,
                                                    2.8763
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "16/05/23",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 3657
                                        }
                                    ],
                                    "value": "16/05/23",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "TRANSFER RECORD": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    1.0015,
                                                    6.3369
                                                ],
                                                [
                                                    1.5556,
                                                    6.3369
                                                ],
                                                [
                                                    1.5556,
                                                    6.5656
                                                ],
                                                [
                                                    1.0015,
                                                    6.5656
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "24/02/23",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 4040
                                        }
                                    ],
                                    "value": "24/02/23",
                                    "value_type": "string"
                                },
                                "Received from": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    1.7183,
                                                    6.3217
                                                ],
                                                [
                                                    6.7664,
                                                    6.3217
                                                ],
                                                [
                                                    6.7664,
                                                    6.779
                                                ],
                                                [
                                                    1.7183,
                                                    6.779
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Transferred to HAL for1200 hrs servicing at1799.51AF Hrs and1799. 51 comp hos",
                                    "spans": [
                                        {
                                            "length": 81,
                                            "offset": 4049
                                        }
                                    ],
                                    "value": "Transferred to HAL for 1200 hrs servicing at 1799.51 AF Hrs and 1799. 51 comp hos",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "TBO": null,
                    "Canister Pt No.": null,
                    "Job No.": null,
                    "Authorised overhaul life": null,
                    "LOG CARD No.": null,
                    "SL No": null,
                    "Ref.No": null,
                    "Delete as appropriate": null,
                    "AUTH": null,
                    "No. of overhauls done": null,
                    "Section No": null,
                    "I.A.F. Catalogue No": null,
                    "Contract No.": null
                }
            ]
        ]
    },
    "https://formrecognizer12345.blob.core.windows.net/form-recognizer/formrecognizer12345/test/HAL/20231129-102004%25LC160001.pdf": {
        "message": "success",
        "data": [
            [
                {
                    "Opened at Unit/Station": "BANGALORE",
                    "Opened by": "HAL. He MRO DIV.",
                    "Opened at Date": "01.03.10",
                    "Nomenclature": "FIRE EXTINGUISHER BOTTLE",
                    "Type & MK": "ALH",
                    "Manufacturers Part No": "898153-4",
                    "Date of Manufacture": "12-09-2008",
                    "Serial No": "4983DH",
                    "Date of Receipt in India": "24-10-2008.",
                    "Details of Major equipment or installation of which the item forms Part": "J.ENGINE FIRE EXTINGUISHING SYSTEM.",
                    "Type of A/C or A/E on which installed": "ACH:",
                    "Overhaul agency": "M)e KIDDe GRAVINER .LTD. U.15.",
                    "Authorised overhaul life": "ON CONDITION",
                    "Certified that": "FIRE EXT BOTTLE.",
                    "Mark": "898153-4",
                    "No": "4983DH",
                    "Job No.": "NO.200.",
                    "Modification embodied": "HAST DONE: 23/07/13 HST Due : - 22/07/18 TBO :- ON CONDITION. Cartridge details TTL :- UNLIMITED. SNO DOM NOTE: i) Weight check - 1 year. 11) Hydro static test - 5 years? 0408024851 04-08 LHST. 01/2018 03/2023 0408024845 04-08 NHST -01/2023 03/2028 weight (5111 Lbs) =5.24Lbs",
                    "DATE": "01.03.10",
                    "INSTALLATION RECORD": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E SI No. on which installed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    0.9252,
                                                    7.709
                                                ],
                                                [
                                                    1.5658,
                                                    7.709
                                                ],
                                                [
                                                    1.5658,
                                                    7.9174
                                                ],
                                                [
                                                    0.9252,
                                                    7.9174
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "J 4051",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1593
                                        }
                                    ],
                                    "value": "J 4051",
                                    "value_type": "string"
                                },
                                "Component Hrs at installation": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    2.0742,
                                                    7.7649
                                                ],
                                                [
                                                    2.6232,
                                                    7.7649
                                                ],
                                                [
                                                    2.6232,
                                                    7.9275
                                                ],
                                                [
                                                    2.0742,
                                                    7.9275
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "00:00",
                                    "spans": [
                                        {
                                            "length": 5,
                                            "offset": 1600
                                        }
                                    ],
                                    "value": "00:00",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.2028,
                                                    7.7395
                                                ],
                                                [
                                                    3.7315,
                                                    7.7395
                                                ],
                                                [
                                                    3.7315,
                                                    7.9326
                                                ],
                                                [
                                                    3.2028,
                                                    7.9326
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "01.03.10",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 1606
                                        }
                                    ],
                                    "value": "01.03.10",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.767,
                                                    7.7395
                                                ],
                                                [
                                                    4.3517,
                                                    7.7395
                                                ],
                                                [
                                                    4.3517,
                                                    7.9174
                                                ],
                                                [
                                                    3.767,
                                                    7.9174
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "434:35",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1615
                                        }
                                    ],
                                    "value": "434:35",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.5144,
                                                    7.6988
                                                ],
                                                [
                                                    7.0816,
                                                    7.6988
                                                ],
                                                [
                                                    7.0816,
                                                    7.9224
                                                ],
                                                [
                                                    4.5144,
                                                    7.9224
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "(On Installation at HAL Bangalore",
                                    "spans": [
                                        {
                                            "length": 33,
                                            "offset": 1622
                                        }
                                    ],
                                    "value": "(On Installation at HAL Bangalore",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E SI No. on which installed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    0.8236,
                                                    7.9834
                                                ],
                                                [
                                                    1.52,
                                                    7.9834
                                                ],
                                                [
                                                    1.52,
                                                    8.1816
                                                ],
                                                [
                                                    0.8236,
                                                    8.1816
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "J-4051",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1658
                                        }
                                    ],
                                    "value": "J-4051",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.3212,
                                                    7.9428
                                                ],
                                                [
                                                    4.7177,
                                                    7.9428
                                                ],
                                                [
                                                    4.7177,
                                                    8.1613
                                                ],
                                                [
                                                    4.3212,
                                                    8.1613
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "2700 02",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1665
                                        }
                                    ],
                                    "value": "2700 02",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.9109,
                                                    7.958
                                                ],
                                                [
                                                    5.4294,
                                                    7.958
                                                ],
                                                [
                                                    5.4294,
                                                    8.141
                                                ],
                                                [
                                                    4.9109,
                                                    8.141
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "549:25",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1673
                                        }
                                    ],
                                    "value": "549:25",
                                    "value_type": "string"
                                },
                                "Component Hours at removal coi. 2": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    5.6226,
                                                    7.9478
                                                ],
                                                [
                                                    6.802,
                                                    7.9478
                                                ],
                                                [
                                                    6.802,
                                                    8.1562
                                                ],
                                                [
                                                    5.6226,
                                                    8.1562
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "114:50 ths.",
                                    "spans": [
                                        {
                                            "length": 11,
                                            "offset": 1680
                                        }
                                    ],
                                    "value": "114:50 ths.",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    7.0054,
                                                    7.9428
                                                ],
                                                [
                                                    7.9154,
                                                    7.9428
                                                ],
                                                [
                                                    7.9154,
                                                    8.146
                                                ],
                                                [
                                                    7.0054,
                                                    8.146
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "For Whichck",
                                    "spans": [
                                        {
                                            "length": 11,
                                            "offset": 1692
                                        }
                                    ],
                                    "value": "For Whichck",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E SI No. on which installed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    0.849,
                                                    8.2172
                                                ],
                                                [
                                                    1.5912,
                                                    8.2172
                                                ],
                                                [
                                                    1.5912,
                                                    8.4103
                                                ],
                                                [
                                                    0.849,
                                                    8.4103
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "J-4051",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1706
                                        }
                                    ],
                                    "value": "J-4051",
                                    "value_type": "string"
                                },
                                "Component Hrs at installation": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    1.9674,
                                                    8.207
                                                ],
                                                [
                                                    3.1519,
                                                    8.207
                                                ],
                                                [
                                                    3.1519,
                                                    8.4103
                                                ],
                                                [
                                                    1.9674,
                                                    8.4103
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "114: 50 Hrs.",
                                    "spans": [
                                        {
                                            "length": 12,
                                            "offset": 1713
                                        }
                                    ],
                                    "value": "114: 50 Hrs.",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.1875,
                                                    8.207
                                                ],
                                                [
                                                    3.6145,
                                                    8.207
                                                ],
                                                [
                                                    3.6145,
                                                    8.4204
                                                ],
                                                [
                                                    3.1875,
                                                    8.4204
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "277",
                                    "spans": [
                                        {
                                            "length": 3,
                                            "offset": 1726
                                        }
                                    ],
                                    "value": "277",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.7772,
                                                    8.1968
                                                ],
                                                [
                                                    4.2805,
                                                    8.1968
                                                ],
                                                [
                                                    4.2805,
                                                    8.39
                                                ],
                                                [
                                                    3.7772,
                                                    8.39
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "549:25",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1730
                                        }
                                    ],
                                    "value": "549:25",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E SI No. on which installed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    0.8286,
                                                    8.4916
                                                ],
                                                [
                                                    1.4692,
                                                    8.4916
                                                ],
                                                [
                                                    1.4692,
                                                    8.6745
                                                ],
                                                [
                                                    0.8286,
                                                    8.6745
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "J-4051",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1739
                                        }
                                    ],
                                    "value": "J-4051",
                                    "value_type": "string"
                                },
                                "Component Hrs at installation": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    2.4859,
                                                    8.5475
                                                ],
                                                [
                                                    2.5266,
                                                    8.5475
                                                ],
                                                [
                                                    2.5266,
                                                    8.6237
                                                ],
                                                [
                                                    2.4859,
                                                    8.6237
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "-",
                                    "spans": [
                                        {
                                            "length": 1,
                                            "offset": 1746
                                        }
                                    ],
                                    "value": "-",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.4417,
                                                    8.5373
                                                ],
                                                [
                                                    3.4874,
                                                    8.5373
                                                ],
                                                [
                                                    3.4874,
                                                    8.6085
                                                ],
                                                [
                                                    3.4417,
                                                    8.6085
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "-",
                                    "spans": [
                                        {
                                            "length": 1,
                                            "offset": 1748
                                        }
                                    ],
                                    "value": "-",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.9501,
                                                    8.5475
                                                ],
                                                [
                                                    3.9907,
                                                    8.5475
                                                ],
                                                [
                                                    3.9907,
                                                    8.6136
                                                ],
                                                [
                                                    3.9501,
                                                    8.6136
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "-",
                                    "spans": [
                                        {
                                            "length": 1,
                                            "offset": 1750
                                        }
                                    ],
                                    "value": "-",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.3212,
                                                    8.4357
                                                ],
                                                [
                                                    4.8245,
                                                    8.4357
                                                ],
                                                [
                                                    4.8245,
                                                    8.6339
                                                ],
                                                [
                                                    4.3212,
                                                    8.6339
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "07.3 . 12",
                                    "spans": [
                                        {
                                            "length": 9,
                                            "offset": 1752
                                        }
                                    ],
                                    "value": "07.3 . 12",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.9211,
                                                    8.4713
                                                ],
                                                [
                                                    5.4193,
                                                    8.4713
                                                ],
                                                [
                                                    5.4193,
                                                    8.6542
                                                ],
                                                [
                                                    4.9211,
                                                    8.6542
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "769:10",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1762
                                        }
                                    ],
                                    "value": "769:10",
                                    "value_type": "string"
                                },
                                "Component Hours at removal coi. 2": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    5.6277,
                                                    8.4509
                                                ],
                                                [
                                                    6.3394,
                                                    8.4509
                                                ],
                                                [
                                                    6.3394,
                                                    8.6644
                                                ],
                                                [
                                                    5.6277,
                                                    8.6644
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "334:35",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1769
                                        }
                                    ],
                                    "value": "334:35",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    7.0003,
                                                    8.4255
                                                ],
                                                [
                                                    8.2865,
                                                    8.4255
                                                ],
                                                [
                                                    8.2865,
                                                    8.6999
                                                ],
                                                [
                                                    7.0003,
                                                    8.6999
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "wt check our pe",
                                    "spans": [
                                        {
                                            "length": 15,
                                            "offset": 1776
                                        }
                                    ],
                                    "value": "wt check our pe",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E SI No. on which installed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    0.7931,
                                                    8.7254
                                                ],
                                                [
                                                    1.515,
                                                    8.7254
                                                ],
                                                [
                                                    1.515,
                                                    8.9032
                                                ],
                                                [
                                                    0.7931,
                                                    8.9032
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "J - 4051",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 1792
                                        }
                                    ],
                                    "value": "J - 4051",
                                    "value_type": "string"
                                },
                                "Component Hrs at installation": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    1.9827,
                                                    8.7203
                                                ],
                                                [
                                                    2.7401,
                                                    8.7203
                                                ],
                                                [
                                                    2.7401,
                                                    8.8981
                                                ],
                                                [
                                                    1.9827,
                                                    8.8981
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "334: 35",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1801
                                        }
                                    ],
                                    "value": "334: 35",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.2028,
                                                    8.7254
                                                ],
                                                [
                                                    3.7162,
                                                    8.7254
                                                ],
                                                [
                                                    3.7162,
                                                    8.893
                                                ],
                                                [
                                                    3.2028,
                                                    8.893
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "07. 3. 12",
                                    "spans": [
                                        {
                                            "length": 9,
                                            "offset": 1809
                                        }
                                    ],
                                    "value": "07. 3. 12",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.8026,
                                                    8.7304
                                                ],
                                                [
                                                    4.25,
                                                    8.7304
                                                ],
                                                [
                                                    4.25,
                                                    8.9032
                                                ],
                                                [
                                                    3.8026,
                                                    8.9032
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "769: 10",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1819
                                        }
                                    ],
                                    "value": "769: 10",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.4788,
                                                    8.7711
                                                ],
                                                [
                                                    4.5245,
                                                    8.7711
                                                ],
                                                [
                                                    4.5245,
                                                    8.8422
                                                ],
                                                [
                                                    4.4788,
                                                    8.8422
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "-",
                                    "spans": [
                                        {
                                            "length": 1,
                                            "offset": 1827
                                        }
                                    ],
                                    "value": "-",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    5.1244,
                                                    8.766
                                                ],
                                                [
                                                    5.1651,
                                                    8.766
                                                ],
                                                [
                                                    5.1651,
                                                    8.827
                                                ],
                                                [
                                                    5.1244,
                                                    8.827
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "-",
                                    "spans": [
                                        {
                                            "length": 1,
                                            "offset": 1829
                                        }
                                    ],
                                    "value": "-",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    6.9393,
                                                    8.6745
                                                ],
                                                [
                                                    8.3678,
                                                    8.6745
                                                ],
                                                [
                                                    8.3678,
                                                    8.9032
                                                ],
                                                [
                                                    6.9393,
                                                    8.9032
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "aftern't check dour",
                                    "spans": [
                                        {
                                            "length": 19,
                                            "offset": 1831
                                        }
                                    ],
                                    "value": "aftern't check dour",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E SI No. on which installed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    0.8337,
                                                    8.9693
                                                ],
                                                [
                                                    1.5455,
                                                    8.9693
                                                ],
                                                [
                                                    1.5455,
                                                    9.1471
                                                ],
                                                [
                                                    0.8337,
                                                    9.1471
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "J-4051",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1851
                                        }
                                    ],
                                    "value": "J-4051",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.4076,
                                                    8.9489
                                                ],
                                                [
                                                    4.9261,
                                                    8.9489
                                                ],
                                                [
                                                    4.9261,
                                                    9.1319
                                                ],
                                                [
                                                    4.4076,
                                                    9.1319
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "22/5/13",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1858
                                        }
                                    ],
                                    "value": "22/5/13",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.9719,
                                                    8.9591
                                                ],
                                                [
                                                    5.4294,
                                                    8.9591
                                                ],
                                                [
                                                    5.4294,
                                                    9.1471
                                                ],
                                                [
                                                    4.9719,
                                                    9.1471
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1017: 15",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 1866
                                        }
                                    ],
                                    "value": "1017: 15",
                                    "value_type": "string"
                                },
                                "Component Hours at removal coi. 2": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    5.7446,
                                                    8.9693
                                                ],
                                                [
                                                    6.4818,
                                                    8.9693
                                                ],
                                                [
                                                    6.4818,
                                                    9.1675
                                                ],
                                                [
                                                    5.7446,
                                                    9.1675
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "582:40",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1875
                                        }
                                    ],
                                    "value": "582:40",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    6.6699,
                                                    8.9794
                                                ],
                                                [
                                                    7.6866,
                                                    8.9794
                                                ],
                                                [
                                                    7.6866,
                                                    9.1725
                                                ],
                                                [
                                                    6.6699,
                                                    9.1725
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "for sgravicry",
                                    "spans": [
                                        {
                                            "length": 13,
                                            "offset": 1882
                                        }
                                    ],
                                    "value": "for sgravicry",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E SI No. on which installed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    0.788,
                                                    9.2234
                                                ],
                                                [
                                                    1.6217,
                                                    9.2234
                                                ],
                                                [
                                                    1.6217,
                                                    9.4114
                                                ],
                                                [
                                                    0.788,
                                                    9.4114
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "D-4044",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1896
                                        }
                                    ],
                                    "value": "D-4044",
                                    "value_type": "string"
                                },
                                "Component Hrs at installation": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    2.1911,
                                                    9.2132
                                                ],
                                                [
                                                    2.7859,
                                                    9.2132
                                                ],
                                                [
                                                    2.7859,
                                                    9.386
                                                ],
                                                [
                                                    2.1911,
                                                    9.386
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "582:40",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1903
                                        }
                                    ],
                                    "value": "582:40",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.2078,
                                                    9.2183
                                                ],
                                                [
                                                    3.7213,
                                                    9.2183
                                                ],
                                                [
                                                    3.7213,
                                                    9.3911
                                                ],
                                                [
                                                    3.2078,
                                                    9.3911
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "20/09/13",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 1910
                                        }
                                    ],
                                    "value": "20/09/13",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.7721,
                                                    9.2234
                                                ],
                                                [
                                                    4.2297,
                                                    9.2234
                                                ],
                                                [
                                                    4.2297,
                                                    9.3961
                                                ],
                                                [
                                                    3.7721,
                                                    9.3961
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "494:35",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1919
                                        }
                                    ],
                                    "value": "494:35",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.2653,
                                                    9.198
                                                ],
                                                [
                                                    6.9647,
                                                    9.198
                                                ],
                                                [
                                                    6.9647,
                                                    9.3911
                                                ],
                                                [
                                                    4.2653,
                                                    9.3911
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "(On Installation at HAL Bangalore)",
                                    "spans": [
                                        {
                                            "length": 34,
                                            "offset": 1926
                                        }
                                    ],
                                    "value": "(On Installation at HAL Bangalore)",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E SI No. on which installed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    0.849,
                                                    9.4774
                                                ],
                                                [
                                                    1.6319,
                                                    9.4774
                                                ],
                                                [
                                                    1.6319,
                                                    9.6248
                                                ],
                                                [
                                                    0.849,
                                                    9.6248
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "20-4044",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1987
                                        }
                                    ],
                                    "value": "20-4044",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.3059,
                                                    9.452
                                                ],
                                                [
                                                    4.8499,
                                                    9.452
                                                ],
                                                [
                                                    4.8499,
                                                    9.6451
                                                ],
                                                [
                                                    4.3059,
                                                    9.6451
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "24/11/17",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 1999
                                        }
                                    ],
                                    "value": "24/11/17",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.9465,
                                                    9.4571
                                                ],
                                                [
                                                    5.465,
                                                    9.4571
                                                ],
                                                [
                                                    5.465,
                                                    9.6451
                                                ],
                                                [
                                                    4.9465,
                                                    9.6451
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "888.00",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 2008
                                        }
                                    ],
                                    "value": "888.00",
                                    "value_type": "string"
                                },
                                "Component Hours at removal coi. 2": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    5.6379,
                                                    9.4317
                                                ],
                                                [
                                                    6.2784,
                                                    9.4317
                                                ],
                                                [
                                                    6.2784,
                                                    9.6655
                                                ],
                                                [
                                                    5.6379,
                                                    9.6655
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "976:05",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1961
                                        }
                                    ],
                                    "value": "976:05",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    6.436,
                                                    9.4215
                                                ],
                                                [
                                                    7.2494,
                                                    9.4215
                                                ],
                                                [
                                                    7.2494,
                                                    9.6604
                                                ],
                                                [
                                                    6.436,
                                                    9.6604
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "for HAST",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 1968
                                        }
                                    ],
                                    "value": "for HAST",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E SI No. on which installed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    0.8439,
                                                    9.7112
                                                ],
                                                [
                                                    1.5963,
                                                    9.7112
                                                ],
                                                [
                                                    1.5963,
                                                    9.884
                                                ],
                                                [
                                                    0.8439,
                                                    9.884
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "ZD-4051",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 2015
                                        }
                                    ],
                                    "value": "ZD-4051",
                                    "value_type": "string"
                                },
                                "Component Hrs at installation": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    2.1097,
                                                    9.701
                                                ],
                                                [
                                                    2.7045,
                                                    9.701
                                                ],
                                                [
                                                    2.7045,
                                                    9.8891
                                                ],
                                                [
                                                    2.1097,
                                                    9.8891
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "976:05",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 2023
                                        }
                                    ],
                                    "value": "976:05",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.2434,
                                                    9.701
                                                ],
                                                [
                                                    3.6196,
                                                    9.701
                                                ],
                                                [
                                                    3.6196,
                                                    9.8992
                                                ],
                                                [
                                                    3.2434,
                                                    9.8992
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "5/9/18",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 2030
                                        }
                                    ],
                                    "value": "5/9/18",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.7416,
                                                    9.7366
                                                ],
                                                [
                                                    4.2347,
                                                    9.7366
                                                ],
                                                [
                                                    4.2347,
                                                    9.884
                                                ],
                                                [
                                                    3.7416,
                                                    9.884
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1492:05",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 2037
                                        }
                                    ],
                                    "value": "1492:05",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.4737,
                                                    9.7214
                                                ],
                                                [
                                                    6.919,
                                                    9.7214
                                                ],
                                                [
                                                    6.919,
                                                    9.8942
                                                ],
                                                [
                                                    4.4737,
                                                    9.8942
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "in installation at HAL (B1)",
                                    "spans": [
                                        {
                                            "length": 27,
                                            "offset": 2045
                                        }
                                    ],
                                    "value": "in installation at HAL (B1)",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E At removal Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.3669,
                                                    9.9348
                                                ],
                                                [
                                                    4.86,
                                                    9.9348
                                                ],
                                                [
                                                    4.86,
                                                    10.133
                                                ],
                                                [
                                                    4.3669,
                                                    10.133
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "05/01/23",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 2073
                                        }
                                    ],
                                    "value": "05/01/23",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.8906,
                                                    9.9348
                                                ],
                                                [
                                                    5.4345,
                                                    9.9348
                                                ],
                                                [
                                                    5.4345,
                                                    10.133
                                                ],
                                                [
                                                    4.8906,
                                                    10.133
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "2113:40",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 2082
                                        }
                                    ],
                                    "value": "2113:40",
                                    "value_type": "string"
                                },
                                "Component Hours at removal coi. 2": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    5.4599,
                                                    9.9196
                                                ],
                                                [
                                                    6.5275,
                                                    9.9196
                                                ],
                                                [
                                                    6.5275,
                                                    10.133
                                                ],
                                                [
                                                    5.4599,
                                                    10.133
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "15971 40",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 2090
                                        }
                                    ],
                                    "value": "15971 40",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    6.5478,
                                                    9.9145
                                                ],
                                                [
                                                    6.8732,
                                                    9.9145
                                                ],
                                                [
                                                    6.8732,
                                                    10.1228
                                                ],
                                                [
                                                    6.5478,
                                                    10.1228
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "HST",
                                    "spans": [
                                        {
                                            "length": 3,
                                            "offset": 2099
                                        }
                                    ],
                                    "value": "HST",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "PART DISCREPANCY REPORT (PDR)": [],
                    "PART DISCREPANCY REPORT - 2 (PDR-2)": [],
                    "MAJOR UNSERVICEABILITY RECORD": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date placed U/S": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    0.7371,
                                                    1.9107
                                                ],
                                                [
                                                    1.4285,
                                                    1.9107
                                                ],
                                                [
                                                    1.4285,
                                                    2.114
                                                ],
                                                [
                                                    0.7371,
                                                    2.114
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "24/11/2017",
                                    "spans": [
                                        {
                                            "length": 10,
                                            "offset": 3745
                                        }
                                    ],
                                    "value": "24/11/2017",
                                    "value_type": "string"
                                },
                                "Reason for unserviceability (U/S)": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    1.6624,
                                                    1.9107
                                                ],
                                                [
                                                    2.5927,
                                                    1.9107
                                                ],
                                                [
                                                    2.5927,
                                                    2.114
                                                ],
                                                [
                                                    1.6624,
                                                    2.114
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Due los HST",
                                    "spans": [
                                        {
                                            "length": 11,
                                            "offset": 3756
                                        }
                                    ],
                                    "value": "Due los HST",
                                    "value_type": "string"
                                },
                                "Hours done since last overhaul or since new": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    2.9435,
                                                    1.9209
                                                ],
                                                [
                                                    3.4722,
                                                    1.9209
                                                ],
                                                [
                                                    3.4722,
                                                    2.1191
                                                ],
                                                [
                                                    2.9435,
                                                    2.1191
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "976:05",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 3768
                                        }
                                    ],
                                    "value": "976:05",
                                    "value_type": "string"
                                },
                                "Work done to rectify the fault & Components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    3.9551,
                                                    1.9361
                                                ],
                                                [
                                                    5.765,
                                                    1.9361
                                                ],
                                                [
                                                    5.765,
                                                    2.861
                                                ],
                                                [
                                                    3.9551,
                                                    2.861
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "HST & ReHUL carriedalt & found satisfactoryas per vender reportMAI2018/L/0117",
                                    "spans": [
                                        {
                                            "length": 44,
                                            "offset": 3775
                                        },
                                        {
                                            "length": 35,
                                            "offset": 3829
                                        }
                                    ],
                                    "value": "HST & ReHUL carried alt & found satisfactory as per vender report MAI2018/L/0117",
                                    "value_type": "string"
                                },
                                "Date placed 'S'": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    5.8056,
                                                    2.1902
                                                ],
                                                [
                                                    6.4767,
                                                    2.1902
                                                ],
                                                [
                                                    6.4767,
                                                    2.4748
                                                ],
                                                [
                                                    5.8056,
                                                    2.4748
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "6/1/2018",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 3820
                                        }
                                    ],
                                    "value": "6/1/2018",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date placed U/S": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    0.6711,
                                                    3.0033
                                                ],
                                                [
                                                    1.3624,
                                                    3.0033
                                                ],
                                                [
                                                    1.3624,
                                                    3.232
                                                ],
                                                [
                                                    0.6711,
                                                    3.232
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "05/01/2023",
                                    "spans": [
                                        {
                                            "length": 10,
                                            "offset": 3865
                                        }
                                    ],
                                    "value": "05/01/2023",
                                    "value_type": "string"
                                },
                                "Reason for unserviceability (U/S)": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    1.5505,
                                                    3.0236
                                                ],
                                                [
                                                    2.4504,
                                                    3.0236
                                                ],
                                                [
                                                    2.4504,
                                                    3.2218
                                                ],
                                                [
                                                    1.5505,
                                                    3.2218
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Due for HST",
                                    "spans": [
                                        {
                                            "length": 11,
                                            "offset": 3876
                                        }
                                    ],
                                    "value": "Due for HST",
                                    "value_type": "string"
                                },
                                "Hours done since last overhaul or since new": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    2.7961,
                                                    3.0338
                                                ],
                                                [
                                                    3.3553,
                                                    3.0338
                                                ],
                                                [
                                                    3.3553,
                                                    3.232
                                                ],
                                                [
                                                    2.7961,
                                                    3.232
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1597.40",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 3888
                                        }
                                    ],
                                    "value": "1597.40",
                                    "value_type": "string"
                                },
                                "Work done to rectify the fault & Components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    3.9348,
                                                    3.0236
                                                ],
                                                [
                                                    5.526,
                                                    3.0236
                                                ],
                                                [
                                                    5.526,
                                                    4.1874
                                                ],
                                                [
                                                    3.9348,
                                                    4.1874
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "HST of Petul 410ffound satisfactory asper vendor Report(Re) HAL B/N. MAI2023/1/2054",
                                    "spans": [
                                        {
                                            "length": 39,
                                            "offset": 3896
                                        },
                                        {
                                            "length": 17,
                                            "offset": 3939
                                        },
                                        {
                                            "length": 29,
                                            "offset": 3979
                                        }
                                    ],
                                    "value": "HST of Petul 410f found satisfactory as per vendor Report (Re) HAL B/N. MAI 2023/1/2054",
                                    "value_type": "string"
                                },
                                "Date placed 'S'": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    5.7853,
                                                    3.4607
                                                ],
                                                [
                                                    6.5173,
                                                    3.4607
                                                ],
                                                [
                                                    6.5173,
                                                    3.7249
                                                ],
                                                [
                                                    5.7853,
                                                    3.7249
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "14/9/2023",
                                    "spans": [
                                        {
                                            "length": 9,
                                            "offset": 3957
                                        }
                                    ],
                                    "value": "14/9/2023",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "PERIODICAL SERVICING RECORD": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.0066,
                                                    2.1343
                                                ],
                                                [
                                                    1.5658,
                                                    2.1343
                                                ],
                                                [
                                                    1.5658,
                                                    2.2868
                                                ],
                                                [
                                                    1.0066,
                                                    2.2868
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "9).03.10",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 4384
                                        }
                                    ],
                                    "value": "9).03.10",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.7488,
                                                    2.0988
                                                ],
                                                [
                                                    2.8418,
                                                    2.0988
                                                ],
                                                [
                                                    2.8418,
                                                    2.2919
                                                ],
                                                [
                                                    1.7488,
                                                    2.2919
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Weight check,",
                                    "spans": [
                                        {
                                            "length": 13,
                                            "offset": 4393
                                        }
                                    ],
                                    "value": "Weight check,",
                                    "value_type": "string"
                                },
                                "Details of work done and components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    2.7605,
                                                    2.0581
                                                ],
                                                [
                                                    5.3735,
                                                    2.0581
                                                ],
                                                [
                                                    5.3735,
                                                    2.5053
                                                ],
                                                [
                                                    2.7605,
                                                    2.5053
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "weight checks done andweight is 2.339 kgs.",
                                    "spans": [
                                        {
                                            "length": 22,
                                            "offset": 4407
                                        },
                                        {
                                            "length": 20,
                                            "offset": 4444
                                        }
                                    ],
                                    "value": "weight checks done and weight is 2.339 kgs.",
                                    "value_type": "string"
                                },
                                "date declared serviceable or unserviceable": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    5.6785,
                                                    2.302
                                                ],
                                                [
                                                    6.4106,
                                                    2.302
                                                ],
                                                [
                                                    6.4106,
                                                    2.4596
                                                ],
                                                [
                                                    5.6785,
                                                    2.4596
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "01.03.10",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 4465
                                        }
                                    ],
                                    "value": "01.03.10",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    0.9761,
                                                    2.5155
                                                ],
                                                [
                                                    1.6014,
                                                    2.5155
                                                ],
                                                [
                                                    1.6014,
                                                    2.7035
                                                ],
                                                [
                                                    0.9761,
                                                    2.7035
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "27. 0211",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 4474
                                        },
                                        {
                                            "length": 2,
                                            "offset": 4483
                                        }
                                    ],
                                    "value": "27. 02 11",
                                    "value_type": "string"
                                },
                                "Details of work done and components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    2.6435,
                                                    2.4951
                                                ],
                                                [
                                                    5.6684,
                                                    2.4951
                                                ],
                                                [
                                                    5.6684,
                                                    2.6933
                                                ],
                                                [
                                                    2.6435,
                                                    2.6933
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "weight check done and weight's33g/kg",
                                    "spans": [
                                        {
                                            "length": 30,
                                            "offset": 4486
                                        },
                                        {
                                            "length": 6,
                                            "offset": 4520
                                        }
                                    ],
                                    "value": "weight check done and weight's 33g/kg",
                                    "value_type": "string"
                                },
                                "date declared serviceable or unserviceable": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    5.7955,
                                                    2.5104
                                                ],
                                                [
                                                    6.1513,
                                                    2.5104
                                                ],
                                                [
                                                    6.1513,
                                                    2.6984
                                                ],
                                                [
                                                    5.7955,
                                                    2.6984
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "'S'",
                                    "spans": [
                                        {
                                            "length": 3,
                                            "offset": 4527
                                        }
                                    ],
                                    "value": "'S'",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    0.9659,
                                                    2.7797
                                                ],
                                                [
                                                    1.5556,
                                                    2.7797
                                                ],
                                                [
                                                    1.5556,
                                                    2.9423
                                                ],
                                                [
                                                    0.9659,
                                                    2.9423
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "07.03.12",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 4531
                                        }
                                    ],
                                    "value": "07.03.12",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.6217,
                                                    2.7746
                                                ],
                                                [
                                                    2.3792,
                                                    2.7746
                                                ],
                                                [
                                                    2.3792,
                                                    2.9423
                                                ],
                                                [
                                                    1.6217,
                                                    2.9423
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "4+ check",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 4540
                                        }
                                    ],
                                    "value": "4+ check",
                                    "value_type": "string"
                                },
                                "Details of work done and components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    2.613,
                                                    2.7238
                                                ],
                                                [
                                                    5.5819,
                                                    2.7238
                                                ],
                                                [
                                                    5.5819,
                                                    3.171
                                                ],
                                                [
                                                    2.613,
                                                    3.171
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "nit check carried ont. 2.34 kgItem received back after HST & found",
                                    "spans": [
                                        {
                                            "length": 30,
                                            "offset": 4549
                                        },
                                        {
                                            "length": 36,
                                            "offset": 4602
                                        }
                                    ],
                                    "value": "nit check carried ont. 2.34 kg Item received back after HST & found",
                                    "value_type": "string"
                                },
                                "date declared serviceable or unserviceable": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    5.9124,
                                                    2.7035
                                                ],
                                                [
                                                    6.8427,
                                                    2.7035
                                                ],
                                                [
                                                    6.8427,
                                                    2.8915
                                                ],
                                                [
                                                    5.9124,
                                                    2.8915
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "'s'07.3.12",
                                    "spans": [
                                        {
                                            "length": 10,
                                            "offset": 4580
                                        }
                                    ],
                                    "value": "'s'07.3.12",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.0269,
                                                    3.2269
                                                ],
                                                [
                                                    1.576,
                                                    3.2269
                                                ],
                                                [
                                                    1.576,
                                                    3.3895
                                                ],
                                                [
                                                    1.0269,
                                                    3.3895
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "22.5013",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 4650
                                        }
                                    ],
                                    "value": "22.5013",
                                    "value_type": "string"
                                },
                                "Details of work done and components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    2.6181,
                                                    3.1812
                                                ],
                                                [
                                                    5.5464,
                                                    3.1812
                                                ],
                                                [
                                                    5.5464,
                                                    3.4048
                                                ],
                                                [
                                                    2.6181,
                                                    3.4048
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "sat-as per render report- MA|2013/1/1250",
                                    "spans": [
                                        {
                                            "length": 40,
                                            "offset": 4658
                                        }
                                    ],
                                    "value": "sat-as per render report- MA|2013/1/1250",
                                    "value_type": "string"
                                },
                                "date declared serviceable or unserviceable": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    5.8412,
                                                    3.1608
                                                ],
                                                [
                                                    6.68,
                                                    3.1608
                                                ],
                                                [
                                                    6.68,
                                                    3.3641
                                                ],
                                                [
                                                    5.8412,
                                                    3.3641
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "23/07/2013",
                                    "spans": [
                                        {
                                            "length": 10,
                                            "offset": 4699
                                        }
                                    ],
                                    "value": "23/07/2013",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.0167,
                                                    3.4505
                                                ],
                                                [
                                                    1.5607,
                                                    3.4505
                                                ],
                                                [
                                                    1.5607,
                                                    3.6233
                                                ],
                                                [
                                                    1.0167,
                                                    3.6233
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "20.9.13",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 4714
                                        }
                                    ],
                                    "value": "20.9.13",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.6929,
                                                    3.4607
                                                ],
                                                [
                                                    2.4046,
                                                    3.4607
                                                ],
                                                [
                                                    2.4046,
                                                    3.6131
                                                ],
                                                [
                                                    1.6929,
                                                    3.6131
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "wt check",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 4722
                                        }
                                    ],
                                    "value": "wt check",
                                    "value_type": "string"
                                },
                                "Details of work done and components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    2.7401,
                                                    3.4403
                                                ],
                                                [
                                                    5.2362,
                                                    3.4403
                                                ],
                                                [
                                                    5.2362,
                                                    3.6385
                                                ],
                                                [
                                                    2.7401,
                                                    3.6385
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "wt. Check carried out 2.34kg",
                                    "spans": [
                                        {
                                            "length": 28,
                                            "offset": 4731
                                        }
                                    ],
                                    "value": "wt. Check carried out 2.34kg",
                                    "value_type": "string"
                                },
                                "date declared serviceable or unserviceable": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    5.6938,
                                                    3.4149
                                                ],
                                                [
                                                    6.7715,
                                                    3.4149
                                                ],
                                                [
                                                    6.7715,
                                                    3.5826
                                                ],
                                                [
                                                    5.6938,
                                                    3.5826
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "(3) 20.9.13",
                                    "spans": [
                                        {
                                            "length": 11,
                                            "offset": 4760
                                        }
                                    ],
                                    "value": "(3) 20.9.13",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.0523,
                                                    3.6792
                                                ],
                                                [
                                                    1.6014,
                                                    3.6792
                                                ],
                                                [
                                                    1.6014,
                                                    3.8113
                                                ],
                                                [
                                                    1.0523,
                                                    3.8113
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "10.09.14",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 4776
                                        }
                                    ],
                                    "value": "10.09.14",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.759,
                                                    3.6538
                                                ],
                                                [
                                                    2.4605,
                                                    3.6538
                                                ],
                                                [
                                                    2.4605,
                                                    3.791
                                                ],
                                                [
                                                    1.759,
                                                    3.791
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "wt. check",
                                    "spans": [
                                        {
                                            "length": 9,
                                            "offset": 4785
                                        }
                                    ],
                                    "value": "wt. check",
                                    "value_type": "string"
                                },
                                "Details of work done and components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    2.7045,
                                                    3.6385
                                                ],
                                                [
                                                    5.3227,
                                                    3.6385
                                                ],
                                                [
                                                    5.3227,
                                                    3.7961
                                                ],
                                                [
                                                    2.7045,
                                                    3.7961
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "cut. check carried out 2-332kg",
                                    "spans": [
                                        {
                                            "length": 30,
                                            "offset": 4795
                                        }
                                    ],
                                    "value": "cut. check carried out 2-332kg",
                                    "value_type": "string"
                                },
                                "date declared serviceable or unserviceable": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    6.2428,
                                                    3.6436
                                                ],
                                                [
                                                    6.802,
                                                    3.6436
                                                ],
                                                [
                                                    6.802,
                                                    3.7808
                                                ],
                                                [
                                                    6.2428,
                                                    3.7808
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "10.09.14",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 4826
                                        }
                                    ],
                                    "value": "10.09.14",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.0625,
                                                    3.8977
                                                ],
                                                [
                                                    1.6268,
                                                    3.8977
                                                ],
                                                [
                                                    1.6268,
                                                    4.0654
                                                ],
                                                [
                                                    1.0625,
                                                    4.0654
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "09/9/15",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 4839
                                        }
                                    ],
                                    "value": "09/9/15",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.7336,
                                                    3.8875
                                                ],
                                                [
                                                    2.5063,
                                                    3.8875
                                                ],
                                                [
                                                    2.5063,
                                                    4.0552
                                                ],
                                                [
                                                    1.7336,
                                                    4.0552
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "of check",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 4847
                                        }
                                    ],
                                    "value": "of check",
                                    "value_type": "string"
                                },
                                "Details of work done and components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    2.7249,
                                                    3.8672
                                                ],
                                                [
                                                    5.4752,
                                                    3.8672
                                                ],
                                                [
                                                    5.4752,
                                                    4.0552
                                                ],
                                                [
                                                    2.7249,
                                                    4.0552
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Wt. Check Carried out - 2-324kg",
                                    "spans": [
                                        {
                                            "length": 31,
                                            "offset": 4856
                                        }
                                    ],
                                    "value": "Wt. Check Carried out - 2-324kg",
                                    "value_type": "string"
                                },
                                "date declared serviceable or unserviceable": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    5.765,
                                                    3.8316
                                                ],
                                                [
                                                    6.7817,
                                                    3.8316
                                                ],
                                                [
                                                    6.7817,
                                                    4.0349
                                                ],
                                                [
                                                    5.765,
                                                    4.0349
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "'S 9/9/15",
                                    "spans": [
                                        {
                                            "length": 9,
                                            "offset": 4888
                                        }
                                    ],
                                    "value": "'S 9/9/15",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.0167,
                                                    4.101
                                                ],
                                                [
                                                    1.6573,
                                                    4.101
                                                ],
                                                [
                                                    1.6573,
                                                    4.2839
                                                ],
                                                [
                                                    1.0167,
                                                    4.2839
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "07/9/16",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 4898
                                        }
                                    ],
                                    "value": "07/9/16",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.7488,
                                                    4.101
                                                ],
                                                [
                                                    2.5215,
                                                    4.101
                                                ],
                                                [
                                                    2.5215,
                                                    4.2788
                                                ],
                                                [
                                                    1.7488,
                                                    4.2788
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Lot Check",
                                    "spans": [
                                        {
                                            "length": 9,
                                            "offset": 4906
                                        }
                                    ],
                                    "value": "Lot Check",
                                    "value_type": "string"
                                },
                                "Details of work done and components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    2.7503,
                                                    4.0857
                                                ],
                                                [
                                                    5.4955,
                                                    4.0857
                                                ],
                                                [
                                                    5.4955,
                                                    4.2737
                                                ],
                                                [
                                                    2.7503,
                                                    4.2737
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "wt check Carriedent. 2.320 g8",
                                    "spans": [
                                        {
                                            "length": 29,
                                            "offset": 4916
                                        }
                                    ],
                                    "value": "wt check Carriedent. 2.320 g8",
                                    "value_type": "string"
                                },
                                "date declared serviceable or unserviceable": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    5.7802,
                                                    4.0705
                                                ],
                                                [
                                                    6.7664,
                                                    4.0705
                                                ],
                                                [
                                                    6.7664,
                                                    4.2483
                                                ],
                                                [
                                                    5.7802,
                                                    4.2483
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "5 07/9/16",
                                    "spans": [
                                        {
                                            "length": 9,
                                            "offset": 4946
                                        }
                                    ],
                                    "value": "5 07/9/16",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.0167,
                                                    4.3042
                                                ],
                                                [
                                                    1.5658,
                                                    4.3042
                                                ],
                                                [
                                                    1.5658,
                                                    4.4669
                                                ],
                                                [
                                                    1.0167,
                                                    4.4669
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "05/9/17",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 4956
                                        }
                                    ],
                                    "value": "05/9/17",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.7742,
                                                    4.3093
                                                ],
                                                [
                                                    2.4859,
                                                    4.3093
                                                ],
                                                [
                                                    2.4859,
                                                    4.4567
                                                ],
                                                [
                                                    1.7742,
                                                    4.4567
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "wt check",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 4964
                                        }
                                    ],
                                    "value": "wt check",
                                    "value_type": "string"
                                },
                                "Details of work done and components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    2.7249,
                                                    4.2788
                                                ],
                                                [
                                                    5.4193,
                                                    4.2788
                                                ],
                                                [
                                                    5.4193,
                                                    4.4669
                                                ],
                                                [
                                                    2.7249,
                                                    4.4669
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Wt check carried out 2.319 kg",
                                    "spans": [
                                        {
                                            "length": 29,
                                            "offset": 4973
                                        }
                                    ],
                                    "value": "Wt check carried out 2.319 kg",
                                    "value_type": "string"
                                },
                                "date declared serviceable or unserviceable": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    5.7243,
                                                    4.2636
                                                ],
                                                [
                                                    6.7359,
                                                    4.2636
                                                ],
                                                [
                                                    6.7359,
                                                    4.4516
                                                ],
                                                [
                                                    5.7243,
                                                    4.4516
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "S' 04/9/18",
                                    "spans": [
                                        {
                                            "length": 10,
                                            "offset": 5003
                                        }
                                    ],
                                    "value": "S' 04/9/18",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.032,
                                                    4.5228
                                                ],
                                                [
                                                    1.4692,
                                                    4.5228
                                                ],
                                                [
                                                    1.4692,
                                                    4.7057
                                                ],
                                                [
                                                    1.032,
                                                    4.7057
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "24/11/17",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 5021
                                        }
                                    ],
                                    "value": "24/11/17",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.6725,
                                                    4.5634
                                                ],
                                                [
                                                    2.4351,
                                                    4.5634
                                                ],
                                                [
                                                    2.4351,
                                                    4.7057
                                                ],
                                                [
                                                    1.6725,
                                                    4.7057
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "HST & wt check",
                                    "spans": [
                                        {
                                            "length": 14,
                                            "offset": 5030
                                        }
                                    ],
                                    "value": "HST & wt check",
                                    "value_type": "string"
                                },
                                "Details of work done and components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    2.6944,
                                                    4.5024
                                                ],
                                                [
                                                    5.3633,
                                                    4.5024
                                                ],
                                                [
                                                    5.3633,
                                                    4.6904
                                                ],
                                                [
                                                    2.6944,
                                                    4.6904
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Wt-check carried out 2.317kg (5.11 lbs",
                                    "spans": [
                                        {
                                            "length": 38,
                                            "offset": 5045
                                        }
                                    ],
                                    "value": "Wt-check carried out 2.317kg (5.11 lbs",
                                    "value_type": "string"
                                },
                                "date declared serviceable or unserviceable": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    5.77,
                                                    4.4669
                                                ],
                                                [
                                                    6.7614,
                                                    4.4669
                                                ],
                                                [
                                                    6.7614,
                                                    4.665
                                                ],
                                                [
                                                    5.77,
                                                    4.665
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "5 03/09/18",
                                    "spans": [
                                        {
                                            "length": 10,
                                            "offset": 5084
                                        }
                                    ],
                                    "value": "5 03/09/18",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.0218,
                                                    4.7667
                                                ],
                                                [
                                                    1.5861,
                                                    4.7667
                                                ],
                                                [
                                                    1.5861,
                                                    4.9649
                                                ],
                                                [
                                                    1.0218,
                                                    4.9649
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "20/08/19",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 5099
                                        }
                                    ],
                                    "value": "20/08/19",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.7081,
                                                    4.7921
                                                ],
                                                [
                                                    2.3436,
                                                    4.7921
                                                ],
                                                [
                                                    2.3436,
                                                    4.9293
                                                ],
                                                [
                                                    1.7081,
                                                    4.9293
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "at check",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 5108
                                        }
                                    ],
                                    "value": "at check",
                                    "value_type": "string"
                                },
                                "Details of work done and components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    2.6995,
                                                    4.7362
                                                ],
                                                [
                                                    5.1092,
                                                    4.7362
                                                ],
                                                [
                                                    5.1092,
                                                    4.909
                                                ],
                                                [
                                                    2.6995,
                                                    4.909
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "lt check carried out 2.317kg",
                                    "spans": [
                                        {
                                            "length": 28,
                                            "offset": 5117
                                        }
                                    ],
                                    "value": "lt check carried out 2.317kg",
                                    "value_type": "string"
                                },
                                "date declared serviceable or unserviceable": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    5.6531,
                                                    4.7006
                                                ],
                                                [
                                                    6.6444,
                                                    4.7006
                                                ],
                                                [
                                                    6.6444,
                                                    4.8988
                                                ],
                                                [
                                                    5.6531,
                                                    4.8988
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "'s' 20/08/19",
                                    "spans": [
                                        {
                                            "length": 12,
                                            "offset": 5146
                                        }
                                    ],
                                    "value": "'s' 20/08/19",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.0167,
                                                    4.9496
                                                ],
                                                [
                                                    1.6217,
                                                    4.9496
                                                ],
                                                [
                                                    1.6217,
                                                    5.1021
                                                ],
                                                [
                                                    1.0167,
                                                    5.1021
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "06/11/20",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 5159
                                        }
                                    ],
                                    "value": "06/11/20",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.7285,
                                                    4.9395
                                                ],
                                                [
                                                    2.3792,
                                                    4.9395
                                                ],
                                                [
                                                    2.3792,
                                                    5.0868
                                                ],
                                                [
                                                    1.7285,
                                                    5.0868
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "WF check",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 5168
                                        }
                                    ],
                                    "value": "WF check",
                                    "value_type": "string"
                                },
                                "Details of work done and components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    2.7808,
                                                    4.9344
                                                ],
                                                [
                                                    5.1702,
                                                    4.9344
                                                ],
                                                [
                                                    5.1702,
                                                    5.1173
                                                ],
                                                [
                                                    2.7808,
                                                    5.1173
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "WF clock andfour 2.316 kg",
                                    "spans": [
                                        {
                                            "length": 25,
                                            "offset": 5177
                                        }
                                    ],
                                    "value": "WF clock andfour 2.316 kg",
                                    "value_type": "string"
                                },
                                "date declared serviceable or unserviceable": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    5.5769,
                                                    4.9547
                                                ],
                                                [
                                                    6.6139,
                                                    4.9547
                                                ],
                                                [
                                                    6.6139,
                                                    5.1427
                                                ],
                                                [
                                                    5.5769,
                                                    5.1427
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1s' 06/11/19",
                                    "spans": [
                                        {
                                            "length": 12,
                                            "offset": 5219
                                        }
                                    ],
                                    "value": "1s' 06/11/19",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "TRANSFER RECORD": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.0676,
                                                    6.4182
                                                ],
                                                [
                                                    1.5709,
                                                    6.4182
                                                ],
                                                [
                                                    1.5709,
                                                    6.5707
                                                ],
                                                [
                                                    1.0676,
                                                    6.5707
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "08.07-10",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 5624
                                        }
                                    ],
                                    "value": "08.07-10",
                                    "value_type": "string"
                                },
                                "Received from": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.8759,
                                                    6.3623
                                                ],
                                                [
                                                    6.7156,
                                                    6.3623
                                                ],
                                                [
                                                    6.7156,
                                                    6.6063
                                                ],
                                                [
                                                    1.8759,
                                                    6.6063
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "(Transfered to7Huafter 500 Hrs. Servicing) at 452:15Afhrs.",
                                    "spans": [
                                        {
                                            "length": 14,
                                            "offset": 5633
                                        },
                                        {
                                            "length": 45,
                                            "offset": 5650
                                        }
                                    ],
                                    "value": "(Transfered to 7Huafter 500 Hrs. Servicing) at 452:15 Afhrs.",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.0371,
                                                    6.6622
                                                ],
                                                [
                                                    1.6675,
                                                    6.6622
                                                ],
                                                [
                                                    1.6675,
                                                    6.8248
                                                ],
                                                [
                                                    1.0371,
                                                    6.8248
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "03.05.13",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 5706
                                        }
                                    ],
                                    "value": "03.05.13",
                                    "value_type": "string"
                                },
                                "Received from": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.7742,
                                                    6.591
                                                ],
                                                [
                                                    6.863,
                                                    6.591
                                                ],
                                                [
                                                    6.863,
                                                    6.8451
                                                ],
                                                [
                                                    1.7742,
                                                    6.8451
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Transfered & HAL at 1017:15 at Lo and 5 82. 40 Comply",
                                    "spans": [
                                        {
                                            "length": 53,
                                            "offset": 5715
                                        }
                                    ],
                                    "value": "Transfered & HAL at 1017:15 at Lo and 5 82. 40 Comply",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.0523,
                                                    6.8553
                                                ],
                                                [
                                                    1.6166,
                                                    6.8553
                                                ],
                                                [
                                                    1.6166,
                                                    7.0433
                                                ],
                                                [
                                                    1.0523,
                                                    7.0433
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "10/11/17",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 5773
                                        }
                                    ],
                                    "value": "10/11/17",
                                    "value_type": "string"
                                },
                                "Received from": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.942,
                                                    6.8045
                                                ],
                                                [
                                                    7.59,
                                                    6.8045
                                                ],
                                                [
                                                    7.59,
                                                    7.272
                                                ],
                                                [
                                                    1.942,
                                                    7.272
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Transferedto HAL (BC) For 900 Ans servering at888:00AF Hansand 976: 05 Comp lans.",
                                    "spans": [
                                        {
                                            "length": 85,
                                            "offset": 5782
                                        }
                                    ],
                                    "value": "Transfered to HAL (BC) For 900 Ans servering at 888:00 AF Hans and 976: 05 Comp lans.",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.0523,
                                                    7.2974
                                                ],
                                                [
                                                    1.581,
                                                    7.2974
                                                ],
                                                [
                                                    1.581,
                                                    7.4905
                                                ],
                                                [
                                                    1.0523,
                                                    7.4905
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "16/5/19",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 5868
                                        }
                                    ],
                                    "value": "16/5/19",
                                    "value_type": "string"
                                },
                                "Received from": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.7539,
                                                    7.3025
                                                ],
                                                [
                                                    6.0598,
                                                    7.3025
                                                ],
                                                [
                                                    6.0598,
                                                    7.5007
                                                ],
                                                [
                                                    1.7539,
                                                    7.5007
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Trans fored to151 HU after1500 hos H/K Servicing",
                                    "spans": [
                                        {
                                            "length": 50,
                                            "offset": 5876
                                        }
                                    ],
                                    "value": "Trans fored to 151 HU after 1500 hos H/K Servicing",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "TBO": null,
                    "Canister Pt No.": null,
                    "LOG CARD No.": null,
                    "TTL": null,
                    "SL No": null,
                    "AUTHY": null,
                    "Ref.No": null,
                    "Delete as appropriate": null,
                    "AUTH": null,
                    "Maintenance Unit": null,
                    "No. of overhauls done": null,
                    "Section No": null,
                    "I.A.F. Catalogue No": null,
                    "Contract No.": null
                }
            ]
        ]
    },
    "https://formrecognizer12345.blob.core.windows.net/form-recognizer/formrecognizer12345/test/HAL/20231129-102005%25LC210001.pdf": {
        "message": "success",
        "data": [
            [
                {
                    "LOG CARD No.": "IAF",
                    "Opened at Unit/Station": "BANGALORE",
                    "Opened by": "HALLMRO) HC",
                    "Opened at Date": "05/11/19",
                    "Nomenclature": "FIRE EXTINGUISHING BOTTLE",
                    "Type & MK": "ALH",
                    "Manufacturers Part No": "04.2.0",
                    "Serial No": "B055650",
                    "Date of Receipt in India": "12/12/2017",
                    "Details of Major equipment or installation of which the item forms Part": ": PORTABLE FIRE EXTINGUISHER SYSTEM",
                    "Type of A/C or A/E on which installed": "ALH",
                    "Overhaul agency": "AIR TOTAL, GERMANY",
                    "Authorised overhaul life": "IDYRS FROM THE DOM OR LAST OVERHAUL)",
                    "No. of overhauls done": "-NIL-",
                    "TBO": "10 years)",
                    "Certified that": "FIRE EXTINGUISHER BOTTLE",
                    "Mark": "74-20_No",
                    "No": "B055650",
                    "Maintenance Unit": "00:00",
                    "Modification embodied": "TTL:",
                    "DATE": "0.5-1119",
                    "INSTALLATION RECORD": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E SI No. on which installed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    0.7321,
                                                    7.7801
                                                ],
                                                [
                                                    1.3726,
                                                    7.7801
                                                ],
                                                [
                                                    1.3726,
                                                    7.9834
                                                ],
                                                [
                                                    0.7321,
                                                    7.9834
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "LD-4145",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1540
                                        }
                                    ],
                                    "value": "LD-4145",
                                    "value_type": "string"
                                },
                                "Component Hrs at installation": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    2.1352,
                                                    7.7751
                                                ],
                                                [
                                                    2.5927,
                                                    7.7751
                                                ],
                                                [
                                                    2.5927,
                                                    7.9529
                                                ],
                                                [
                                                    2.1352,
                                                    7.9529
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "00:00",
                                    "spans": [
                                        {
                                            "length": 5,
                                            "offset": 1548
                                        }
                                    ],
                                    "value": "00:00",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.2943,
                                                    7.7954
                                                ],
                                                [
                                                    3.8026,
                                                    7.7954
                                                ],
                                                [
                                                    3.8026,
                                                    7.9682
                                                ],
                                                [
                                                    3.2943,
                                                    7.9682
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "03-12-19",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 1554
                                        }
                                    ],
                                    "value": "03-12-19",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.8941,
                                                    7.7852
                                                ],
                                                [
                                                    4.2907,
                                                    7.7852
                                                ],
                                                [
                                                    4.2907,
                                                    7.9834
                                                ],
                                                [
                                                    3.8941,
                                                    7.9834
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "599:49",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1563
                                        }
                                    ],
                                    "value": "599:49",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.7787,
                                                    7.7598
                                                ],
                                                [
                                                    5.2972,
                                                    7.7598
                                                ],
                                                [
                                                    5.2972,
                                                    7.9936
                                                ],
                                                [
                                                    4.7787,
                                                    7.9936
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Wrong",
                                    "spans": [
                                        {
                                            "length": 5,
                                            "offset": 1570
                                        }
                                    ],
                                    "value": "Wrong",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    5.4193,
                                                    7.7344
                                                ],
                                                [
                                                    6.2428,
                                                    7.7344
                                                ],
                                                [
                                                    6.2428,
                                                    8.0241
                                                ],
                                                [
                                                    5.4193,
                                                    8.0241
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Entrychat",
                                    "spans": [
                                        {
                                            "length": 9,
                                            "offset": 1576
                                        }
                                    ],
                                    "value": "Entrychat",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E SI No. on which installed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    0.7727,
                                                    8.019
                                                ],
                                                [
                                                    1.4031,
                                                    8.019
                                                ],
                                                [
                                                    1.4031,
                                                    8.2172
                                                ],
                                                [
                                                    0.7727,
                                                    8.2172
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "TD-4116",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1586
                                        }
                                    ],
                                    "value": "TD-4116",
                                    "value_type": "string"
                                },
                                "Component Hrs at installation": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    2.1758,
                                                    8.0292
                                                ],
                                                [
                                                    2.669,
                                                    8.0292
                                                ],
                                                [
                                                    2.669,
                                                    8.207
                                                ],
                                                [
                                                    2.1758,
                                                    8.207
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "00: 00",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1594
                                        }
                                    ],
                                    "value": "00: 00",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.3044,
                                                    8.0444
                                                ],
                                                [
                                                    3.8128,
                                                    8.0444
                                                ],
                                                [
                                                    3.8128,
                                                    8.2223
                                                ],
                                                [
                                                    3.3044,
                                                    8.2223
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "13-12-19",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 1601
                                        }
                                    ],
                                    "value": "13-12-19",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.8789,
                                                    8.0546
                                                ],
                                                [
                                                    4.3873,
                                                    8.0546
                                                ],
                                                [
                                                    4.3873,
                                                    8.2121
                                                ],
                                                [
                                                    3.8789,
                                                    8.2121
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1266:00",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1610
                                        }
                                    ],
                                    "value": "1266:00",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    6.0141,
                                                    8.0495
                                                ],
                                                [
                                                    7.1986,
                                                    8.0495
                                                ],
                                                [
                                                    7.1986,
                                                    8.207
                                                ],
                                                [
                                                    6.0141,
                                                    8.207
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "on Just at 115",
                                    "spans": [
                                        {
                                            "length": 14,
                                            "offset": 1618
                                        }
                                    ],
                                    "value": "on Just at 115",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E At removal Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.4635,
                                                    8.3086
                                                ],
                                                [
                                                    4.9261,
                                                    8.3086
                                                ],
                                                [
                                                    4.9261,
                                                    8.5018
                                                ],
                                                [
                                                    4.4635,
                                                    8.5018
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "14/3/23",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1636
                                        }
                                    ],
                                    "value": "14/3/23",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    5.0431,
                                                    8.329
                                                ],
                                                [
                                                    5.5362,
                                                    8.329
                                                ],
                                                [
                                                    5.5362,
                                                    8.5018
                                                ],
                                                [
                                                    5.0431,
                                                    8.5018
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1799.57",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1644
                                        }
                                    ],
                                    "value": "1799.57",
                                    "value_type": "string"
                                },
                                "Component Hours at removal coi. 2": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    5.6582,
                                                    8.3341
                                                ],
                                                [
                                                    6.2072,
                                                    8.3341
                                                ],
                                                [
                                                    6.2072,
                                                    8.4967
                                                ],
                                                [
                                                    5.6582,
                                                    8.4967
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "333.51",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1652
                                        }
                                    ],
                                    "value": "333.51",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    6.2377,
                                                    8.3493
                                                ],
                                                [
                                                    6.9698,
                                                    8.3493
                                                ],
                                                [
                                                    6.9698,
                                                    8.517
                                                ],
                                                [
                                                    6.2377,
                                                    8.517
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "For AFSer.",
                                    "spans": [
                                        {
                                            "length": 10,
                                            "offset": 1659
                                        }
                                    ],
                                    "value": "For AFSer.",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E SI No. on which installed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    0.8846,
                                                    8.5373
                                                ],
                                                [
                                                    1.7793,
                                                    8.5373
                                                ],
                                                [
                                                    1.7793,
                                                    8.7508
                                                ],
                                                [
                                                    0.8846,
                                                    8.7508
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "ZD-4116",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1670
                                        }
                                    ],
                                    "value": "ZD-4116",
                                    "value_type": "string"
                                },
                                "Component Hrs at installation": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    2.3131,
                                                    8.5526
                                                ],
                                                [
                                                    2.9536,
                                                    8.5526
                                                ],
                                                [
                                                    2.9536,
                                                    8.7457
                                                ],
                                                [
                                                    2.3131,
                                                    8.7457
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "533:51",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1678
                                        }
                                    ],
                                    "value": "533:51",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.2993,
                                                    8.5577
                                                ],
                                                [
                                                    3.7925,
                                                    8.5577
                                                ],
                                                [
                                                    3.7925,
                                                    8.7508
                                                ],
                                                [
                                                    3.2993,
                                                    8.7508
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "13/06/23",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 1685
                                        }
                                    ],
                                    "value": "13/06/23",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.823,
                                                    8.5678
                                                ],
                                                [
                                                    4.4127,
                                                    8.5678
                                                ],
                                                [
                                                    4.4127,
                                                    8.7406
                                                ],
                                                [
                                                    3.823,
                                                    8.7406
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1799:51",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1694
                                        }
                                    ],
                                    "value": "1799:51",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.4483,
                                                    8.517
                                                ],
                                                [
                                                    7.0511,
                                                    8.517
                                                ],
                                                [
                                                    7.0511,
                                                    8.7406
                                                ],
                                                [
                                                    4.4483,
                                                    8.7406
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "(On Installation at HAL Bangalore)",
                                    "spans": [
                                        {
                                            "length": 34,
                                            "offset": 1702
                                        }
                                    ],
                                    "value": "(On Installation at HAL Bangalore)",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "PART DISCREPANCY REPORT (PDR)": [],
                    "PART DISCREPANCY REPORT - 2 (PDR-2)": [],
                    "MAJOR UNSERVICEABILITY RECORD": [],
                    "PERIODICAL SERVICING RECORD": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    0.8947,
                                                    1.7176
                                                ],
                                                [
                                                    1.5099,
                                                    1.7176
                                                ],
                                                [
                                                    1.5099,
                                                    1.926
                                                ],
                                                [
                                                    0.8947,
                                                    1.926
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "19/05/23",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 3604
                                        }
                                    ],
                                    "value": "19/05/23",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    1.5861,
                                                    1.7176
                                                ],
                                                [
                                                    2.3487,
                                                    1.7176
                                                ],
                                                [
                                                    2.3487,
                                                    1.9158
                                                ],
                                                [
                                                    1.5861,
                                                    1.9158
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "wt-Check",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 3613
                                        }
                                    ],
                                    "value": "wt-Check",
                                    "value_type": "string"
                                },
                                "Details of work done and components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    2.5317,
                                                    1.7227
                                                ],
                                                [
                                                    5.1854,
                                                    1.7227
                                                ],
                                                [
                                                    5.1854,
                                                    1.9971
                                                ],
                                                [
                                                    2.5317,
                                                    1.9971
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Weighing c/o- wt. 2.06kg).",
                                    "spans": [
                                        {
                                            "length": 26,
                                            "offset": 3622
                                        }
                                    ],
                                    "value": "Weighing c/o- wt. 2.06kg).",
                                    "value_type": "string"
                                },
                                "date declared serviceable or unserviceable": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    5.6328,
                                                    1.7481
                                                ],
                                                [
                                                    6.6343,
                                                    1.7481
                                                ],
                                                [
                                                    6.6343,
                                                    1.987
                                                ],
                                                [
                                                    5.6328,
                                                    1.987
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "S 19/05/23",
                                    "spans": [
                                        {
                                            "length": 10,
                                            "offset": 3649
                                        }
                                    ],
                                    "value": "S 19/05/23",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "TRANSFER RECORD": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    0.8439,
                                                    6.281
                                                ],
                                                [
                                                    1.3726,
                                                    6.281
                                                ],
                                                [
                                                    1.3726,
                                                    6.4741
                                                ],
                                                [
                                                    0.8439,
                                                    6.4741
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "24/02/23",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 4041
                                        }
                                    ],
                                    "value": "24/02/23",
                                    "value_type": "string"
                                },
                                "Received from": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    1.52,
                                                    6.2861
                                                ],
                                                [
                                                    6.6495,
                                                    6.2861
                                                ],
                                                [
                                                    6.6495,
                                                    6.7638
                                                ],
                                                [
                                                    1.52,
                                                    6.7638
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Transferred to HAL for 1800 hrs servicing at 1799 5T AFhrs and $33:51 comp hrs",
                                    "spans": [
                                        {
                                            "length": 55,
                                            "offset": 4050
                                        },
                                        {
                                            "length": 23,
                                            "offset": 4108
                                        }
                                    ],
                                    "value": "Transferred to HAL for 1800 hrs servicing at 1799 5T AF hrs and $33:51 comp hrs",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "I.A.F. Catalogue No": null,
                    "Contract No.": null,
                    "Delete as appropriate": null,
                    "Canister Pt No.": null,
                    "AUTH": null,
                    "SL No": null,
                    "AUTHY": null,
                    "Date of Manufacture": null,
                    "Section No": null,
                    "Job No.": null,
                    "Ref.No": null,
                    "TTL": null
                }
            ]
        ]
    },
    "https://formrecognizer12345.blob.core.windows.net/form-recognizer/formrecognizer12345/test/HAL/20231129-102005%25LC100001.pdf": {
        "message": "success",
        "data": [
            [
                {
                    "Nomenclature": "YAW FRICTION TRIM ACTUATORSL.",
                    "SL No": "LO10",
                    "Manufacturers Part No": "TYF 16602-02",
                    "Date of Manufacture": "16.04. 2010",
                    "Type of A/C or A/E on which installed": "ACH",
                    "Overhaul agency": "HAL CADL)",
                    "Certified that": "YAW FRICTION TRIM ACTUATOR",
                    "Mark": "TYF 16602-02",
                    "No": "2010",
                    "Maintenance Unit": "0000",
                    "DATE": "28.6.2010",
                    "INSTALLATION RECORD": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E SI No. on which installed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    0.1576,
                                                    6.4487
                                                ],
                                                [
                                                    1.9166,
                                                    6.4487
                                                ],
                                                [
                                                    1.9166,
                                                    6.7181
                                                ],
                                                [
                                                    0.1576,
                                                    6.7181
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "ZD-4118 DS-118",
                                    "spans": [
                                        {
                                            "length": 14,
                                            "offset": 747
                                        }
                                    ],
                                    "value": "ZD-4118 DS-118",
                                    "value_type": "string"
                                },
                                "Component Hrs at installation": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    2.5571,
                                                    6.53
                                                ],
                                                [
                                                    3.1417,
                                                    6.53
                                                ],
                                                [
                                                    3.1417,
                                                    6.7079
                                                ],
                                                [
                                                    2.5571,
                                                    6.7079
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "00:00",
                                    "spans": [
                                        {
                                            "length": 5,
                                            "offset": 762
                                        }
                                    ],
                                    "value": "00:00",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.5739,
                                                    6.4589
                                                ],
                                                [
                                                    4.006,
                                                    6.4589
                                                ],
                                                [
                                                    4.006,
                                                    6.6063
                                                ],
                                                [
                                                    3.5739,
                                                    6.6063
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "24.01.11",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 785
                                        }
                                    ],
                                    "value": "24.01.11",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.1229,
                                                    6.4894
                                                ],
                                                [
                                                    4.5652,
                                                    6.4894
                                                ],
                                                [
                                                    4.5652,
                                                    6.6317
                                                ],
                                                [
                                                    4.1229,
                                                    6.6317
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "00:00",
                                    "spans": [
                                        {
                                            "length": 5,
                                            "offset": 794
                                        }
                                    ],
                                    "value": "00:00",
                                    "value_type": "string"
                                },
                                "Component Hours at removal coi. 2": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    5.6785,
                                                    6.4487
                                                ],
                                                [
                                                    6.558,
                                                    6.4487
                                                ],
                                                [
                                                    6.558,
                                                    6.8756
                                                ],
                                                [
                                                    5.6785,
                                                    6.8756
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Inst at HALCH/0)",
                                    "spans": [
                                        {
                                            "length": 16,
                                            "offset": 800
                                        }
                                    ],
                                    "value": "Inst at HALCH/0)",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E At removal Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.5703,
                                                    6.901
                                                ],
                                                [
                                                    6.2835,
                                                    6.901
                                                ],
                                                [
                                                    6.2835,
                                                    7.1907
                                                ],
                                                [
                                                    4.5703,
                                                    7.1907
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "23/2/23|1791.541|1791.54",
                                    "spans": [
                                        {
                                            "length": 24,
                                            "offset": 817
                                        }
                                    ],
                                    "value": "23/2/23|1791.541|1791.54",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E SI No. on which installed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    0.3508,
                                                    7.0839
                                                ],
                                                [
                                                    1.2099,
                                                    7.0839
                                                ],
                                                [
                                                    1.2099,
                                                    7.2974
                                                ],
                                                [
                                                    0.3508,
                                                    7.2974
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "20- 4116",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 768
                                        }
                                    ],
                                    "value": "20- 4116",
                                    "value_type": "string"
                                },
                                "Component Hrs at installation": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    2.5215,
                                                    7.1246
                                                ],
                                                [
                                                    3.279,
                                                    7.1246
                                                ],
                                                [
                                                    3.279,
                                                    7.3279
                                                ],
                                                [
                                                    2.5215,
                                                    7.3279
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1791:54",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 777
                                        }
                                    ],
                                    "value": "1791:54",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.5484,
                                                    7.1195
                                                ],
                                                [
                                                    3.9856,
                                                    7.1195
                                                ],
                                                [
                                                    3.9856,
                                                    7.3939
                                                ],
                                                [
                                                    3.5484,
                                                    7.3939
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "24/6/23",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 846
                                        }
                                    ],
                                    "value": "24/6/23",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.0212,
                                                    7.1602
                                                ],
                                                [
                                                    4.4839,
                                                    7.1602
                                                ],
                                                [
                                                    4.4839,
                                                    7.4143
                                                ],
                                                [
                                                    4.0212,
                                                    7.4143
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1799:50",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 854
                                        }
                                    ],
                                    "value": "1799:50",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.5245,
                                                    7.1957
                                                ],
                                                [
                                                    6.7207,
                                                    7.1957
                                                ],
                                                [
                                                    6.7207,
                                                    7.4244
                                                ],
                                                [
                                                    4.5245,
                                                    7.4244
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "On Installation at HAL Bara",
                                    "spans": [
                                        {
                                            "length": 27,
                                            "offset": 862
                                        }
                                    ],
                                    "value": "On Installation at HAL Bara",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "PART DISCREPANCY REPORT (PDR)": [],
                    "PART DISCREPANCY REPORT - 2 (PDR-2)": [],
                    "MAJOR UNSERVICEABILITY RECORD": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date placed U/S": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    0.6558,
                                                    3.481
                                                ],
                                                [
                                                    1.82,
                                                    3.481
                                                ],
                                                [
                                                    1.82,
                                                    3.6893
                                                ],
                                                [
                                                    0.6558,
                                                    3.6893
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "23/02/2023",
                                    "spans": [
                                        {
                                            "length": 10,
                                            "offset": 1875
                                        }
                                    ],
                                    "value": "23/02/2023",
                                    "value_type": "string"
                                },
                                "Reason for unserviceability (U/S)": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    2.1199,
                                                    3.4657
                                                ],
                                                [
                                                    3.1214,
                                                    3.4657
                                                ],
                                                [
                                                    3.1214,
                                                    4.4872
                                                ],
                                                [
                                                    2.1199,
                                                    4.4872
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "PI Check Failed (Angular gap 3/5 the secondary Voltage, Damping Motor test, Pre-load.",
                                    "spans": [
                                        {
                                            "length": 85,
                                            "offset": 1886
                                        }
                                    ],
                                    "value": "PI Check Failed (Angular gap 3/5 the secondary Voltage, Damping Motor test, Pre-load.",
                                    "value_type": "string"
                                },
                                "Hours done since last overhaul or since new": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    3.3095,
                                                    3.5725
                                                ],
                                                [
                                                    4.4483,
                                                    3.5725
                                                ],
                                                [
                                                    4.4483,
                                                    3.979
                                                ],
                                                [
                                                    3.3095,
                                                    3.979
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1791: 54 HRSSINCE NEW",
                                    "spans": [
                                        {
                                            "length": 12,
                                            "offset": 1972
                                        },
                                        {
                                            "length": 9,
                                            "offset": 2000
                                        }
                                    ],
                                    "value": "1791: 54 HRS SINCE NEW",
                                    "value_type": "string"
                                },
                                "Work done to rectify the fault & Components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    4.4991,
                                                    3.5471
                                                ],
                                                [
                                                    5.709,
                                                    3.5471
                                                ],
                                                [
                                                    5.709,
                                                    4.2788
                                                ],
                                                [
                                                    4.4991,
                                                    4.2788
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Unit Repaired,FT & FI found satisfactory",
                                    "spans": [
                                        {
                                            "length": 14,
                                            "offset": 1985
                                        },
                                        {
                                            "length": 26,
                                            "offset": 2035
                                        }
                                    ],
                                    "value": "Unit Repaired, FT & FI found satisfactory",
                                    "value_type": "string"
                                },
                                "Date placed 'S'": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    5.948,
                                                    3.6741
                                                ],
                                                [
                                                    6.924,
                                                    3.6741
                                                ],
                                                [
                                                    6.924,
                                                    3.9129
                                                ],
                                                [
                                                    5.948,
                                                    3.9129
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "05/06/2023",
                                    "spans": [
                                        {
                                            "length": 10,
                                            "offset": 2096
                                        }
                                    ],
                                    "value": "05/06/2023",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "PERIODICAL SERVICING RECORD": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    0.5948,
                                                    1.555
                                                ],
                                                [
                                                    1.2506,
                                                    1.555
                                                ],
                                                [
                                                    1.2506,
                                                    1.7481
                                                ],
                                                [
                                                    0.5948,
                                                    1.7481
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "09.10.10",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 2204
                                        }
                                    ],
                                    "value": "09.10.10",
                                    "value_type": "string"
                                },
                                "Details of work done and components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    2.4402,
                                                    1.3975
                                                ],
                                                [
                                                    4.1992,
                                                    1.3975
                                                ],
                                                [
                                                    4.1992,
                                                    2.1648
                                                ],
                                                [
                                                    2.4402,
                                                    2.1648
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "PI check carried out & found satisfactoryas per tag endorsement",
                                    "spans": [
                                        {
                                            "length": 64,
                                            "offset": 2275
                                        }
                                    ],
                                    "value": "PI check carried out & found satisfactory as per tag endorsement",
                                    "value_type": "string"
                                },
                                "date declared serviceable or unserviceable": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    4.5347,
                                                    1.6109
                                                ],
                                                [
                                                    5.3074,
                                                    1.6109
                                                ],
                                                [
                                                    5.3074,
                                                    1.804
                                                ],
                                                [
                                                    4.5347,
                                                    1.804
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "0910.10",
                                    "spans": [
                                        {
                                            "length": 2,
                                            "offset": 2364
                                        },
                                        {
                                            "length": 5,
                                            "offset": 2369
                                        }
                                    ],
                                    "value": "09 10.10",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "TRANSFER RECORD": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    0.4372,
                                                    5.8847
                                                ],
                                                [
                                                    1.1896,
                                                    5.8847
                                                ],
                                                [
                                                    1.1896,
                                                    6.0727
                                                ],
                                                [
                                                    0.4372,
                                                    6.0727
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "09 Feb23",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 2556
                                        }
                                    ],
                                    "value": "09 Feb23",
                                    "value_type": "string"
                                },
                                "Received from": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.3065,
                                                    5.8338
                                                ],
                                                [
                                                    6.5021,
                                                    5.8338
                                                ],
                                                [
                                                    6.5021,
                                                    6.3166
                                                ],
                                                [
                                                    1.3065,
                                                    6.3166
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Item transferred to HAL for 1800 hrs servicingat 1791.54 AF hry and 1791:54 component hrs-",
                                    "spans": [
                                        {
                                            "length": 91,
                                            "offset": 2565
                                        }
                                    ],
                                    "value": "Item transferred to HAL for 1800 hrs servicing at 1791.54 AF hry and 1791:54 component hrs-",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "Canister Pt No.": null,
                    "Contract No.": null,
                    "Job No.": null,
                    "Serial No": null,
                    "LOG CARD No.": null,
                    "TBO": null,
                    "TTL": null,
                    "Delete as appropriate": null,
                    "Ref.No": null,
                    "AUTH": null,
                    "Modification embodied": null,
                    "Date of Receipt in India": null,
                    "Section No": null,
                    "AUTHY": null,
                    "Opened at Unit/Station": null,
                    "Details of Major equipment or installation of which the item forms Part": null,
                    "Opened by": null,
                    "Opened at Date": null,
                    "I.A.F. Catalogue No": null,
                    "Type & MK": null,
                    "Authorised overhaul life": null,
                    "No. of overhauls done": null
                }
            ]
        ]
    },
    "https://formrecognizer12345.blob.core.windows.net/form-recognizer/formrecognizer12345/test/HAL/20231129-102005%25LC170001.pdf": {
        "message": "success",
        "data": [
            [
                {
                    "Opened at Unit/Station": "BANGALORE",
                    "Opened by": "HAL. HeMRO DIV",
                    "Opened at Date": "19.01.2013.",
                    "Nomenclature": "CANISTER WITH ARV.",
                    "Type & MK": "ACH",
                    "Manufacturers Part No": "C49 AA 0240",
                    "Date of Manufacture": "03-2005",
                    "Serial No": "389",
                    "Details of Major equipment or installation of which the item forms Part": "FUEL SYSTEM.",
                    "Type of A/C or A/E on which installed": "ACH.",
                    "Overhaul agency": "m/S SOFEMA FRANCE.",
                    "TTL": "6000 Hrs",
                    "TBO": "10 Yrs from DOI or 1250 Hrs",
                    "AUTH": "Dte EME (Avn) letter No B/96314/ALH/LC/EME Avn-VI/53 dt 31 Dec 2015.",
                    "Canister Pt No.": "C49AA0240.",
                    "Certified that": "CANISTER WITH NRU.",
                    "Mark": "C49 AA 0240",
                    "No": "389",
                    "Maintenance Unit": "431:47 hours.",
                    "Modification embodied": "TBO: ON CONDITION. DOI 05:07.07 TBO ... 10 yrs from DOI",
                    "DATE": "u0967 / ......?.......",
                    "Delete as appropriate": "log card raised during 500 HP Servicing based on the original og card as Per 8th Lem Rof No RemA(H)C) {162211 at 02/03/12",
                    "INSTALLATION RECORD": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E SI No. on which installed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    0.8032,
                                                    7.7395
                                                ],
                                                [
                                                    1.5251,
                                                    7.7395
                                                ],
                                                [
                                                    1.5251,
                                                    7.9326
                                                ],
                                                [
                                                    0.8032,
                                                    7.9326
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "IA 1129",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1680
                                        }
                                    ],
                                    "value": "IA 1129",
                                    "value_type": "string"
                                },
                                "Component Hrs at installation": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    2.0487,
                                                    7.7344
                                                ],
                                                [
                                                    2.7503,
                                                    7.7344
                                                ],
                                                [
                                                    2.7503,
                                                    7.9326
                                                ],
                                                [
                                                    2.0487,
                                                    7.9326
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "431:47",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1688
                                        }
                                    ],
                                    "value": "431:47",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.1824,
                                                    7.7446
                                                ],
                                                [
                                                    3.701,
                                                    7.7446
                                                ],
                                                [
                                                    3.701,
                                                    7.9326
                                                ],
                                                [
                                                    3.1824,
                                                    7.9326
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "19.01.13",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 1695
                                        }
                                    ],
                                    "value": "19.01.13",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.762,
                                                    7.7497
                                                ],
                                                [
                                                    4.5194,
                                                    7.7497
                                                ],
                                                [
                                                    4.5194,
                                                    7.9174
                                                ],
                                                [
                                                    3.762,
                                                    7.9174
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "484.24On",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 1704
                                        }
                                    ],
                                    "value": "484.24On",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.5499,
                                                    7.709
                                                ],
                                                [
                                                    6.8732,
                                                    7.709
                                                ],
                                                [
                                                    6.8732,
                                                    7.9174
                                                ],
                                                [
                                                    4.5499,
                                                    7.9174
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Installation at HAL Bangalore)",
                                    "spans": [
                                        {
                                            "length": 30,
                                            "offset": 1713
                                        }
                                    ],
                                    "value": "Installation at HAL Bangalore)",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E SI No. on which installed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    0.7219,
                                                    8.0342
                                                ],
                                                [
                                                    1.52,
                                                    8.0342
                                                ],
                                                [
                                                    1.52,
                                                    8.1867
                                                ],
                                                [
                                                    0.7219,
                                                    8.1867
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1A - 1129",
                                    "spans": [
                                        {
                                            "length": 9,
                                            "offset": 1744
                                        }
                                    ],
                                    "value": "1A - 1129",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.3415,
                                                    8.0088
                                                ],
                                                [
                                                    4.86,
                                                    8.0088
                                                ],
                                                [
                                                    4.86,
                                                    8.1867
                                                ],
                                                [
                                                    4.3415,
                                                    8.1867
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "4-7- 2017",
                                    "spans": [
                                        {
                                            "length": 9,
                                            "offset": 1754
                                        }
                                    ],
                                    "value": "4-7- 2017",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.8906,
                                                    8.0088
                                                ],
                                                [
                                                    5.4142,
                                                    8.0088
                                                ],
                                                [
                                                    5.4142,
                                                    8.1918
                                                ],
                                                [
                                                    4.8906,
                                                    8.1918
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1175:14",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1764
                                        }
                                    ],
                                    "value": "1175:14",
                                    "value_type": "string"
                                },
                                "Component Hours at removal coi. 2": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    5.5819,
                                                    7.9275
                                                ],
                                                [
                                                    6.7969,
                                                    7.9275
                                                ],
                                                [
                                                    6.7969,
                                                    8.1816
                                                ],
                                                [
                                                    5.5819,
                                                    8.1816
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1122.27of callitecompletion",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1772
                                        },
                                        {
                                            "length": 10,
                                            "offset": 1782
                                        },
                                        {
                                            "length": 10,
                                            "offset": 1795
                                        }
                                    ],
                                    "value": "completion 1122.27 of callite",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E SI No. on which installed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    0.8083,
                                                    8.2477
                                                ],
                                                [
                                                    1.4946,
                                                    8.2477
                                                ],
                                                [
                                                    1.4946,
                                                    8.4103
                                                ],
                                                [
                                                    0.8083,
                                                    8.4103
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "ZD. 411 6",
                                    "spans": [
                                        {
                                            "length": 9,
                                            "offset": 1809
                                        }
                                    ],
                                    "value": "ZD. 411 6",
                                    "value_type": "string"
                                },
                                "Component Hrs at installation": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    2.1097,
                                                    8.2629
                                                ],
                                                [
                                                    2.7401,
                                                    8.2629
                                                ],
                                                [
                                                    2.7401,
                                                    8.4204
                                                ],
                                                [
                                                    2.1097,
                                                    8.4204
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "00:00",
                                    "spans": [
                                        {
                                            "length": 5,
                                            "offset": 1819
                                        }
                                    ],
                                    "value": "00:00",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.1722,
                                                    8.2121
                                                ],
                                                [
                                                    3.6705,
                                                    8.2121
                                                ],
                                                [
                                                    3.6705,
                                                    8.395
                                                ],
                                                [
                                                    3.1722,
                                                    8.395
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "21.7.19",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1825
                                        }
                                    ],
                                    "value": "21.7.19",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.7315,
                                                    8.2172
                                                ],
                                                [
                                                    4.2602,
                                                    8.2172
                                                ],
                                                [
                                                    4.2602,
                                                    8.3849
                                                ],
                                                [
                                                    3.7315,
                                                    8.3849
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1248:59",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1833
                                        }
                                    ],
                                    "value": "1248:59",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.555,
                                                    8.2985
                                                ],
                                                [
                                                    4.6008,
                                                    8.2985
                                                ],
                                                [
                                                    4.6008,
                                                    8.3747
                                                ],
                                                [
                                                    4.555,
                                                    8.3747
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "-",
                                    "spans": [
                                        {
                                            "length": 1,
                                            "offset": 1841
                                        }
                                    ],
                                    "value": "-",
                                    "value_type": "string"
                                },
                                "Component Hours at removal coi. 2": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    5.5108,
                                                    8.1968
                                                ],
                                                [
                                                    6.9291,
                                                    8.1968
                                                ],
                                                [
                                                    6.9291,
                                                    8.39
                                                ],
                                                [
                                                    5.5108,
                                                    8.39
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "on Iolaltabral 11571V",
                                    "spans": [
                                        {
                                            "length": 21,
                                            "offset": 1843
                                        }
                                    ],
                                    "value": "on Iolaltabral 11571V",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E At removal Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.3771,
                                                    8.4814
                                                ],
                                                [
                                                    4.8448,
                                                    8.4814
                                                ],
                                                [
                                                    4.8448,
                                                    8.639
                                                ],
                                                [
                                                    4.3771,
                                                    8.639
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "14/3/23",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1865
                                        }
                                    ],
                                    "value": "14/3/23",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.8855,
                                                    8.4814
                                                ],
                                                [
                                                    5.404,
                                                    8.4814
                                                ],
                                                [
                                                    5.404,
                                                    8.6339
                                                ],
                                                [
                                                    4.8855,
                                                    8.6339
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1799.57",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1873
                                        }
                                    ],
                                    "value": "1799.57",
                                    "value_type": "string"
                                },
                                "Component Hours at removal coi. 2": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    5.5667,
                                                    8.456
                                                ],
                                                [
                                                    6.8325,
                                                    8.456
                                                ],
                                                [
                                                    6.8325,
                                                    8.6237
                                                ],
                                                [
                                                    5.5667,
                                                    8.6237
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "550.52 far AS-Sv.",
                                    "spans": [
                                        {
                                            "length": 17,
                                            "offset": 1881
                                        }
                                    ],
                                    "value": "550.52 far AS-Sv.",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "PART DISCREPANCY REPORT (PDR)": [],
                    "PART DISCREPANCY REPORT - 2 (PDR-2)": [],
                    "MAJOR UNSERVICEABILITY RECORD": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date placed U/S": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    0.8947,
                                                    2.0073
                                                ],
                                                [
                                                    1.6675,
                                                    2.0073
                                                ],
                                                [
                                                    1.6675,
                                                    2.1851
                                                ],
                                                [
                                                    0.8947,
                                                    2.1851
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "05/07/17",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 3979
                                        }
                                    ],
                                    "value": "05/07/17",
                                    "value_type": "string"
                                },
                                "Reason for unserviceability (U/S)": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    1.8098,
                                                    1.9971
                                                ],
                                                [
                                                    2.974,
                                                    1.9971
                                                ],
                                                [
                                                    2.974,
                                                    2.4342
                                                ],
                                                [
                                                    1.8098,
                                                    2.4342
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Calender lifedue",
                                    "spans": [
                                        {
                                            "length": 13,
                                            "offset": 3988
                                        },
                                        {
                                            "length": 3,
                                            "offset": 4046
                                        }
                                    ],
                                    "value": "Calender life due",
                                    "value_type": "string"
                                },
                                "Hours done since last overhaul or since new": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    3.1062,
                                                    2.0175
                                                ],
                                                [
                                                    4.0314,
                                                    2.0175
                                                ],
                                                [
                                                    4.0314,
                                                    2.1902
                                                ],
                                                [
                                                    3.1062,
                                                    2.1902
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1122:27",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 4002
                                        }
                                    ],
                                    "value": "1122:27",
                                    "value_type": "string"
                                },
                                "Work done to rectify the fault & Components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    4.1839,
                                                    2.0073
                                                ],
                                                [
                                                    5.8717,
                                                    2.0073
                                                ],
                                                [
                                                    5.8717,
                                                    3.3082
                                                ],
                                                [
                                                    4.1839,
                                                    3.3082
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Overhaul CarvicolentosPor ReMA procedure.ATP Carriedout & joundSatisfactory Comp.hrsreset to zero. NextTRO is loyrs fromDot",
                                    "spans": [
                                        {
                                            "length": 22,
                                            "offset": 4010
                                        },
                                        {
                                            "length": 19,
                                            "offset": 4050
                                        },
                                        {
                                            "length": 22,
                                            "offset": 4092
                                        },
                                        {
                                            "length": 21,
                                            "offset": 4124
                                        },
                                        {
                                            "length": 19,
                                            "offset": 4161
                                        },
                                        {
                                            "length": 20,
                                            "offset": 4189
                                        }
                                    ],
                                    "value": "Overhaul Carvicolentos Por ReMA procedure. ATP Carriedout & jound Satisfactory Comp.hrs reset to zero. Next TRO is loyrs fromDot",
                                    "value_type": "string"
                                },
                                "Date placed 'S'": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    5.9124,
                                                    1.987
                                                ],
                                                [
                                                    6.6953,
                                                    1.987
                                                ],
                                                [
                                                    6.6953,
                                                    2.4189
                                                ],
                                                [
                                                    5.9124,
                                                    2.4189
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "'S\"20/08 18",
                                    "spans": [
                                        {
                                            "length": 3,
                                            "offset": 4035
                                        },
                                        {
                                            "length": 8,
                                            "offset": 4070
                                        }
                                    ],
                                    "value": "'S\" 20/08 18",
                                    "value_type": "string"
                                },
                                "Signature of N.C.O.": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    6.7664,
                                                    2.053
                                                ],
                                                [
                                                    7.8645,
                                                    2.053
                                                ],
                                                [
                                                    7.8645,
                                                    3.3184
                                                ],
                                                [
                                                    6.7664,
                                                    3.3184
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "ORDADAcleared videMemo no:KRU ME CRI/ROH-/1409/date: 20/08/18",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 4039
                                        },
                                        {
                                            "length": 12,
                                            "offset": 4079
                                        },
                                        {
                                            "length": 8,
                                            "offset": 4115
                                        },
                                        {
                                            "length": 14,
                                            "offset": 4146
                                        },
                                        {
                                            "length": 7,
                                            "offset": 4181
                                        },
                                        {
                                            "length": 14,
                                            "offset": 4224
                                        }
                                    ],
                                    "value": "ORDADA cleared vide Memo no: KRU ME CRI/ROH -/1409/ date: 20/08/18",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "PERIODICAL SERVICING RECORD": [],
                    "TRANSFER RECORD": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    0.8642,
                                                    6.2404
                                                ],
                                                [
                                                    1.3472,
                                                    6.2404
                                                ],
                                                [
                                                    1.3472,
                                                    6.3877
                                                ],
                                                [
                                                    0.8642,
                                                    6.3877
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "22/1/13",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 4927
                                        }
                                    ],
                                    "value": "22/1/13",
                                    "value_type": "string"
                                },
                                "Despatched to": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    2.0792,
                                                    6.1896
                                                ],
                                                [
                                                    5.6684,
                                                    6.1896
                                                ],
                                                [
                                                    5.6684,
                                                    6.403
                                                ],
                                                [
                                                    2.0792,
                                                    6.403
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Transfered to 205 AAafter 500 Hrs.Servicing",
                                    "spans": [
                                        {
                                            "length": 45,
                                            "offset": 4935
                                        }
                                    ],
                                    "value": "Transfered to 205 AA after 500 Hrs. Servicing",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    0.8439,
                                                    6.5046
                                                ],
                                                [
                                                    1.3472,
                                                    6.5046
                                                ],
                                                [
                                                    1.3472,
                                                    6.6266
                                                ],
                                                [
                                                    0.8439,
                                                    6.6266
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "05/07/17",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 4991
                                        }
                                    ],
                                    "value": "05/07/17",
                                    "value_type": "string"
                                },
                                "Despatched to": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    2.1453,
                                                    6.4995
                                                ],
                                                [
                                                    4.3008,
                                                    6.4995
                                                ],
                                                [
                                                    4.3008,
                                                    6.6622
                                                ],
                                                [
                                                    2.1453,
                                                    6.6622
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Transfered to reper Agency",
                                    "spans": [
                                        {
                                            "length": 26,
                                            "offset": 5000
                                        }
                                    ],
                                    "value": "Transfered to reper Agency",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    0.8134,
                                                    6.6977
                                                ],
                                                [
                                                    1.3726,
                                                    6.6977
                                                ],
                                                [
                                                    1.3726,
                                                    6.8451
                                                ],
                                                [
                                                    0.8134,
                                                    6.8451
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "28/09/23",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 5032
                                        }
                                    ],
                                    "value": "28/09/23",
                                    "value_type": "string"
                                },
                                "Despatched to": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    2.1199,
                                                    6.6672
                                                ],
                                                [
                                                    6.131,
                                                    6.6672
                                                ],
                                                [
                                                    6.131,
                                                    7.0992
                                                ],
                                                [
                                                    2.1199,
                                                    7.0992
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Transferred to HALfor 1800 hrsand1799: 51 Af Is and350:52 comp hrs",
                                    "spans": [
                                        {
                                            "length": 70,
                                            "offset": 5041
                                        }
                                    ],
                                    "value": "Transferred to HAL for 1800 hrs and 1799: 51 Af Is and 350:52 comp hrs",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "Authorised overhaul life": null,
                    "I.A.F. Catalogue No": null,
                    "Contract No.": null,
                    "SL No": null,
                    "AUTHY": null,
                    "LOG CARD No.": null,
                    "Date of Receipt in India": null,
                    "No. of overhauls done": null,
                    "Section No": null,
                    "Job No.": null,
                    "Ref.No": null
                }
            ]
        ]
    },
    "https://formrecognizer12345.blob.core.windows.net/form-recognizer/formrecognizer12345/test/HAL/20231129-102006%25LC110001.pdf": {
        "message": "success",
        "data": [
            [
                {
                    "Opened at Unit/Station": "BANGALORE",
                    "Opened by": "HAL (BC)",
                    "Nomenclature": "BLEED AIR FILTER",
                    "Manufacturers Part No": "D-60364",
                    "Date of Manufacture": "NA",
                    "Serial No": "RK S-3921",
                    "Details of Major equipment or installation of which the item forms Part": "HEATING AND VENTILATION SYSTEM ICE DETECTION SYSTEM.",
                    "Type of A/C or A/E on which installed": "ALH",
                    "Overhaul agency": "M/S, HYMATI ENGG ENGLAND",
                    "Authorised overhaul life": "00",
                    "No. of overhauls done": "NIL",
                    "Certified that": "BLEED AIR FILTER",
                    "Mark": "A 60364",
                    "DATE": ".u0967u0968u0966 u0968u0966u0966/",
                    "INSTALLATION RECORD": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E SI No. on which installed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    0.8642,
                                                    7.8513
                                                ],
                                                [
                                                    1.5912,
                                                    7.8513
                                                ],
                                                [
                                                    1.5912,
                                                    8.0088
                                                ],
                                                [
                                                    0.8642,
                                                    8.0088
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "IA- 1101",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 1511
                                        }
                                    ],
                                    "value": "IA- 1101",
                                    "value_type": "string"
                                },
                                "Component Hrs at installation": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    2.4046,
                                                    7.8513
                                                ],
                                                [
                                                    2.9079,
                                                    7.8513
                                                ],
                                                [
                                                    2.9079,
                                                    7.9885
                                                ],
                                                [
                                                    2.4046,
                                                    7.9885
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "00:00",
                                    "spans": [
                                        {
                                            "length": 5,
                                            "offset": 1520
                                        }
                                    ],
                                    "value": "00:00",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.401,
                                                    7.7852
                                                ],
                                                [
                                                    3.9043,
                                                    7.7852
                                                ],
                                                [
                                                    3.9043,
                                                    7.9733
                                                ],
                                                [
                                                    3.401,
                                                    7.9733
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "19/01/01",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 1526
                                        }
                                    ],
                                    "value": "19/01/01",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E SI No. on which installed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    0.9456,
                                                    8.3645
                                                ],
                                                [
                                                    1.6929,
                                                    8.3645
                                                ],
                                                [
                                                    1.6929,
                                                    8.517
                                                ],
                                                [
                                                    0.9456,
                                                    8.517
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "IA -1101",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 1576
                                        }
                                    ],
                                    "value": "IA -1101",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.5449,
                                                    8.268
                                                ],
                                                [
                                                    5.0176,
                                                    8.268
                                                ],
                                                [
                                                    5.0176,
                                                    8.4662
                                                ],
                                                [
                                                    4.5449,
                                                    8.4662
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "28/03/21",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 1587
                                        }
                                    ],
                                    "value": "28/03/21",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    5.1295,
                                                    8.3188
                                                ],
                                                [
                                                    5.6175,
                                                    8.3188
                                                ],
                                                [
                                                    5.6175,
                                                    8.4967
                                                ],
                                                [
                                                    5.1295,
                                                    8.4967
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "2150,00",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1596
                                        }
                                    ],
                                    "value": "2150,00",
                                    "value_type": "string"
                                },
                                "Component Hours at removal coi. 2": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    5.9175,
                                                    8.329
                                                ],
                                                [
                                                    6.4563,
                                                    8.329
                                                ],
                                                [
                                                    6.4563,
                                                    8.4662
                                                ],
                                                [
                                                    5.9175,
                                                    8.4662
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "2150:00",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1619
                                        }
                                    ],
                                    "value": "2150:00",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    6.7969,
                                                    8.268
                                                ],
                                                [
                                                    7.6307,
                                                    8.268
                                                ],
                                                [
                                                    7.6307,
                                                    8.4001
                                                ],
                                                [
                                                    6.7969,
                                                    8.4001
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "For 20 yes ITL",
                                    "spans": [
                                        {
                                            "length": 14,
                                            "offset": 1604
                                        }
                                    ],
                                    "value": "For 20 yes ITL",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "PART DISCREPANCY REPORT (PDR)": [],
                    "PART DISCREPANCY REPORT - 2 (PDR-2)": [],
                    "MAJOR UNSERVICEABILITY RECORD": [],
                    "PERIODICAL SERVICING RECORD": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.4641,
                                                    1.7939
                                                ],
                                                [
                                                    2.2165,
                                                    1.7939
                                                ],
                                                [
                                                    2.2165,
                                                    2.0886
                                                ],
                                                [
                                                    1.4641,
                                                    2.0886
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "20 Years 14/2Servicing",
                                    "spans": [
                                        {
                                            "length": 13,
                                            "offset": 3103
                                        },
                                        {
                                            "length": 9,
                                            "offset": 3332
                                        }
                                    ],
                                    "value": "20 Years 14/2 Servicing",
                                    "value_type": "string"
                                },
                                "Details of work done and components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    2.5419,
                                                    1.8548
                                                ],
                                                [
                                                    4.8804,
                                                    1.8548
                                                ],
                                                [
                                                    4.8804,
                                                    2.053
                                                ],
                                                [
                                                    2.5419,
                                                    2.053
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Applicable checks c/o & Found sat",
                                    "spans": [
                                        {
                                            "length": 33,
                                            "offset": 3342
                                        }
                                    ],
                                    "value": "Applicable checks c/o & Found sat",
                                    "value_type": "string"
                                },
                                "date declared serviceable or unserviceable": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    5.6124,
                                                    2.0886
                                                ],
                                                [
                                                    6.1005,
                                                    2.0886
                                                ],
                                                [
                                                    6.1005,
                                                    2.2715
                                                ],
                                                [
                                                    5.6124,
                                                    2.2715
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "21/11/21",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 3376
                                        }
                                    ],
                                    "value": "21/11/21",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "TRANSFER RECORD": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    0.8286,
                                                    6.4741
                                                ],
                                                [
                                                    1.3777,
                                                    6.4741
                                                ],
                                                [
                                                    1.3777,
                                                    6.6622
                                                ],
                                                [
                                                    0.8286,
                                                    6.6622
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "08/04/2",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 3755
                                        }
                                    ],
                                    "value": "08/04/2",
                                    "value_type": "string"
                                },
                                "Received from": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.4082,
                                                    6.4132
                                                ],
                                                [
                                                    6.8173,
                                                    6.4132
                                                ],
                                                [
                                                    6.8173,
                                                    6.7028
                                                ],
                                                [
                                                    1.4082,
                                                    6.7028
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Thr to HALB love for 20 yrs TTL Checks at 2150 AF Hrs &Complirs, 2150:00",
                                    "spans": [
                                        {
                                            "length": 55,
                                            "offset": 3763
                                        },
                                        {
                                            "length": 17,
                                            "offset": 3821
                                        }
                                    ],
                                    "value": "Thr to HALB love for 20 yrs TTL Checks at 2150 AF Hrs & Complirs, 2150:00",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "I.A.F. Catalogue No": null,
                    "Contract No.": null,
                    "Delete as appropriate": null,
                    "Type & MK": null,
                    "Canister Pt No.": null,
                    "AUTH": null,
                    "Maintenance Unit": null,
                    "SL No": null,
                    "AUTHY": null,
                    "LOG CARD No.": null,
                    "Opened at Date": null,
                    "Date of Receipt in India": null,
                    "Modification embodied": null,
                    "No": null,
                    "Section No": null,
                    "Job No.": null,
                    "Ref.No": null,
                    "TTL": null,
                    "TBO": null
                }
            ]
        ]
    },
    "https://formrecognizer12345.blob.core.windows.net/form-recognizer/formrecognizer12345/test/HAL/20231129-102006%25LC120001.pdf": {
        "message": "success",
        "data": [
            [
                {
                    "Opened at Unit/Station": "Bangalore",
                    "Opened by": "HAL (HC)",
                    "Opened at Date": "23/11/10",
                    "Nomenclature": "FORWARD MAIN TANK",
                    "Type & MK": "ALH MK-111",
                    "Manufacturers Part No": "FT-25422 ISS",
                    "Date of Manufacture": "02/2010",
                    "Serial No": "390835",
                    "Date of Receipt in India": "04/2010",
                    "Details of Major equipment or installation of which the item forms Part": "FUEL SYSTEM",
                    "Type of A/C or A/E on which installed": "ALH",
                    "Overhaul agency": "M/S GKN U.K.",
                    "TTL": "15 YEARS FROM DATE OF INSTALLATION (ONLY FOR UK MADE FUEL TANKS).SUBJECTED TO SATISFACTORY LEAK TEST AT'EVERY 01 YEAR AFTER 10 YEARS.",
                    "AUTHY": "RCMA/ (H/C)/1622/1 DTD 17/11/14) & MSR REVISION NO 01/10 DTD 14 DEC 2019",
                    "Certified that": "T.",
                    "Mark": "FT25422",
                    "No": "390835",
                    "Maintenance Unit": "00:00",
                    "Modification embodied": "TBO: OC (Auth: ALH/85518/ALH/EBA(T) elf 27 Aug14) TTL: (10 years) 15 years leak chick atevery year after completing Service life of10 years) Remarks: maximum Service life is 95 years including Storage life of 5 years",
                    "DATE": "23)m./b.o.",
                    "INSTALLATION RECORD": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E SI No. on which installed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    0.8388,
                                                    7.8056
                                                ],
                                                [
                                                    1.4844,
                                                    7.8056
                                                ],
                                                [
                                                    1.4844,
                                                    7.9631
                                                ],
                                                [
                                                    0.8388,
                                                    7.9631
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "OS-115",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1692
                                        }
                                    ],
                                    "value": "OS-115",
                                    "value_type": "string"
                                },
                                "Component Hrs at installation": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    2.125,
                                                    7.8259
                                                ],
                                                [
                                                    2.674,
                                                    7.8259
                                                ],
                                                [
                                                    2.674,
                                                    7.9733
                                                ],
                                                [
                                                    2.125,
                                                    7.9733
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "00:00",
                                    "spans": [
                                        {
                                            "length": 5,
                                            "offset": 1699
                                        }
                                    ],
                                    "value": "00:00",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.2383,
                                                    7.77
                                                ],
                                                [
                                                    4.2602,
                                                    7.77
                                                ],
                                                [
                                                    4.2602,
                                                    7.9885
                                                ],
                                                [
                                                    3.2383,
                                                    7.9885
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "23/11/1000:00",
                                    "spans": [
                                        {
                                            "length": 13,
                                            "offset": 1705
                                        }
                                    ],
                                    "value": "23/11/1000:00",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.6872,
                                                    7.8259
                                                ],
                                                [
                                                    4.738,
                                                    7.8259
                                                ],
                                                [
                                                    4.738,
                                                    7.9072
                                                ],
                                                [
                                                    4.6872,
                                                    7.9072
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "-",
                                    "spans": [
                                        {
                                            "length": 1,
                                            "offset": 1719
                                        }
                                    ],
                                    "value": "-",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    5.0431,
                                                    7.8564
                                                ],
                                                [
                                                    5.0685,
                                                    7.8564
                                                ],
                                                [
                                                    5.0685,
                                                    7.9377
                                                ],
                                                [
                                                    5.0431,
                                                    7.9377
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "-",
                                    "spans": [
                                        {
                                            "length": 1,
                                            "offset": 1721
                                        }
                                    ],
                                    "value": "-",
                                    "value_type": "string"
                                },
                                "Component Hours at removal coi. 2": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    5.6023,
                                                    7.7954
                                                ],
                                                [
                                                    7.0105,
                                                    7.7954
                                                ],
                                                [
                                                    7.0105,
                                                    7.9834
                                                ],
                                                [
                                                    5.6023,
                                                    7.9834
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Smrt at HALCHC)",
                                    "spans": [
                                        {
                                            "length": 15,
                                            "offset": 1723
                                        }
                                    ],
                                    "value": "Smrt at HALCHC)",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E SI No. on which installed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    0.8846,
                                                    8.2629
                                                ],
                                                [
                                                    1.8454,
                                                    8.2629
                                                ],
                                                [
                                                    1.8454,
                                                    8.4713
                                                ],
                                                [
                                                    0.8846,
                                                    8.4713
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "ZD-4111",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1746
                                        }
                                    ],
                                    "value": "ZD-4111",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.372,
                                                    8.268
                                                ],
                                                [
                                                    5.5413,
                                                    8.268
                                                ],
                                                [
                                                    5.5413,
                                                    8.4763
                                                ],
                                                [
                                                    4.372,
                                                    8.4763
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "05/04/21797:19",
                                    "spans": [
                                        {
                                            "length": 14,
                                            "offset": 1754
                                        }
                                    ],
                                    "value": "05/04/21797:19",
                                    "value_type": "string"
                                },
                                "Component Hours at removal coi. 2": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    5.5769,
                                                    8.268
                                                ],
                                                [
                                                    6.2886,
                                                    8.268
                                                ],
                                                [
                                                    6.2886,
                                                    8.4814
                                                ],
                                                [
                                                    5.5769,
                                                    8.4814
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1797:19",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1769
                                        }
                                    ],
                                    "value": "1797:19",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    6.3394,
                                                    8.268
                                                ],
                                                [
                                                    7.1071,
                                                    8.268
                                                ],
                                                [
                                                    7.1071,
                                                    8.4916
                                                ],
                                                [
                                                    6.3394,
                                                    8.4916
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Perfercity",
                                    "spans": [
                                        {
                                            "length": 10,
                                            "offset": 1777
                                        }
                                    ],
                                    "value": "Perfercity",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E SI No. on which installed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    0.8439,
                                                    8.5221
                                                ],
                                                [
                                                    1.5048,
                                                    8.5221
                                                ],
                                                [
                                                    1.5048,
                                                    8.7152
                                                ],
                                                [
                                                    0.8439,
                                                    8.7152
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "ZD .4146",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 1794
                                        }
                                    ],
                                    "value": "ZD .4146",
                                    "value_type": "string"
                                },
                                "Component Hrs at installation": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    2.0335,
                                                    8.5018
                                                ],
                                                [
                                                    2.73,
                                                    8.5018
                                                ],
                                                [
                                                    2.73,
                                                    8.6949
                                                ],
                                                [
                                                    2.0335,
                                                    8.6949
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1797:19",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1803
                                        }
                                    ],
                                    "value": "1797:19",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.2333,
                                                    8.4814
                                                ],
                                                [
                                                    3.6705,
                                                    8.4814
                                                ],
                                                [
                                                    3.6705,
                                                    8.6847
                                                ],
                                                [
                                                    3.2333,
                                                    8.6847
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "16/6/23",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1811
                                        }
                                    ],
                                    "value": "16/6/23",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.767,
                                                    8.5373
                                                ],
                                                [
                                                    4.2805,
                                                    8.5373
                                                ],
                                                [
                                                    4.2805,
                                                    8.705
                                                ],
                                                [
                                                    3.767,
                                                    8.705
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1799:59",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1819
                                        }
                                    ],
                                    "value": "1799:59",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.616,
                                                    8.517
                                                ],
                                                [
                                                    7.1884,
                                                    8.517
                                                ],
                                                [
                                                    7.1884,
                                                    8.7152
                                                ],
                                                [
                                                    4.616,
                                                    8.7152
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "omInstallation at HAL",
                                    "spans": [
                                        {
                                            "length": 22,
                                            "offset": 1827
                                        }
                                    ],
                                    "value": "om Installation at HAL",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "PART DISCREPANCY REPORT (PDR)": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "COLUMN1": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    1.2658,
                                                    1.1536
                                                ],
                                                [
                                                    3.3603,
                                                    1.1536
                                                ],
                                                [
                                                    3.3603,
                                                    1.5499
                                                ],
                                                [
                                                    1.2658,
                                                    1.5499
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "HAL Division HELICOPTER MRO DIVISION",
                                    "spans": [
                                        {
                                            "length": 36,
                                            "offset": 3322
                                        }
                                    ],
                                    "value": "HAL Division HELICOPTER MRO DIVISION",
                                    "value_type": "string"
                                },
                                "COLUMN2": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    3.8331,
                                                    1.1434
                                                ],
                                                [
                                                    5.16,
                                                    1.1434
                                                ],
                                                [
                                                    5.16,
                                                    1.5296
                                                ],
                                                [
                                                    3.8331,
                                                    1.5296
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "DATE (dd/mm/yyyy)25/04/2022",
                                    "spans": [
                                        {
                                            "length": 17,
                                            "offset": 3359
                                        },
                                        {
                                            "length": 10,
                                            "offset": 3379
                                        }
                                    ],
                                    "value": "DATE (dd/mm/yyyy) 25/04/2022",
                                    "value_type": "string"
                                },
                                "COLUMN3": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    5.7802,
                                                    1.1078
                                                ],
                                                [
                                                    7.5849,
                                                    1.1078
                                                ],
                                                [
                                                    7.5849,
                                                    1.2654
                                                ],
                                                [
                                                    5.7802,
                                                    1.2654
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "PDR NO: PDR/FA/7527",
                                    "spans": [
                                        {
                                            "length": 19,
                                            "offset": 3390
                                        }
                                    ],
                                    "value": "PDR NO: PDR/FA/7527",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "COLUMN1": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    1.4082,
                                                    1.677
                                                ],
                                                [
                                                    2.8418,
                                                    1.677
                                                ],
                                                [
                                                    2.8418,
                                                    2.2055
                                                ],
                                                [
                                                    1.4082,
                                                    2.2055
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "INSPECTORK VASANTHA KUMAR",
                                    "spans": [
                                        {
                                            "length": 9,
                                            "offset": 3410
                                        },
                                        {
                                            "length": 16,
                                            "offset": 3464
                                        }
                                    ],
                                    "value": "INSPECTOR K VASANTHA KUMAR",
                                    "value_type": "string"
                                },
                                "COLUMN2": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    4.1127,
                                                    1.6617
                                                ],
                                                [
                                                    5.0075,
                                                    1.6617
                                                ],
                                                [
                                                    5.0075,
                                                    2.1902
                                                ],
                                                [
                                                    4.1127,
                                                    2.1902
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "INSP. UNITFA - QA",
                                    "spans": [
                                        {
                                            "length": 10,
                                            "offset": 3420
                                        },
                                        {
                                            "length": 7,
                                            "offset": 3481
                                        }
                                    ],
                                    "value": "INSP. UNIT FA - QA",
                                    "value_type": "string"
                                },
                                "COLUMN3": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    5.8005,
                                                    1.6465
                                                ],
                                                [
                                                    7.8289,
                                                    1.6465
                                                ],
                                                [
                                                    7.8289,
                                                    2.1851
                                                ],
                                                [
                                                    5.8005,
                                                    2.1851
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "INSPECTION STAGE /WORK CENTER NOFA - Shop",
                                    "spans": [
                                        {
                                            "length": 32,
                                            "offset": 3431
                                        },
                                        {
                                            "length": 9,
                                            "offset": 3489
                                        }
                                    ],
                                    "value": "INSPECTION STAGE /WORK CENTER NO FA - Shop",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "COLUMN1": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    1.6827,
                                                    2.3224
                                                ],
                                                [
                                                    3.2282,
                                                    2.3224
                                                ],
                                                [
                                                    3.2282,
                                                    2.8356
                                                ],
                                                [
                                                    1.6827,
                                                    2.8356
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "PROJECT/UNIT NAME ALH 1 ALH",
                                    "spans": [
                                        {
                                            "length": 27,
                                            "offset": 3499
                                        }
                                    ],
                                    "value": "PROJECT/UNIT NAME ALH 1 ALH",
                                    "value_type": "string"
                                },
                                "COLUMN2": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    3.8586,
                                                    2.2969
                                                ],
                                                [
                                                    5.5108,
                                                    2.2969
                                                ],
                                                [
                                                    5.5108,
                                                    2.9677
                                                ],
                                                [
                                                    3.8586,
                                                    2.9677
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "A/c No. /ENG/OTHERS UNIT SL.NO ZD4115",
                                    "spans": [
                                        {
                                            "length": 37,
                                            "offset": 3527
                                        }
                                    ],
                                    "value": "A/c No. /ENG/OTHERS UNIT SL.NO ZD4115",
                                    "value_type": "string"
                                },
                                "COLUMN3": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    6.3038,
                                                    2.2969
                                                ],
                                                [
                                                    7.0054,
                                                    2.2969
                                                ],
                                                [
                                                    7.0054,
                                                    2.7797
                                                ],
                                                [
                                                    6.3038,
                                                    2.7797
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "CUSTOMER IAF",
                                    "spans": [
                                        {
                                            "length": 12,
                                            "offset": 3565
                                        }
                                    ],
                                    "value": "CUSTOMER IAF",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "COLUMN1": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    1.9318,
                                                    3.2066
                                                ],
                                                [
                                                    2.7757,
                                                    3.2066
                                                ],
                                                [
                                                    2.7757,
                                                    3.5877
                                                ],
                                                [
                                                    1.9318,
                                                    3.5877
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "PART NO FT.25422",
                                    "spans": [
                                        {
                                            "length": 16,
                                            "offset": 3578
                                        }
                                    ],
                                    "value": "PART NO FT.25422",
                                    "value_type": "string"
                                },
                                "COLUMN2": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    4.0111,
                                                    3.1863
                                                ],
                                                [
                                                    5.3989,
                                                    3.1863
                                                ],
                                                [
                                                    5.3989,
                                                    3.5725
                                                ],
                                                [
                                                    4.0111,
                                                    3.5725
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "PART NAME FWD MAIN TANK SKID",
                                    "spans": [
                                        {
                                            "length": 28,
                                            "offset": 3595
                                        }
                                    ],
                                    "value": "PART NAME FWD MAIN TANK SKID",
                                    "value_type": "string"
                                },
                                "COLUMN3": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    5.8158,
                                                    3.1558
                                                ],
                                                [
                                                    7.7679,
                                                    3.1558
                                                ],
                                                [
                                                    7.7679,
                                                    3.5572
                                                ],
                                                [
                                                    5.8158,
                                                    3.5572
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Part. S. No. /Batch No 390835",
                                    "spans": [
                                        {
                                            "length": 29,
                                            "offset": 3624
                                        }
                                    ],
                                    "value": "Part. S. No. /Batch No 390835",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "COLUMN1": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    1.2404,
                                                    3.8723
                                                ],
                                                [
                                                    3.6094,
                                                    3.8723
                                                ],
                                                [
                                                    3.6094,
                                                    4.4414
                                                ],
                                                [
                                                    1.2404,
                                                    4.4414
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "HOURS COMPLETED ON REMOVAL (HH : MM) 1797:19",
                                    "spans": [
                                        {
                                            "length": 44,
                                            "offset": 3654
                                        }
                                    ],
                                    "value": "HOURS COMPLETED ON REMOVAL (HH : MM) 1797:19",
                                    "value_type": "string"
                                },
                                "COLUMN2": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    3.8636,
                                                    3.8774
                                                ],
                                                [
                                                    5.6277,
                                                    3.8774
                                                ],
                                                [
                                                    5.6277,
                                                    4.4211
                                                ],
                                                [
                                                    3.8636,
                                                    4.4211
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "CALENDER LIFE COMPLETED ON REMOVAL (YYMM : DD) 12YRS 5 MONTHS",
                                    "spans": [
                                        {
                                            "length": 38,
                                            "offset": 3699
                                        },
                                        {
                                            "length": 23,
                                            "offset": 3740
                                        }
                                    ],
                                    "value": "CALENDER LIFE COMPLETED ON REMOVAL (YY MM : DD) 12YRS 5 MONTHS",
                                    "value_type": "string"
                                },
                                "COLUMN3": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    5.9276,
                                                    3.8164
                                                ],
                                                [
                                                    7.8544,
                                                    3.8164
                                                ],
                                                [
                                                    7.8544,
                                                    4.4008
                                                ],
                                                [
                                                    5.9276,
                                                    4.4008
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "IN-HOUSE/SUPPLIER/OEM ACD",
                                    "spans": [
                                        {
                                            "length": 25,
                                            "offset": 3764
                                        }
                                    ],
                                    "value": "IN-HOUSE/SUPPLIER/OEM ACD",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "COLUMN1": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    1.5709,
                                                    4.5634
                                                ],
                                                [
                                                    2.852,
                                                    4.5634
                                                ],
                                                [
                                                    2.852,
                                                    4.9699
                                                ],
                                                [
                                                    1.5709,
                                                    4.9699
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "W. O.NO/S.O.NO 50069 / 267588",
                                    "spans": [
                                        {
                                            "length": 29,
                                            "offset": 3790
                                        }
                                    ],
                                    "value": "W. O.NO/S.O.NO 50069 / 267588",
                                    "value_type": "string"
                                },
                                "COLUMN2": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    4.1127,
                                                    4.5532
                                                ],
                                                [
                                                    4.916,
                                                    4.5532
                                                ],
                                                [
                                                    4.916,
                                                    4.9344
                                                ],
                                                [
                                                    4.1127,
                                                    4.9344
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "PO NUMBER N/A",
                                    "spans": [
                                        {
                                            "length": 13,
                                            "offset": 3820
                                        }
                                    ],
                                    "value": "PO NUMBER N/A",
                                    "value_type": "string"
                                },
                                "COLUMN3": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    6.131,
                                                    4.5329
                                                ],
                                                [
                                                    7.3206,
                                                    4.5329
                                                ],
                                                [
                                                    7.3206,
                                                    4.9242
                                                ],
                                                [
                                                    6.131,
                                                    4.9242
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "MATERIAL CODE N/A",
                                    "spans": [
                                        {
                                            "length": 17,
                                            "offset": 3834
                                        }
                                    ],
                                    "value": "MATERIAL CODE N/A",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "COLUMN1": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    2.1097,
                                                    5.097
                                                ],
                                                [
                                                    2.9943,
                                                    5.097
                                                ],
                                                [
                                                    2.9943,
                                                    5.4832
                                                ],
                                                [
                                                    2.1097,
                                                    5.4832
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "PO ITEM NO N/A",
                                    "spans": [
                                        {
                                            "length": 14,
                                            "offset": 3852
                                        }
                                    ],
                                    "value": "PO ITEM NO N/A",
                                    "value_type": "string"
                                },
                                "COLUMN2": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    5.892,
                                                    5.0716
                                                ],
                                                [
                                                    6.6292,
                                                    5.0716
                                                ],
                                                [
                                                    6.6292,
                                                    5.4476
                                                ],
                                                [
                                                    5.892,
                                                    5.4476
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "R.R. NO.N/A",
                                    "spans": [
                                        {
                                            "length": 12,
                                            "offset": 4124
                                        }
                                    ],
                                    "value": "R.R. NO. N/A",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "PART DISCREPANCY REPORT - 2 (PDR-2)": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "COLUMN1": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    1.1998,
                                                    5.6153
                                                ],
                                                [
                                                    1.9217,
                                                    5.6153
                                                ],
                                                [
                                                    1.9217,
                                                    5.7627
                                                ],
                                                [
                                                    1.1998,
                                                    5.7627
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "QUANTITY",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 3867
                                        }
                                    ],
                                    "value": "QUANTITY",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "COLUMN1": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    1.2099,
                                                    5.8084
                                                ],
                                                [
                                                    1.9115,
                                                    5.8084
                                                ],
                                                [
                                                    1.9115,
                                                    5.9355
                                                ],
                                                [
                                                    1.2099,
                                                    5.9355
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "RECEIVED",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 3876
                                        }
                                    ],
                                    "value": "RECEIVED",
                                    "value_type": "string"
                                },
                                "COLUMN3": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    2.1403,
                                                    5.7881
                                                ],
                                                [
                                                    4.3873,
                                                    5.7881
                                                ],
                                                [
                                                    4.3873,
                                                    5.9253
                                                ],
                                                [
                                                    2.1403,
                                                    5.9253
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "INSPECTEDDEFECTIVE",
                                    "spans": [
                                        {
                                            "length": 19,
                                            "offset": 3885
                                        }
                                    ],
                                    "value": "INSPECTED DEFECTIVE",
                                    "value_type": "string"
                                },
                                "COLUMN4": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    5.5616,
                                                    5.7627
                                                ],
                                                [
                                                    7.2901,
                                                    5.7627
                                                ],
                                                [
                                                    7.2901,
                                                    5.9101
                                                ],
                                                [
                                                    5.5616,
                                                    5.9101
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "DEFFECT DESCRIPTION",
                                    "spans": [
                                        {
                                            "length": 19,
                                            "offset": 4137
                                        }
                                    ],
                                    "value": "DEFFECT DESCRIPTION",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "COLUMN1": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    1.2048,
                                                    6.1591
                                                ],
                                                [
                                                    5.3125,
                                                    6.1591
                                                ],
                                                [
                                                    5.3125,
                                                    6.962
                                                ],
                                                [
                                                    1.2048,
                                                    6.962
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "DESCRIPTION OF DEFECT: PUMP PLATE INSTALLATION STUDS FOUND CORRODED. VENT BTACKET STUDS NOT AVAILABLE IN 01 NO. FILLER NECK CUT OUT AREA FOUND DEBONDED. CARRY OUT NECESSARY REWORK & CERTIFY",
                                    "spans": [
                                        {
                                            "length": 189,
                                            "offset": 3911
                                        }
                                    ],
                                    "value": "DESCRIPTION OF DEFECT: PUMP PLATE INSTALLATION STUDS FOUND CORRODED. VENT BTACKET STUDS NOT AVAILABLE IN 01 NO. FILLER NECK CUT OUT AREA FOUND DEBONDED. CARRY OUT NECESSARY REWORK & CERTIFY",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "COLUMN1": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    1.2252,
                                                    7.0026
                                                ],
                                                [
                                                    3.2129,
                                                    7.0026
                                                ],
                                                [
                                                    3.2129,
                                                    7.1449
                                                ],
                                                [
                                                    1.2252,
                                                    7.1449
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "INSPECTOR SIGN & STAMP",
                                    "spans": [
                                        {
                                            "length": 22,
                                            "offset": 4101
                                        }
                                    ],
                                    "value": "INSPECTOR SIGN & STAMP",
                                    "value_type": "string"
                                },
                                "COLUMN2": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    4.7685,
                                                    6.962
                                                ],
                                                [
                                                    7.7984,
                                                    6.962
                                                ],
                                                [
                                                    7.7984,
                                                    7.2974
                                                ],
                                                [
                                                    4.7685,
                                                    7.2974
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "AUTHORISED BY INSPECTION SECTIONHEAD",
                                    "spans": [
                                        {
                                            "length": 32,
                                            "offset": 4185
                                        },
                                        {
                                            "length": 4,
                                            "offset": 4230
                                        }
                                    ],
                                    "value": "AUTHORISED BY INSPECTION SECTION HEAD",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "COLUMN1": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    1.2201,
                                                    7.709
                                                ],
                                                [
                                                    1.8352,
                                                    7.709
                                                ],
                                                [
                                                    1.8352,
                                                    7.831
                                                ],
                                                [
                                                    1.2201,
                                                    7.831
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "REMARKS",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 4238
                                        }
                                    ],
                                    "value": "REMARKS",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "COLUMN1": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    1.215,
                                                    8.0444
                                                ],
                                                [
                                                    4.3212,
                                                    8.0444
                                                ],
                                                [
                                                    4.3212,
                                                    8.207
                                                ],
                                                [
                                                    1.215,
                                                    8.207
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "DGAQA/DGCA REMARKS & CO-ORDINATION",
                                    "spans": [
                                        {
                                            "length": 34,
                                            "offset": 4248
                                        }
                                    ],
                                    "value": "DGAQA/DGCA REMARKS & CO-ORDINATION",
                                    "value_type": "string"
                                },
                                "COLUMN2": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    4.9821,
                                                    8.019
                                                ],
                                                [
                                                    6.9037,
                                                    8.019
                                                ],
                                                [
                                                    6.9037,
                                                    8.3442
                                                ],
                                                [
                                                    4.9821,
                                                    8.3442
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "LICENSOR/OEM/CUSTOMERCO-ORDINATIONsion,",
                                    "spans": [
                                        {
                                            "length": 21,
                                            "offset": 4307
                                        },
                                        {
                                            "length": 18,
                                            "offset": 4339
                                        }
                                    ],
                                    "value": "LICENSOR/OEM/CUSTOMER CO-ORDINATIONsion,",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "COLUMN1": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    1.2862,
                                                    8.9286
                                                ],
                                                [
                                                    2.0894,
                                                    8.9286
                                                ],
                                                [
                                                    2.0894,
                                                    9.0658
                                                ],
                                                [
                                                    1.2862,
                                                    9.0658
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "ENCLOSURE",
                                    "spans": [
                                        {
                                            "length": 9,
                                            "offset": 4383
                                        }
                                    ],
                                    "value": "ENCLOSURE",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "MAJOR UNSERVICEABILITY RECORD": [],
                    "PERIODICAL SERVICING RECORD": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.0879,
                                                    2.0479
                                                ],
                                                [
                                                    1.6014,
                                                    2.0479
                                                ],
                                                [
                                                    1.6014,
                                                    2.236
                                                ],
                                                [
                                                    1.0879,
                                                    2.236
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "18.4.17",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 4836
                                        }
                                    ],
                                    "value": "18.4.17",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.8251,
                                                    2.0276
                                                ],
                                                [
                                                    3.2485,
                                                    2.0276
                                                ],
                                                [
                                                    3.2485,
                                                    2.2258
                                                ],
                                                [
                                                    1.8251,
                                                    2.2258
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Fuel tank leak",
                                    "spans": [
                                        {
                                            "length": 14,
                                            "offset": 4844
                                        }
                                    ],
                                    "value": "Fuel tank leak",
                                    "value_type": "string"
                                },
                                "Details of work done and components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.9166,
                                                    2.0276
                                                ],
                                                [
                                                    6.6749,
                                                    2.0276
                                                ],
                                                [
                                                    6.6749,
                                                    2.4596
                                                ],
                                                [
                                                    1.9166,
                                                    2.4596
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "test carried out at Af 898:38 Af Hp.Next due on 1398:38AF Hrs",
                                    "spans": [
                                        {
                                            "length": 62,
                                            "offset": 4859
                                        }
                                    ],
                                    "value": "test carried out at Af 898:38 Af Hp. Next due on 1398:38AF Hrs",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.0778,
                                                    2.5307
                                                ],
                                                [
                                                    1.6217,
                                                    2.5307
                                                ],
                                                [
                                                    1.6217,
                                                    2.6933
                                                ],
                                                [
                                                    1.0778,
                                                    2.6933
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "01.01.19",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 4922
                                        }
                                    ],
                                    "value": "01.01.19",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.8861,
                                                    2.4799
                                                ],
                                                [
                                                    3.2485,
                                                    2.4799
                                                ],
                                                [
                                                    3.2485,
                                                    2.673
                                                ],
                                                [
                                                    1.8861,
                                                    2.673
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Fuel tank leak",
                                    "spans": [
                                        {
                                            "length": 14,
                                            "offset": 4931
                                        }
                                    ],
                                    "value": "Fuel tank leak",
                                    "value_type": "string"
                                },
                                "Details of work done and components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.9674,
                                                    2.4545
                                                ],
                                                [
                                                    6.6749,
                                                    2.4545
                                                ],
                                                [
                                                    6.6749,
                                                    2.8966
                                                ],
                                                [
                                                    1.9674,
                                                    2.8966
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "test carried out at 1289:47 Af HRSnext due on 1789:47",
                                    "spans": [
                                        {
                                            "length": 54,
                                            "offset": 4946
                                        }
                                    ],
                                    "value": "test carried out at 1289:47 Af HRS next due on 1789:47",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.032,
                                                    2.9779
                                                ],
                                                [
                                                    1.6471,
                                                    2.9779
                                                ],
                                                [
                                                    1.6471,
                                                    3.1151
                                                ],
                                                [
                                                    1.032,
                                                    3.1151
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "09.01. 20",
                                    "spans": [
                                        {
                                            "length": 9,
                                            "offset": 5001
                                        }
                                    ],
                                    "value": "09.01. 20",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.7793,
                                                    2.9372
                                                ],
                                                [
                                                    3.1163,
                                                    2.9372
                                                ],
                                                [
                                                    3.1163,
                                                    3.1253
                                                ],
                                                [
                                                    1.7793,
                                                    3.1253
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "fuel tank leak",
                                    "spans": [
                                        {
                                            "length": 14,
                                            "offset": 5011
                                        }
                                    ],
                                    "value": "fuel tank leak",
                                    "value_type": "string"
                                },
                                "Details of work done and components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.7031,
                                                    2.9068
                                                ],
                                                [
                                                    6.2327,
                                                    2.9068
                                                ],
                                                [
                                                    6.2327,
                                                    3.3387
                                                ],
                                                [
                                                    1.7031,
                                                    3.3387
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "test carried out at 1499:54 Af hsfound at Next due on 1999:54 AF hrs.",
                                    "spans": [
                                        {
                                            "length": 70,
                                            "offset": 5026
                                        }
                                    ],
                                    "value": "test carried out at 1499:54 Af hs found at Next due on 1999:54 AF hrs.",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.0371,
                                                    3.3946
                                                ],
                                                [
                                                    1.6522,
                                                    3.3946
                                                ],
                                                [
                                                    1.6522,
                                                    3.5928
                                                ],
                                                [
                                                    1.0371,
                                                    3.5928
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "06/04/22",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 5102
                                        }
                                    ],
                                    "value": "06/04/22",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    2.0233,
                                                    3.42
                                                ],
                                                [
                                                    2.0945,
                                                    3.42
                                                ],
                                                [
                                                    2.0945,
                                                    3.5369
                                                ],
                                                [
                                                    2.0233,
                                                    3.5369
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "-",
                                    "spans": [
                                        {
                                            "length": 1,
                                            "offset": 5111
                                        }
                                    ],
                                    "value": "-",
                                    "value_type": "string"
                                },
                                "Details of work done and components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    2.852,
                                                    3.359
                                                ],
                                                [
                                                    5.2617,
                                                    3.359
                                                ],
                                                [
                                                    5.2617,
                                                    3.603
                                                ],
                                                [
                                                    2.852,
                                                    3.603
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "preservation c/o.",
                                    "spans": [
                                        {
                                            "length": 17,
                                            "offset": 5113
                                        }
                                    ],
                                    "value": "preservation c/o.",
                                    "value_type": "string"
                                },
                                "date declared serviceable or unserviceable": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    5.8005,
                                                    3.3235
                                                ],
                                                [
                                                    6.6851,
                                                    3.3235
                                                ],
                                                [
                                                    6.6851,
                                                    3.5166
                                                ],
                                                [
                                                    5.8005,
                                                    3.5166
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "06/04/20",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 5131
                                        }
                                    ],
                                    "value": "06/04/20",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.0727,
                                                    3.6182
                                                ],
                                                [
                                                    1.7641,
                                                    3.6182
                                                ],
                                                [
                                                    1.7641,
                                                    3.8062
                                                ],
                                                [
                                                    1.0727,
                                                    3.8062
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "23/08/22",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 5140
                                        }
                                    ],
                                    "value": "23/08/22",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    2.0335,
                                                    3.6182
                                                ],
                                                [
                                                    2.6232,
                                                    3.6182
                                                ],
                                                [
                                                    2.6232,
                                                    3.8062
                                                ],
                                                [
                                                    2.0335,
                                                    3.8062
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Repair",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 5149
                                        }
                                    ],
                                    "value": "Repair",
                                    "value_type": "string"
                                },
                                "Details of work done and components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    2.735,
                                                    3.5674
                                                ],
                                                [
                                                    5.5819,
                                                    3.5674
                                                ],
                                                [
                                                    5.5819,
                                                    4.0247
                                                ],
                                                [
                                                    2.735,
                                                    4.0247
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Tank is repaired and lacktest is satisfactory",
                                    "spans": [
                                        {
                                            "length": 25,
                                            "offset": 5156
                                        },
                                        {
                                            "length": 20,
                                            "offset": 5186
                                        }
                                    ],
                                    "value": "Tank is repaired and lack test is satisfactory",
                                    "value_type": "string"
                                },
                                "date declared serviceable or unserviceable": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    5.8158,
                                                    3.7402
                                                ],
                                                [
                                                    6.802,
                                                    3.7402
                                                ],
                                                [
                                                    6.802,
                                                    4.0095
                                                ],
                                                [
                                                    5.8158,
                                                    4.0095
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "23/08/22",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 5207
                                        }
                                    ],
                                    "value": "23/08/22",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "TRANSFER RECORD": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.0167,
                                                    6.3674
                                                ],
                                                [
                                                    1.642,
                                                    6.3674
                                                ],
                                                [
                                                    1.642,
                                                    6.5453
                                                ],
                                                [
                                                    1.0167,
                                                    6.5453
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "8.2.22",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 5598
                                        }
                                    ],
                                    "value": "8.2.22",
                                    "value_type": "string"
                                },
                                "Received from": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 4,
                                            "polygon": [
                                                [
                                                    1.7946,
                                                    6.3268
                                                ],
                                                [
                                                    6.6597,
                                                    6.3268
                                                ],
                                                [
                                                    6.6597,
                                                    6.7892
                                                ],
                                                [
                                                    1.7946,
                                                    6.7892
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "On Tortaservicing at 1797:19 AfthS and 17/97:19 comp.hrs.to Hise Aleygarth H/C for 1800 hrs",
                                    "spans": [
                                        {
                                            "length": 93,
                                            "offset": 5605
                                        }
                                    ],
                                    "value": "On Torta to Hise Aleygarth H/C for 1800 hrs servicing at 1797:19 AfthS and 17/97:19 comp.hrs.",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "TBO": null,
                    "Canister Pt No.": null,
                    "Job No.": null,
                    "Authorised overhaul life": null,
                    "LOG CARD No.": null,
                    "SL No": null,
                    "Ref.No": null,
                    "Delete as appropriate": null,
                    "AUTH": null,
                    "No. of overhauls done": null,
                    "Section No": null,
                    "I.A.F. Catalogue No": null,
                    "Contract No.": null
                }
            ]
        ]
    },
    "https://formrecognizer12345.blob.core.windows.net/form-recognizer/formrecognizer12345/test/HAL/20231129-102006%25LC130001.pdf": {
        "message": "success",
        "data": [
            [
                {
                    "Opened at Unit/Station": "Bangalore",
                    "Opened by": "HAL (H/C)",
                    "Opened at Date": "28/2/14",
                    "Nomenclature": "Middle Main Tank",
                    "Type & MK": "iss k.",
                    "Manufacturers Part No": "FT 25423",
                    "Date of Manufacture": "20 DEC 2013",
                    "Serial No": "MO78V",
                    "Details of Major equipment or installation of which the item forms Part": "FUEL SYSTEM",
                    "Type of A/C or A/E on which installed": "ALH",
                    "Overhaul agency": "HAL (H/C)",
                    "TTL": "15 YEARS FROM DATE OF MANUFACTURED (ONLY FOR ACD under TOT HAL MADE FUEL TANKS).SUBJECTED TO SATISFACTORY LEAK TEST AT 05 YEARS (LCMR SEP 20)",
                    "AUTHY": "RCMA (H/C)/1500/LIFING DATED 14 TH DEC 2020 & AIRHQ/85518/ALH/EB4 (T) DTD 31 DEC 20(16TH LCM).",
                    "Certified that": "Middle Main Tank",
                    "Mark": "FT 25423 us K",
                    "No": "MO78",
                    "Contract No.": "CMD",
                    "Job No.": "02.02.01 1.1.295.8",
                    "Maintenance Unit": "O.O Q.Q",
                    "DATE": "28/ 2/1.u096b.",
                    "INSTALLATION RECORD": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E SI No. on which installed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    0.6304,
                                                    7.6683
                                                ],
                                                [
                                                    1.4997,
                                                    7.6683
                                                ],
                                                [
                                                    1.4997,
                                                    7.8818
                                                ],
                                                [
                                                    0.6304,
                                                    7.8818
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "EP4115",
                                    "spans": [
                                        {
                                            "length": 2,
                                            "offset": 1663
                                        },
                                        {
                                            "length": 4,
                                            "offset": 1668
                                        }
                                    ],
                                    "value": "EP 4115",
                                    "value_type": "string"
                                },
                                "Component Hrs at installation": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    2.1047,
                                                    7.7039
                                                ],
                                                [
                                                    2.7757,
                                                    7.7039
                                                ],
                                                [
                                                    2.7757,
                                                    7.8869
                                                ],
                                                [
                                                    2.1047,
                                                    7.8869
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "00:00",
                                    "spans": [
                                        {
                                            "length": 5,
                                            "offset": 1673
                                        }
                                    ],
                                    "value": "00:00",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.1214,
                                                    7.709
                                                ],
                                                [
                                                    3.7213,
                                                    7.709
                                                ],
                                                [
                                                    3.7213,
                                                    7.897
                                                ],
                                                [
                                                    3.1214,
                                                    7.897
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "25.10.14",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 1679
                                        }
                                    ],
                                    "value": "25.10.14",
                                    "value_type": "string"
                                },
                                "A/C or A/E at Installation Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    3.8179,
                                                    7.7141
                                                ],
                                                [
                                                    4.3669,
                                                    7.7141
                                                ],
                                                [
                                                    4.3669,
                                                    7.9123
                                                ],
                                                [
                                                    3.8179,
                                                    7.9123
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "417:00",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 1688
                                        }
                                    ],
                                    "value": "417:00",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.433,
                                                    7.7192
                                                ],
                                                [
                                                    7.2291,
                                                    7.7192
                                                ],
                                                [
                                                    7.2291,
                                                    7.9326
                                                ],
                                                [
                                                    4.433,
                                                    7.9326
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Sonst doone ber HAL",
                                    "spans": [
                                        {
                                            "length": 19,
                                            "offset": 1695
                                        }
                                    ],
                                    "value": "Sonst doone ber HAL",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "A/C or A/E SI No. on which installed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    0.7676,
                                                    8.1765
                                                ],
                                                [
                                                    1.6725,
                                                    8.1765
                                                ],
                                                [
                                                    1.6725,
                                                    8.3798
                                                ],
                                                [
                                                    0.7676,
                                                    8.3798
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "20-4115",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1750
                                        }
                                    ],
                                    "value": "20-4115",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.3313,
                                                    8.2273
                                                ],
                                                [
                                                    4.8753,
                                                    8.2273
                                                ],
                                                [
                                                    4.8753,
                                                    8.4306
                                                ],
                                                [
                                                    4.3313,
                                                    8.4306
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "05/04/22",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 1758
                                        }
                                    ],
                                    "value": "05/04/22",
                                    "value_type": "string"
                                },
                                "A/C or A/E At removal Hours": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    4.9109,
                                                    8.2375
                                                ],
                                                [
                                                    5.4803,
                                                    8.2375
                                                ],
                                                [
                                                    5.4803,
                                                    8.4357
                                                ],
                                                [
                                                    4.9109,
                                                    8.4357
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1797:19",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1767
                                        }
                                    ],
                                    "value": "1797:19",
                                    "value_type": "string"
                                },
                                "Component Hours at removal coi. 2": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    5.5158,
                                                    8.2477
                                                ],
                                                [
                                                    6.192,
                                                    8.2477
                                                ],
                                                [
                                                    6.192,
                                                    8.4459
                                                ],
                                                [
                                                    5.5158,
                                                    8.4459
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1380:19",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 1775
                                        }
                                    ],
                                    "value": "1380:19",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 1,
                                            "polygon": [
                                                [
                                                    6.2428,
                                                    8.2629
                                                ],
                                                [
                                                    7.2189,
                                                    8.2629
                                                ],
                                                [
                                                    7.2189,
                                                    8.4662
                                                ],
                                                [
                                                    6.2428,
                                                    8.4662
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "For Servicing",
                                    "spans": [
                                        {
                                            "length": 13,
                                            "offset": 1783
                                        }
                                    ],
                                    "value": "For Servicing",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "PART DISCREPANCY REPORT (PDR)": [],
                    "PART DISCREPANCY REPORT - 2 (PDR-2)": [],
                    "MAJOR UNSERVICEABILITY RECORD": [],
                    "PERIODICAL SERVICING RECORD": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    0.8388,
                                                    1.8142
                                                ],
                                                [
                                                    1.3879,
                                                    1.8142
                                                ],
                                                [
                                                    1.3879,
                                                    1.9768
                                                ],
                                                [
                                                    0.8388,
                                                    1.9768
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "18-04-17",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 3648
                                        }
                                    ],
                                    "value": "18-04-17",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    1.5709,
                                                    1.7837
                                                ],
                                                [
                                                    3.1011,
                                                    1.7837
                                                ],
                                                [
                                                    3.1011,
                                                    1.987
                                                ],
                                                [
                                                    1.5709,
                                                    1.987
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "fuel tame leave",
                                    "spans": [
                                        {
                                            "length": 15,
                                            "offset": 3657
                                        }
                                    ],
                                    "value": "fuel tame leave",
                                    "value_type": "string"
                                },
                                "Details of work done and components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    3.2282,
                                                    1.7939
                                                ],
                                                [
                                                    6.7664,
                                                    1.7939
                                                ],
                                                [
                                                    6.7664,
                                                    1.9971
                                                ],
                                                [
                                                    3.2282,
                                                    1.9971
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "test carried out at 898:38 AFIM fondsA",
                                    "spans": [
                                        {
                                            "length": 38,
                                            "offset": 3673
                                        }
                                    ],
                                    "value": "test carried out at 898:38 AFIM fondsA",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    1.52,
                                                    1.987
                                                ],
                                                [
                                                    3.5434,
                                                    1.987
                                                ],
                                                [
                                                    3.5434,
                                                    2.1496
                                                ],
                                                [
                                                    1.52,
                                                    2.1496
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Next due on: (1398:38 Af h.)",
                                    "spans": [
                                        {
                                            "length": 28,
                                            "offset": 3718
                                        }
                                    ],
                                    "value": "Next due on: (1398:38 Af h.)",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    0.7931,
                                                    2.241
                                                ],
                                                [
                                                    1.276,
                                                    2.241
                                                ],
                                                [
                                                    1.276,
                                                    2.3986
                                                ],
                                                [
                                                    0.7931,
                                                    2.3986
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "1-1-19",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 3807
                                        }
                                    ],
                                    "value": "1-1-19",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    1.4895,
                                                    2.2055
                                                ],
                                                [
                                                    2.8164,
                                                    2.2055
                                                ],
                                                [
                                                    2.8164,
                                                    2.3783
                                                ],
                                                [
                                                    1.4895,
                                                    2.3783
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Fuel tank leak",
                                    "spans": [
                                        {
                                            "length": 14,
                                            "offset": 3747
                                        }
                                    ],
                                    "value": "Fuel tank leak",
                                    "value_type": "string"
                                },
                                "Details of work done and components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    2.913,
                                                    2.1902
                                                ],
                                                [
                                                    6.7766,
                                                    2.1902
                                                ],
                                                [
                                                    6.7766,
                                                    2.3833
                                                ],
                                                [
                                                    2.913,
                                                    2.3833
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "test carried out at 1289:47 A/F hr found sat",
                                    "spans": [
                                        {
                                            "length": 44,
                                            "offset": 3762
                                        }
                                    ],
                                    "value": "test carried out at 1289:47 A/F hr found sat",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    1.7793,
                                                    2.3884
                                                ],
                                                [
                                                    4.2246,
                                                    2.3884
                                                ],
                                                [
                                                    4.2246,
                                                    2.6171
                                                ],
                                                [
                                                    1.7793,
                                                    2.6171
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "next dove on 1789:47 A/F Wh)",
                                    "spans": [
                                        {
                                            "length": 28,
                                            "offset": 3814
                                        }
                                    ],
                                    "value": "next dove on 1789:47 A/F Wh)",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    0.7626,
                                                    2.7238
                                                ],
                                                [
                                                    1.3319,
                                                    2.7238
                                                ],
                                                [
                                                    1.3319,
                                                    2.8509
                                                ],
                                                [
                                                    0.7626,
                                                    2.8509
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "09-1-20",
                                    "spans": [
                                        {
                                            "length": 7,
                                            "offset": 3843
                                        }
                                    ],
                                    "value": "09-1-20",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    1.4794,
                                                    2.7035
                                                ],
                                                [
                                                    2.735,
                                                    2.7035
                                                ],
                                                [
                                                    2.735,
                                                    2.8661
                                                ],
                                                [
                                                    1.4794,
                                                    2.8661
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Fuel tank leak",
                                    "spans": [
                                        {
                                            "length": 14,
                                            "offset": 3851
                                        }
                                    ],
                                    "value": "Fuel tank leak",
                                    "value_type": "string"
                                },
                                "Details of work done and components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    2.9181,
                                                    2.7035
                                                ],
                                                [
                                                    6.0801,
                                                    2.7035
                                                ],
                                                [
                                                    6.0801,
                                                    2.8712
                                                ],
                                                [
                                                    2.9181,
                                                    2.8712
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "test carried out at 1499:54 AF his.",
                                    "spans": [
                                        {
                                            "length": 35,
                                            "offset": 3866
                                        }
                                    ],
                                    "value": "test carried out at 1499:54 AF his.",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    1.4489,
                                                    2.922
                                                ],
                                                [
                                                    4.8092,
                                                    2.922
                                                ],
                                                [
                                                    4.8092,
                                                    3.0999
                                                ],
                                                [
                                                    1.4489,
                                                    3.0999
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "found yat, Next dice on 1999:54 Af has.",
                                    "spans": [
                                        {
                                            "length": 39,
                                            "offset": 3902
                                        }
                                    ],
                                    "value": "found yat, Next dice on 1999:54 Af has.",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    0.7981,
                                                    3.1456
                                                ],
                                                [
                                                    1.398,
                                                    3.1456
                                                ],
                                                [
                                                    1.398,
                                                    3.3082
                                                ],
                                                [
                                                    0.7981,
                                                    3.3082
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "06/04/22",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 3942
                                        }
                                    ],
                                    "value": "06/04/22",
                                    "value_type": "string"
                                },
                                "Details of work done and components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    2.5419,
                                                    3.1507
                                                ],
                                                [
                                                    4.8499,
                                                    3.1507
                                                ],
                                                [
                                                    4.8499,
                                                    3.359
                                                ],
                                                [
                                                    2.5419,
                                                    3.359
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "preservation c/o.",
                                    "spans": [
                                        {
                                            "length": 17,
                                            "offset": 3951
                                        }
                                    ],
                                    "value": "preservation c/o.",
                                    "value_type": "string"
                                },
                                "date declared serviceable or unserviceable": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    5.465,
                                                    3.1558
                                                ],
                                                [
                                                    6.3242,
                                                    3.1558
                                                ],
                                                [
                                                    6.3242,
                                                    3.3489
                                                ],
                                                [
                                                    5.465,
                                                    3.3489
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "06/04/22",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 3969
                                        }
                                    ],
                                    "value": "06/04/22",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        },
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    0.7626,
                                                    3.359
                                                ],
                                                [
                                                    1.4234,
                                                    3.359
                                                ],
                                                [
                                                    1.4234,
                                                    3.5267
                                                ],
                                                [
                                                    0.7626,
                                                    3.5267
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "24/09/22",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 3983
                                        }
                                    ],
                                    "value": "24/09/22",
                                    "value_type": "string"
                                },
                                "Reason for removal": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    1.637,
                                                    3.359
                                                ],
                                                [
                                                    2.1453,
                                                    3.359
                                                ],
                                                [
                                                    2.1453,
                                                    3.5318
                                                ],
                                                [
                                                    1.637,
                                                    3.5318
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Repair",
                                    "spans": [
                                        {
                                            "length": 6,
                                            "offset": 3992
                                        }
                                    ],
                                    "value": "Repair",
                                    "value_type": "string"
                                },
                                "Details of work done and components changed": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    2.4249,
                                                    3.3692
                                                ],
                                                [
                                                    5.1447,
                                                    3.3692
                                                ],
                                                [
                                                    5.1447,
                                                    3.8011
                                                ],
                                                [
                                                    2.4249,
                                                    3.8011
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "Tank is repaired and leaktest is satisfactory",
                                    "spans": [
                                        {
                                            "length": 25,
                                            "offset": 3999
                                        },
                                        {
                                            "length": 20,
                                            "offset": 4029
                                        }
                                    ],
                                    "value": "Tank is repaired and leak test is satisfactory",
                                    "value_type": "string"
                                },
                                "date declared serviceable or unserviceable": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    5.5921,
                                                    3.608
                                                ],
                                                [
                                                    6.5173,
                                                    3.608
                                                ],
                                                [
                                                    6.5173,
                                                    3.791
                                                ],
                                                [
                                                    5.5921,
                                                    3.791
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "24/09/22",
                                    "spans": [
                                        {
                                            "length": 8,
                                            "offset": 4050
                                        }
                                    ],
                                    "value": "24/09/22",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "TRANSFER RECORD": [
                        {
                            "bounding_regions": [],
                            "confidence": null,
                            "content": null,
                            "spans": [],
                            "value": {
                                "Date": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    0.8693,
                                                    6.1286
                                                ],
                                                [
                                                    1.2455,
                                                    6.1286
                                                ],
                                                [
                                                    1.2455,
                                                    6.281
                                                ],
                                                [
                                                    0.8693,
                                                    6.281
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "2-22",
                                    "spans": [
                                        {
                                            "length": 4,
                                            "offset": 4444
                                        }
                                    ],
                                    "value": "2-22",
                                    "value_type": "string"
                                },
                                "Received from": {
                                    "bounding_regions": [
                                        {
                                            "page_number": 3,
                                            "polygon": [
                                                [
                                                    1.4641,
                                                    6.0981
                                                ],
                                                [
                                                    6.7309,
                                                    6.0981
                                                ],
                                                [
                                                    6.7309,
                                                    6.5605
                                                ],
                                                [
                                                    1.4641,
                                                    6.5605
                                                ]
                                            ]
                                        }
                                    ],
                                    "confidence": null,
                                    "content": "On Tratarto HAL, AlongauthH/C - for 1800 hrsA/F servicing at 1797:19 AF has and1380:19 comp. hry",
                                    "spans": [
                                        {
                                            "length": 100,
                                            "offset": 4449
                                        }
                                    ],
                                    "value": "On Tratar to HAL, Alongauth H/C - for 1800 hrs A/F servicing at 1797:19 AF has and 1380:19 comp. hry",
                                    "value_type": "string"
                                }
                            },
                            "value_type": "dictionary"
                        }
                    ],
                    "Authorised overhaul life": null,
                    "I.A.F. Catalogue No": null,
                    "AUTH": null,
                    "Modification embodied": null,
                    "Ref.No": null,
                    "TBO": null,
                    "SL No": null,
                    "No. of overhauls done": null,
                    "LOG CARD No.": null,
                    "Section No": null,
                    "Date of Receipt in India": null,
                    "Canister Pt No.": null,
                    "Delete as appropriate": null
                }
            ]
        ]
    }
}