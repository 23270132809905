/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Dropdown1 } from "../../content/dropdown/Dropdown1";
import { KTIcon, toAbsoluteUrl } from "../../../helpers";
import { useAuth } from "../../../../app/modules/auth";
import { statistic } from "../../../../app/api";
import { Link } from "react-router-dom";

type Props = {
  className: string;
  color: string;
  svgIcon?: string;
  iconColor: string;
  title: string;
  titleColor?: string;
  description: string;
  descriptionColor?: string;
  image?: string;
  tag?: string;
};

const MixedWidgetDocument: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor,
  image,
  tag
}) => {
  type MyObject = {
    data: {
      processed_count: number;
      unprocessed_count: number;
      total_count: number;
    };
  };
  const [statisticsData, setStatisticsData] = useState<MyObject>({
    data: {
      processed_count: 0,
      unprocessed_count: 0,
      total_count: 0
    }
  });

  const { currentUser } = useAuth();

  useEffect(() => {
    if (tag) {
      statistic({ useremail: currentUser?.email, tag: tag })
        .then((res) => {
          if (res && res.data) {
            setStatisticsData({
              data: {
                processed_count: Number(res.data?.processed_count) || 0,
                unprocessed_count: Number(res.data?.unprocessed_count) || 0,
                total_count: Number(res.data?.total_count) || 0
              }
            });
          } else {
            setStatisticsData({
              data: {
                processed_count: 0,
                unprocessed_count: 0,
                total_count: 0
              }
            });
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          // Handle API call error if necessary
        });
    }
  }, []);
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className="card-body p-0">
        {/* begin::Header */}
        <div className={`px-9 pt-7 card-rounded h-275px w-100 bg-${color}`}>
          {/* begin::Heading */}
          <div className="d-flex flex-stack">
            <h3 className="m-0 text-white fw-bold fs-1">
              {title}
            </h3>
            <div className="ms-1">
              {/* begin::Menu */}
              <button
                type="button"
                className={`btn btn-sm btn-icon btn-color-white btn-active-white btn-active-color-${color} border-0 me-n3`}
              >
                <Link to={`/statistics/${tag}`}>
                  <img src={toAbsoluteUrl("/media/custom-icon/graph-icon.png")} alt="Thirdeye data" height={30} /> &nbsp;
                </Link>
              </button>
              <Dropdown1 />
              {/* end::Menu */}
            </div>
          </div>
          {/* end::Heading */}
          {/* begin::Balance */}
          <div className="d-flex text-center flex-column text-white pt-8">
            {/* <span className="fw-semibold fs-7">Total API Call</span> */}
            <span className="fw-bold fs-5 pt-1">{description}</span>
          </div>
          {/* end::Balance */}
        </div>
        {/* end::Header */}
        {/* begin::Items */}
        <div
          className="shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-body"
          style={{ marginTop: "-100px" }}
        >
          {/* begin::Item */}
          <div className="d-flex align-items-center mb-6">
            {/* begin::Symbol */}
            <div className="symbol symbol-45px w-40px me-5">
              <span className="symbol-label bg-lighten">
                <KTIcon iconName="compass" className="fs-1" />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className="d-flex align-items-center flex-wrap w-100">
              {/* begin::Title */}
              <div className="mb-1 pe-3 flex-grow-1">
                <a
                  href="#"
                  className="fs-5 text-gray-800 text-hover-primary fw-bold"
                  style={{ margin: '0', opacity: 0.5 }}
                >

                  Processed Count
                </a>
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className="d-flex align-items-center">
                <div className="fw-bold fs-1 text-gray-800 pe-1"> {statisticsData?.data?.processed_count}</div>
              </div>
              {/* end::Label */}
            </div>
            {/* end::Description */}
          </div>
          {/* end::Item */}
          {/* begin::Item */}
          <div className="d-flex align-items-center mb-6">
            {/* begin::Symbol */}
            <div className="symbol symbol-45px w-40px me-5">
              <span className="symbol-label bg-lighten">
                <KTIcon iconName="category" className="fs-1" />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className="d-flex align-items-center flex-wrap w-100">
              {/* begin::Title */}
              <div className="mb-1 pe-3 flex-grow-1">
                <a
                  href="#"
                  className="fs-5 text-gray-800 text-hover-primary fw-bold"
                  style={{ margin: '0', opacity: 0.5 }}
                >
                  Unprocessed Count
                </a>
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className="d-flex align-items-center">
                <div className="fw-bold fs-1 text-gray-800 pe-1">{statisticsData?.data?.unprocessed_count}</div>
              </div>
              {/* end::Label */}
            </div>
            {/* end::Description */}
          </div>
          {/* end::Item */}
          {/* begin::Item */}
          <div className="d-flex align-items-center mb-6">
            {/* begin::Symbol */}
            <div className="symbol symbol-45px w-40px me-5">
              <span className="symbol-label bg-lighten">
                <KTIcon iconName="document" className="fs-1" />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className="d-flex align-items-center flex-wrap w-100">
              {/* begin::Title */}
              <div className="mb-1 pe-3 flex-grow-1">
                <a
                  href="#"
                  className="fs-5 text-gray-800 text-hover-primary fw-bold"
                  style={{ margin: '0', opacity: 0.5 }}
                >
                  Total Count
                </a>
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className="d-flex align-items-center">
                <div className="fw-bold fs-1 text-gray-800 pe-1">{statisticsData?.data?.total_count}</div>
              </div>
              {/* end::Label */}
            </div>
            {/* end::Description */}
          </div>
          {/* end::Item */}
        </div>
        {/* end::Items */}
      </div>
      {/* end::Body */}
    </div>
  );
};

export { MixedWidgetDocument };
