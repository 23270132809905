import React, { useCallback, useEffect, useState } from "react";
import {
  DropEvent,
  FileRejection,
  useDropzone
} from "react-dropzone";
import { Blocks } from "react-loader-spinner";
import { pdfjs } from "react-pdf";
import { KTIcon, toAbsoluteUrl } from "../../../_metronic/helpers";
import {
  fileTextExtraction,
  filesByUser
} from "../../api";
import { AddDatasetForm } from "../../modules/documentprocessing/AddDatasetForm";
import { useAuth } from "../../modules/auth";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type Props = {};

type fileType = Array<string>;

const bgList = ["bg-success", "bg-primary", "bg-warning"];

type MyObject = {
  [key: string]: string[];
};

export default function MetaTagExtraction({ }: Props) {
  const { currentUser } = useAuth();
  const [folder, setFolder] = useState({
    folderName: "",
    files: [
      {
        blobLocation: "",
        containerName: "",
        fileOriginalName: "",
        fileUrl: "",
        processed: 0,
        tag: "",
        userEmail: "",
        createdAt: ""
      }
    ]
  });
  const [selectedFileNames, setSelectedFileNames] = useState<string[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [pdfFile, setPdfFile] = useState<File>();
  const [doctype, setDoctype] = useState<string>("");
  const [url, setUrl] = useState("");
  const [selectedCsv, setSelectedCsv] = useState([{}]);
  const [result, setResult] = useState<Array<MyObject>>([]);
  const [processing, setProcessing] = useState<boolean>(false);
  const [preview, setPreview] = useState<boolean>(false);
  const [selectedFilesDrop, setSelectedFilesDrop] = useState<File[]>([]);
  const [pdfUrl, setPdfUrl] = useState<string>(
    "https://example.com/path-to-your-pdf-file.pdf"
  );
  const [fileName, setFileName] = useState<string>("");

  const [sasUrl, setSasUrl] = React.useState("");

  const handleDownload = () => { };


  const handleSelect = (
    e: any,
    url: string,
    name: string,
    tag: string,
    processed: number
  ) => {
    console.log(e.target.checked, url);

    if (e.target.checked) {
      let tempSelectedFileNames = selectedFileNames;
      tempSelectedFileNames.push(name);
      setSelectedFileNames([...tempSelectedFileNames]);
      selectedFiles.push(url);
      setDoctype(tag)
      setFileName(name);
      // if (processed == 1 && selectedFiles.length <= 1) {
      //   handleProcess();
      // }
    }
    if (!e.target.checked) {
      setFileName("");
      setResult([]);
      const index = selectedFiles.indexOf(url);
      if (index > -1) {
        selectedFiles.splice(index, 1);
        let tempSelectedFileNames = selectedFileNames;
        tempSelectedFileNames.splice(index, 1);
        setSelectedFileNames([...tempSelectedFileNames]);
      }
    }
  };

  const handleProcess = (event: React.FormEvent) => {
    event.preventDefault();
    setResult([]);
    setProcessing(true);
    const formData = new FormData();
    if (pdfFile) {
      formData.append("files", pdfFile, `${pdfFile.name}`);
    }

    let fileList: Array<Object> = [];

    selectedFiles.forEach((selectedFile) => {
      let fileObj = {
        fileLink: selectedFile
      };
      fileList.push(fileObj);
    });

    fileTextExtraction({
      files: fileList,
      userName: currentUser?.username,
      email: currentUser?.email
    }).then((response) => {
      if ("error" in response.data[0]) {
        alert("Something went wrong. Please try again later")
        setProcessing(false);
      } else {
        setResult(response.data);
        setSelectedFileNames([])
        setProcessing(false);
      }
    })
      .then((data) => { })
      .catch((error) => {
        // Handle error
      });
  };

  const handlePreview = async () => {
    setPreview(!preview);
  };

  const onDrop = useCallback(
    (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      // Handle file changes here
      console.log(acceptedFiles);
      if (acceptedFiles) {
        setFileName(acceptedFiles[0].name);
        let seletedFile = acceptedFiles[0];
        setPdfFile(seletedFile);
        const fileList = Array.from(acceptedFiles);
        setSelectedFilesDrop(fileList);
      }
    },
    []
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    if (url != "") {
      setPdfFile(undefined);
      setFileName("");
      setResult([]);
      let fileInput = window.document.getElementById("file-input");
    }

    if (pdfFile) {
      setUrl("");
    }
  }, [url, pdfFile]);


  useEffect(() => {
    filesByUser({ email: currentUser?.email, tag: 'handwritten' }).then((res) => {
      setFolder(res.data);
    });
  }, []);

  return (
    <div>
      {/* Upload Document */}
      <div
        className="modal fade"
        id="kt_modal_assosiate_student"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Upload Document</h2>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">

              <AddDatasetForm />

            </div>
          </div>
        </div>
      </div>

      {/* Preview Document */}
      <div className="modal fade" id="kt_modal_preview" aria-hidden="true">
        {/* begin::Modal dialog */}
        <div className="modal-dialog modal-dialog-centered mw-650px">
          {/* begin::Modal content */}
          <div className="modal-content">
            <div className="modal-header">
              {/* begin::Modal title */}
              <h2 className="fw-bolder">Preview Document</h2>
              {/* end::Modal title */}

              {/* begin::Close */}
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              {/* <Document file={pdfUrl}>
                <Page pageNumber={1} />
              </Document> */}
              <div>
                <iframe
                  src={sasUrl}
                  title="PDF Preview"
                  width="100%"
                  height="500px"
                ></iframe>
              </div>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>

      <div className="card card-xl-stretch mb-xl-8 bg-light" style={{borderRadius: '0px'}}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5 mb-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-dark mb-2">
              {" "}
              Meta Tag Extraction{" "}
            </span>
            {/* <span className="text-muted mt-1 fw-semibold fs-7">
              Search your <strong> files</strong>
            </span> */}
          </h3>
        </div>
        {/* end::Header */}

        {/* begin: Btn Row */}
        <div className="row">
          <div
            className="d-flex justify-content-between my-1"
            data-kt-user-table-toolbar="base"
          >
            <div className="px-5">
              <button
                type="button"
                className="btn btn-primary me-3 my-3 w-180px "
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_assosiate_student"
              >
                <KTIcon iconName="plus" className="fs-3" />
                Upload Document
              </button>
              <button
                type="button"
                className="btn btn-primary me-3 my-3 w-180px "
                // data-bs-toggle="modal"
                // data-bs-target="#kt_modal_assosiate_student"
                onClick={handlePreview}
              >
                {!preview ? "Preview Document" : "Normal Mode"}
              </button>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-primary me-3 my-3 w-200px"
                onClick={handleProcess}
              >
                <KTIcon iconName="gear" className="fs-1" />
                Process
              </button>

              <button
                type="button"
                className="btn btn-primary me-3 my-3 w-200px"
                onClick={handleDownload}
              >
                <KTIcon iconName="file" className="fs-1" />
                Download PDF
              </button>
              {result.length === 0 ? null : (
                <>
                  <button
                    className="btn btn-success me-3 my-3 w-150px"
                    id="kt_drawer_chat_toggle"
                  >
                    <KTIcon iconName="message-text-2" className="fs-1" />
                    Chat
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
        {/* end :: Btn Row */}
        {folder.folderName === "" ? (
          <div className="row">
            <Blocks
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
            />
          </div>
        ) : (
          <div className="card-body pt-5">
            <div className="row d-flex justify-content-between position-relative">
              {/* Folder UI */}
              <div className="col-sm-12 col-md-3">
                <div
                  className={`card card-xl-stretch mb-5 mb-xl-8`}
                  style={{ maxHeight: "70vh", overflowY: "scroll" }}
                >
                  {folder && (
                    <>
                      {/* begin::Header */}
                      <div className="card-header border-0">
                        <h3 className="card-title fw-bold text-dark">
                          <KTIcon
                            iconName="folder"
                            className="fs-2x text-primary"
                          />
                          &nbsp;
                          {/* {folder.folderName} */}
                          Documents
                        </h3>
                      </div>
                      {/* end::Header */}
                      {/* begin::Body */}
                      <div className="card-body pt-2">
                        {/* begin::Item */}
                        {folder.files.map((file, index) => (
                          <div
                            className="d-flex align-items-center mb-8 mx-5 rounded p-1"
                            style={
                              file.processed == 1
                                ? { backgroundColor: "lightslategrey" }
                                : { backgroundColor: "transparent" }
                            }
                          >
                            {/* begin::Bullet */}
                            <span
                              className={`bullet bullet-vertical h-40px ${bgList[index + 1]
                                }`}
                            ></span>
                            {/* end::Bullet */}
                            {/* begin::Checkbox */}
                            <div className="form-check form-check-custom form-check-solid mx-5">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                checked={selectedFiles.includes(file.fileUrl)}
                                onClick={(e) =>
                                  handleSelect(
                                    e,
                                    file.fileUrl,
                                    file.fileOriginalName,
                                    file.tag,
                                    file.processed
                                  )
                                }
                              />
                            </div>
                            {/* end::Checkbox */}
                            {/* begin::Description */}
                            <div className="flex-grow-1">
                              <a
                                // href={file.url}
                                // target="_blank"
                                className="text-gray-800 text-hover-primary fw-bold fs-6"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_preview"
                                title={file.fileOriginalName}
                              >
                                {file.fileOriginalName.length > 15
                                  ? file.fileOriginalName.slice(0, 10) + "..."
                                  : file.fileOriginalName}
                              </a>
                              <span className="text-muted fw-semibold d-block">
                                Created At : {file.createdAt}
                              </span>
                              <span className="badge badge-primary">
                                {file.tag}
                              </span>
                            </div>
                            {/* end::Description */}
                            {/* <span className="badge badge-light-success fs-8 fw-bold">New</span> */}
                          </div>
                        ))}
                        {/* end:Item */}
                      </div>
                      {/* end::Body */}
                    </>
                  )}
                </div>
              </div>
              {/* File Preview UI */}
              {preview && (
                <div className="col-sm-12 col-md-4">
                  <div
                    className={`card card-xl-stretch mb-5 mb-xl-8`}
                    style={{ height: "100%" }}
                  >
                    <div className="card-header border-0">
                      <h3 className="card-title fw-bold text-dark">
                        <KTIcon
                          iconName="screen"
                          className="fs-2x text-primary"
                        />
                        &nbsp; Preview Document
                      </h3>
                    </div>
                    {fileName !== "" && (
                      <iframe
                        // src={URL.createObjectURL(pdfFile)}
                        src={toAbsoluteUrl(`/media/Demo/handwritten/${fileName}`)}
                        title="Preview"
                        width="100%"
                        height="500px"
                      />
                    )}
                    {url && (
                      <iframe
                        src={"https://" + url}
                        title="Preview"
                        width="100%"
                        height="500px"
                      />
                    )}
                  </div>
                </div>
              )}

              {/* Result UI */}
              <div className={`col-sm-12 ${preview ? "col-md-5" : "col-md-9"}`}>
                {result.length == 0 ? (
                  <div className="row align-items-center">
                    {processing && (
                      <Blocks
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                      />
                    )}
                  </div>
                ) : (
                  <div
                    className="card-xl-stretch mb-5 mb-xl-8 px-5"
                    style={{ height: "100%" }}
                  >
                    <div className="rounded p-5 bg-dark text-light overflow-auto h-100 border border-3 border-primary">
                      {result.map((sResult) => (
                        <div className="card mb-5 mb-xl-8">
                          <div className="card-body p-9">
                            <div className="d-flex align-items-center flex-grow-1">
                              <div className="symbol symbol-45px me-5">
                                <img
                                  src={toAbsoluteUrl("/media/svg/files/pdf.svg")}
                                  alt=""
                                />
                              </div>

                              <div className="d-flex flex-column">
                                <a
                                  href="#"
                                  className="text-gray-800 text-hover-primary fs-6 fw-bold"
                                >
                                  {(sResult.fileLink).toString().split('%25').pop()}
                                </a>
                              </div>
                            </div>

                            <div className="mt-2 mb-7">
                              <span className="fs-4 text-gray-800 fw-bolder">
                                Keywords : &nbsp;
                              </span>

                              <span className="fs-4 fw-bold text-gray-700">
                                {sResult.keywords.map((keyword) => (
                                  <span>{keyword},{" "}</span>
                                ))}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

