import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { KTIcon } from '../../../_metronic/helpers';

type Props = {}

const question = {
    MySql: [
        "What is the total quantity and amount for the item \"Car Mats\" in all invoices?",
        "How many different statement dates are there for the account number 4532453234?",
        "What is the total amount for the invoice with the number \"12025/23\"?",
        "How many 'Spark Plugs' were purchased across all invoices, and what is the total cost for them?",
        "What is the total quantity and amount for the item \"Wiper Blades\" in all invoices?",
        "Which item has the highest unit cost, and in which invoice does it appear?",
        "Calculate the total amount for each invoice.",
        "How many unique items are there in the provided data?",
        "Calculate the average unit cost across all items.",
        "Find the invoice with the highest total amount.",
        "Which item has a null unit cost, and in which invoice does it appear?",
        "How many different types of items are in stock?",
        "What is the unit price of the \"Engine Oil\"?",
        "Which item has the highest stock quantity, and what is the stock value?",
        "Calculate the total value of the \"Wiper Blades\" in stock.",
        "What is the total value of all the items in stock?",
        "Find the average unit price across all items.",
        "Identify the top three items with the highest stock quantities.",
        "Which customer has a statement on \"2023-12-31\" for the personal current account?",
        "What is the account number associated with the statement dated '2023-11-25' for Michael Anderson?",
        "How many statements are there for the customer Raj Dev?",
        "Which item has the highest unit price, and what is its stock quantity?",
        "Calculate the total value of the 'Brake Fluid' in stock.",
        "On what date did Michael Anderson make a statement for the personal current account?",
        "Which customer has statements on both '2023-11-20' and '2023-11-15'?",
        "What is the customer address for the statement dated '2023-02-08' for Raj Dev?",
        "How many statements are there for the account number 9876543210?",
        "On which date did Raj Dev last make a statement for the personal current account?",
        "What is the bank address for the statements made by Michael Anderson?",
        "Which customer has a statement on '2023-12-31' for the personal current account?"
    ],
    // Inventory: [
    //     "which product stock is lowest?",
    //     "what are the material and their stock quantity for product pvc?",
    //     "what is the current stock for each material of product ideal",
    //     "what are the highest and lowest stock quantities and material for each product?",
    //     "List all material, purchase price within the purchase price in the range of 5 to 15",
    //     "What is the material with highest profit margins?",
    //     "What are the material with the profit margin greater than 50?"
    // ],
    Sharepoint: [
        "What design traits of Replacement radiators contribute to exceptional performance, especially under strenuous driving conditions?",
        "What are the key components included in the kit, and what is their recommended use?",
        "Can you specify the core dimensions of the radiator mentioned in the information?",
        "How is the visibility of Replacement headlights described, and what measures are taken to ensure lasting visibility?",
        "How are Replacement brand bumper covers described in terms of design and manufacturing processes, and what principles are highlighted to keep costs down and quality high?",
        "If a customer needs to return the Replacement bumper cover, what is the specified returns policy, as stated in the information?",
        "Can you specify the OE number and Partslink number associated with the Replacement bumper cover?",
        "Who is covered by the warranty, and is it transferable to subsequent owners?",
        "How can customers initiate a warranty claim for a JC Whitney tonneau cover, and what information should they have ready?",
        "How does the presence of a holiday impact the calculation of delivery time in the provided examples?",
        "What is the return window for items purchased on NihalAutoparts?",
        "What is the process for filing a return online, and what information is required during the process?",
        "Within what time frame must claims for missing, damaged, or incorrect parts be reported to NihalAutoparts?",
        "What conditions are best suited for order cancellations, and how can customers initiate a cancellation?"
    ],
    Combined: [
        "What is the stock of side mirros and How does Kool Vue ensure hassle-free installation of their mirrors?",
        "What is quantity of Wiper blades purchased for invoice 12025/23 and Can this wiper blade be used on the rear windshield, or is it specifically for the front?",
        "What is the price of the Boost Gauge and  give me the warranty period for the Autometer Boost Gauge?",
        "What is stock of Transmission Fluid and What is the significance of the Prop 65 Warning mentioned for this product?",
        "What is the price of the Boost Gauge and  give me the warranty period for the Autometer Boost Gauge?",
        "Provide the unit price, stock quantity for Bumper Guard and What does the Prop 65 Warning indicate"
    ]

}

function SampleQuestion({ }: Props) {

    const notify = () => {
        toast.success('Copied to Clipboard!', {
            position: "bottom-left",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    };

    return (
        <div>
            <div className="card mb-5 mb-xl-10" style={{ borderRadius: '0px', minHeight: '90vh' }}>
                <ToastContainer />
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_account_email_preferences'
                    aria-expanded='true'
                    aria-controls='kt_account_email_preferences'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Sample Question</h3>
                    </div>
                </div>
                <div className='card-body'>
                    {
                        Object.entries(question).map(([key, value]) => (
                            <div className='card mb-5 mb-xl-10 border border-dark border-2'>
                                <div
                                    className='card-header cursor-pointer align-items-center'
                                    role='button'
                                    data-bs-toggle='collapse'
                                    data-bs-target={'#' + key}
                                    aria-expanded='true'
                                    aria-controls={key}
                                >
                                    <div className='card-title m-0'>
                                        <h3 className='fw-bolder m-0'>{key}:</h3>
                                    </div>
                                    <span>
                                        <KTIcon iconName='down' className='fs-1' />
                                    </span>
                                </div>

                                <div id={key} className='collapse show'>
                                    <form className='form'>
                                        <div className='card-body border-top px-9 py-9 bg-dark'>

                                            {
                                                value.map((ques, index) => (
                                                    <label className='form-check form-check-custom form-check-solid align-items-center justify-content-between border border-light border-1 p-2'>
                                                        <span className='form-check-label d-flex flex-column align-items-start'>
                                                            <span className='fw-bolder fs-5 mb-0 '>
                                                                {/* Quest{index + 1}:  */}
                                                                {ques}
                                                            </span>
                                                        </span>

                                                        <span>
                                                            <button
                                                                className="btn btn-success p-2"
                                                                type='button'
                                                                onClick={(e) => { navigator.clipboard.writeText(ques); notify() }}
                                                            >
                                                                <KTIcon iconName="copy" className="fs-1" />
                                                            </button>
                                                        </span>
                                                    </label>
                                                ))
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default SampleQuestion