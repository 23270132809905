import { useState } from "react";
import { websiteCategory } from "../../api";

type Props = {};

export default function WebsiteCategorization({}: Props) {
  const [url, setUrl] = useState("thirdeyedata.ai/");
  const [json, setJson] = useState({
    message: {
      id: "cmpl-7MzjbDSl5fLjYINRqu5Lw7cpB7Hgd",
      object: "text_completion",
      created: 1685714879,
      model: "text-davinci-003",
      choices: [
        {
          text: "\n\nInternet Advertising Bureau (IAB) Taxonomy Category: Technology/Software Solutions, with a confidence score of 0.9.",
          index: 0,
          logprobs: null,
          finish_reason: "stop"
        }
      ],
      usage: {
        prompt_tokens: 209,
        completion_tokens: 27,
        total_tokens: 236
      }
    }
  });

  const handleSearch = () => {
    websiteCategory({url: "https://"+url}).then((res) => {
      setJson(res.message);
      console.log(res.message);
    });
  };

  return (
    <div>
      <div className="card card-xl-stretch mb-xl-8 bg-light" style={{borderRadius: '0px'}}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-dark mb-2">
              {" "}
              Website Categorization{" "}
            </span>
            <span className="text-muted mt-1 fw-semibold fs-7">
              Search by <strong> Domain name</strong>
            </span>
          </h3>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body pt-5">
          <div className="d-flex align-items-center justify-content-between position-relative my-1">
            <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            <input
              type="text"
              data-kt-user-table-filter="search"
              className="form-control form-control-solid w-md-750px w-lg-900px ps-14 border border-dark border-2"
              placeholder="Enter your domain"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
            <div
              className="d-flex justify-content-end my-1"
              data-kt-user-table-toolbar="base"
            >
              <button
                type="button"
                className="btn btn-primary me-3 w-250px"
                onClick={handleSearch}
              >
                Search
              </button>
            </div>
          </div>
          <div className="rounded mt-5 p-3 bg-dark text-light">
            <pre>{JSON.stringify(json, null, 2)}</pre>
          </div>
        </div>
      </div>
    </div>
  );
}
