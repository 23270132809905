import axios from 'axios';
// export const baseUri = '${API_URL}/app';

const API_URL = process.env.REACT_APP_API_URL
const API_URL_TRAINING = process.env.REACT_APP_API_URL_TRAINING
// const API_URL = "https://demo.optira.ai:8000"
// const API_URL = "https://f054-27-131-210-250.ngrok.io"
// const API_URL = "http://localhost:8000"

// axios.interceptors.response.use(
//   response => response,
//   error => {
//     // Handle network errors or server-side errors
//     alert('A network or server-side error occurred:', error);
//     throw error;
//   }
// );

// --------------------------------------------------------------------------------------------------

// Error Handling Function

const handleApiError = (error) => {
  if (error.response) {
    throw new Error(error.response.data.message || 'Unknown error occurred');
  } else if (error.request) {
    throw new Error('Internal Server Error');
  } else {
    throw new Error('Request setup error');
  }
};

// User Management APIs

export const memberRegister = async (admin) => {
  try {
    const { data } = await axios.post(`${API_URL}/register_user/`, admin);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};
export const getAllMember = async (admin) => {
  try {
    const { data } = await axios.post(`${API_URL}/get_user_by_org_id/`, admin);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const register = async (admin) => {
  try {
    const { data } = await axios.post(`${API_URL}/register`, admin);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const login = async (admin) => {
  try {
    const { data } = await axios.post(`${API_URL}/login`, admin);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getUserByToken = async (token) => {
  try {
    const { data } = await axios.post(`${API_URL}/get_user_by_token`, token);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const updateUser = async (user) => {
  try {
    const { data } = await axios.post(`${API_URL}/update_user/`, user);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};


// --------------------------------------------------------------------------------------------------

// Analytics APIs

export const statistic = async (query) => {
  try {
    const { data } = await axios.post(`${API_URL}/statistic_data/`, query);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const datestatistic = async (query) => {
  try {
    const { data } = await axios.post(`${API_URL}/datewise_data/`, query);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const memberStatistic = async (query) => {
  try {
    const { data } = await axios.post(`${API_URL}/assigned_user_data/`, query);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

// --------------------------------------------------------------------------------------------------

// Azure Automation

export const azureCount = async (query) => {
  try {
    const { data } = await axios.post(`${API_URL}/get_file_counts/`, query);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const azureTrack = async (query) => {
  try {
    const { data } = await axios.post(`${API_URL}/monitor_folder/`, query);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getAzureInfo = async (query) => {
  try {
    const { data } = await axios.post(`${API_URL}/get_onedrive_info/`, query);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const driveCreateSource = async (admin) => {
  try {
    const { data } = await axios.post(`${API_URL}/insert_onedrive_info_into_database/`, admin);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const startContinuousProcess = async () => {
  try {
    const { data } = await axios.post(`${API_URL}/monitor_folder_test/`);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

// --------------------------------------------------------------------------------------------------

// Intelligent Document Processing

export const filesByUser = async (query) => {
  try {
    const { data } = await axios.post(`${API_URL}/get_folder_name/`, query);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const uploadFile = async (query) => {
  try {
    const { data } = await axios.post(`${API_URL}/upload_multiple_doc/`, query);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const uploadFileDemo = async (query) => {
  try {
    const { data } = await axios.post(`${API_URL}/upload_multiple_documents/`, query);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const processMultipleFile = async (query) => {
  try {
    const { data } = await axios.post(`${API_URL}/process_multiple_document/`, query);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const processDocuments = async (query) => {
  try {
    const { data } = await axios.post(`${API_URL}/process_documents/`, query);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const documentAssignTo = async (query) => {
  try {
    const { data } = await axios.post(`${API_URL_TRAINING}/assign_to`, query);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const documentApproval = async (query) => {
  try {
    const { data } = await axios.post(`${API_URL_TRAINING}/approval`, query);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const documentFeedback = async (query) => {
  try {
    const { data } = await axios.post(`${API_URL_TRAINING}/feedback`, query);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const updateDownloadCount = async (admin) => {
  try {
    const { data } = await axios.post(`${API_URL}/download_count/`, admin);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const savePdfPreview = async (admin) => {
  try {
    const { data } = await axios.post(`${API_URL}/download_file_for_preview/`, admin);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const downloadazureFile = async (query) => {
  try {
    const { data } = await axios.post(`${API_URL}/monitor_folder/`, query);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const setazureDestination = async (query) => {
  try {
    const { data } = await axios.post(`${API_URL}/update_destination_folder/`, query);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

// --------------------------------------------------------------------------------------------------

// Resume Formating

export const uploadResume = async (query) => {
  try {
    const { data } = await axios.post(`${API_URL}/upload_multiple_resume/`, query);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const processResume = async (query) => {
  try {
    const { data } = await axios.post(`${API_URL}/three_p_process/`, query);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const processResumeForum = async (query) => {
  try {
    const { data } = await axios.post(`${API_URL}/forum_process/`, query);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getResumeForumData = async (query) => {
  try {
    const { data } = await axios.post(`${API_URL}/preview_for_processed/`, query);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const bulkResumeProcess = async (query) => {
  try {
    const { data } = await axios.post(`${API_URL}/bulk_resume_process/`, query);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getResumeInfo = async (query) => {
  try {
    const { data } = await axios.post(`${API_URL}/get_resume_info/`, query);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const resumeMove = async (query) => {
  try {
    const { data } = await axios.post(`${API_URL}/upload_all_resume/`, query);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const resumeAssignTo = async (query) => {
  try {
    const { data } = await axios.post(`${API_URL_TRAINING}/resume_assign_to`, query);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const resumeApproval = async (query) => {
  try {
    const { data } = await axios.post(`${API_URL_TRAINING}/resume_approval`, query);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const resumeFeedback = async (query) => {
  try {
    const { data } = await axios.post(`${API_URL_TRAINING}/resume-feedback`, query);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};


// --------------------------------------------------------------------------------------------------

// Custom Chat GPT

export const custom_chat_gpt_demo = async (query) => {
  try {
    const { data } = await axios.post(`${API_URL}/custom_mysql_chat_gpt_session/`, query);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const resetContest = async (query) => {
  try {
    const { data } = await axios.post(`${API_URL}/reset_context/`, query);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export const updateFeedback = async (query) => {
  try {
    const { data } = await axios.post(`${API_URL}/update_feedback/`, query);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

// -------------------------------------------------------------------------------------------------

// Sharman API

export const sharmanAllFiles = async () => {
  const { data } = await axios.get(`${API_URL}/all_sharman_files/`);
  return data;
};

export const customSearch = async (query) => {
  const { data } = await axios.post(`${API_URL}/custom_search/`, query);
  return data;
};

export const sharmanSemantic = async (query) => {
  const { data } = await axios.post(`${API_URL}/search_and_summarize/`, query);
  return data;
};

export const allCategories = async (query) => {
  const { data } = await axios.post(`${API_URL}/all_categories/`, query);
  return data;
};

export const categorySearchByFile = async (query) => {
  const { data } = await axios.post(`${API_URL}/category_search_by_file/`, query);
  return data;
};

export const fileSearchByCategory = async (query) => {
  const { data } = await axios.post(`${API_URL}/file_search_by_category/`, query);
  return data;
};

// --------------------------------------------------------------------------------------------------

// Singhi API

export const getClientFileList = async (query) => {
  const { data } = await axios.post(`${API_URL}/get_all_clients_file_list_singhi/`, query);
  return data;
};

export const deleteFiles = async (query) => {
  const { data } = await axios.post(`${API_URL}/delete_files/`, query);
  return data;
};

export const getClientListSinghi = async (client) => {
  const { data } = await axios.post(`${API_URL}/get_client_list_singhi/`, client);
  return data;
};

export const storeTrainingData = async (query) => {
  const { data } = await axios.post(`${API_URL}/StoreTrainingData/`, query);
  return data;
};

export const countClient = async (pefix) => {
  const { data } = await axios.post(`${API_URL}/count_all_clients_singhi/`, pefix);
  return data;
};

export const forgotPassword = async (admin) => {
  const { data } = await axios.post(`${API_URL}/forgot_password_singhi/`, admin);
  return data;
};

// export const processMultipleFile = async (query) => {
//   const { data } = await axios.post(`${API_URL}/schedule_process_singhi/`, query);
//   return data;
// };

export const cronjob_data = async (query) => {
  const { data } = await axios.post(`${API_URL}/cronjob_data/`, query);
  return data;
};

// export const uploadFile = async (query) => {
//   const { data } = await axios.post(`${API_URL}/upload_multiple_doc_singhi/`, query);
//   return data;
// };

export const resetPassword = async (admin) => {
  const { data } = await axios.post(`${API_URL}/reset_password_singhi/`, admin);
  return data;
};


// --------------------------------------------------------------------------------------------------

// HAL API

export const websiteCategory = async (query) => {
  const { data } = await axios.post(`${API_URL}/web_cat/`, query);
  return data;
};

export const documentSummary = async (query) => {
  const { data } = await axios.post(`${API_URL}/get_summary_from_url/`, query);
  return data;
};

export const fileTextExtraction = async (query) => {
  const { data } = await axios.post(`${API_URL}/get_keyword_from_url/`, query);
  return data;
};

export const sqlDataCreate = async (query) => {
  const { data } = await axios.post(`${API_URL}/hal_data_create/`, query);
  return data;
};


export const generateCsv = async (query) => {
  const { data } = await axios.post(`${API_URL}/generate_csv/`, query);
  return data;
};