/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from "react";
import { ILayout, useLayout } from "../../core";
import { toAbsoluteUrl } from "../../../helpers";

const Footer = () => {
  const { config } = useLayout();
  useEffect(() => {
    updateDOM(config);
  }, [config]);
  return (
    <>
      <div className="d-flex align-items-end justify-content-between">
        <div className="">
          <span className="text-muted fw-semibold me-1">
            All Rights Reserved © 2024 - ThirdEye Data (USA)
          </span>
          <img src={toAbsoluteUrl("/media/logos/thirdeyelogo-horizontal.png")} alt="Thirdeye data" height={25} /> &nbsp;
        </div>
        <div className="status" style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: '500px'}}>
          <a className="mx-2" href="https://status.openai.com/" >OpenAi Health</a>
          <a className="mx-2" href="https://status.adobe.com/products/512699" >Adobe Health</a>
          <a className="mx-2" href="https://portal.azure.com/#view/Microsoft_Azure_Health/AzureHealthBrowseBlade/~/serviceIssues" >Azure Health</a>
        </div>
      </div>
      {/* <ul className='menu menu-gray-600 menu-hover-primary fw-semibold order-1'>
        <li className='menu-item'>
          <a href='https://keenthemes.com/' target='_blank' className='menu-link px-2'>
            About
          </a>
        </li>

        <li className='menu-item'>
          <a href='https://devs.keenthemes.com/' target='_blank' className='menu-link px-2'>
            Support
          </a>
        </li>

        <li className='menu-item'>
          <a
            href='https://themeforest.net/item/metronic-responsive-admin-dashboard-template/4021469'
            target='_blank'
            className='menu-link px-2'
          >
            Purchase
          </a>
        </li>
      </ul> */}
    </>
  );
};

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add("data-kt-app-footer-fixed", "true");
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add("data-kt-app-footer-fixed-mobile", "true");
  }
};

export { Footer };
