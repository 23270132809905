import { useState } from 'react';
import { StatisticsWidget5 } from '../../../_metronic/partials/widgets';

type Props = {}

export default function DataSourceDashboard({ }: Props) {

  const [searchQuery, setSearchQuery] = useState<string>("");

  const handleTagSearch = () => {
    // setSearchResult([]);
    // setProcessing(true);
    // sharmanSemantic({ search_text: searchQuery }).then((res) => {
    //     setSearchResult(res.data);
    //     setProcessing(false);
    // });
  };


  return (
    <div className='m-3'>

      <div className="d-flex align-items-center justify-content-between position-relative my-5 w-800px m-auto">
        <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
          <span className="path1"></span>
          <span className="path2"></span>
        </i>
        <input
          type="text"
          data-kt-user-table-filter="search"
          className="form-control form-control-solid w-md-100 ps-14 border border-dark border-2"
          placeholder="Search here"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <div
          className="d-flex justify-content-end my-1"
          data-kt-user-table-toolbar="base"
        >
          <button
            type="button"
            className="btn btn-primary me-3 w-200px mx-2"
            onClick={handleTagSearch}
          >
            Search
          </button>
        </div>
      </div>

      {/* begin::Row */}
      <div className='row g-5 g-xl-8 mt-5'>
        {/* <h1>Data Source</h1> */}
        <div className='col-xl-4'>
          <a href='https://www.snowflake.com/en/' target='_blank'>
            <StatisticsWidget5
              className='card-xl-stretch mb-5 mb-xl-8'
              image='snowflake'
              tag=''
              color='light'
              iconColor='white'
              titleColor='dark'
              title='Snowflake'
              description='Snowflake is uniquely designed to connect businesses globally, across any type or scale of data and many different workloads.'
              descriptionColor='dark'
            />
          </a>
        </div>
        <div className='col-xl-4'>
          {/* <Link to={"/documentprocessing/receipts"}> */}
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            image='shareponit'
            tag=''
            color='light'
            iconColor='white'
            title='SharePoint'
            titleColor='dark'
            description='Can use it as a secure place to store, organize, share, and access information from any device.'
            descriptionColor='dark'
          />
          {/* </Link> */}
        </div>
        <div className='col-xl-4'>
          {/* <Link to={"/documentprocessing/receipts"}> */}
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            image='aws-s3-logo'
            tag=''
            color='light'
            iconColor='white'
            title='Aws-S3'
            titleColor='dark'
            description='Amazon S3 is a scalable and secure cloud storage service provided by Amazon Web Services (AWS), allowing users to store and retrieve data, such as files, images, and videos, in the cloud.'
            descriptionColor='dark'
          />
          {/* </Link> */}
        </div>
        <div className='col-xl-4'>
          {/* <Link to={"/documentprocessing/receipts"}> */}
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            image='azure-blob'
            tag=''
            color='light'
            iconColor='white'
            title='Azure-blob'
            titleColor='dark'
            description='Azure Blob Storage is Microsoft cloud object storage solution that allows you to store and manage unstructured data such as documents'
            descriptionColor='dark'
          />
          {/* </Link> */}
        </div>
        <div className='col-xl-4'>
          {/* <Link to={"/documentprocessing/receipts"}> */}
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            image='azure-data-lake'
            tag=''
            color='light'
            iconColor='white'
            title='Azure-data-lake'
            titleColor='dark'
            description='Azure Data Lake is a cloud-based data storage and analytics service by Microsoft that allows you to store and analyze vast amounts of data of various types and sizes.'
            descriptionColor='dark'
          />
          {/* </Link> */}
        </div>
        <div className='col-xl-4'>
          {/* <Link to={"/documentprocessing/receipts"}> */}
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            image='local-pc-icon'
            tag=''
            color='light'
            iconColor='white'
            title='Local-PC'
            titleColor='dark'
            description='export data and documents directly from your local machine to optira installer'
            descriptionColor='dark'
          />
          {/* </Link> */}
        </div>
        <div className='col-xl-4'>
          {/* <Link to={"/documentprocessing/receipts"}> */}
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            image='hdfs'
            color='light'
            iconColor='white'
            title='Hdfs'
            titleColor='dark'
            description='Hadoop Distributed File System (HDFS) is a distributed file system designed to store and manage large volumes of data across multiple commodity servers while providing high fault tolerance and scalability for big data processing.'
            descriptionColor='dark'
          />
          {/* </Link> */}
        </div>
        <div className='col-xl-4'>
          {/* <Link to={"/documentprocessing/receipts"}> */}
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            image='crm'
            color='light'
            iconColor='white'
            title='CRM'
            titleColor='dark'
            description='A Customer Relationship Management (CRM) system is a software solution that helps businesses manage interactions and relationships with their customers, enabling improved customer service, sales, and marketing'
            descriptionColor='dark'
          />
          {/* </Link> */}
        </div>
        <div className='col-xl-4'>
          {/* <Link to={"/documentprocessing/receipts"}> */}
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            image='mysql-database'
            color='light'
            iconColor='white'
            title='MySql-Database'
            titleColor='dark'
            description='A Customer Relationship Management (CRM) system is a software solution that helps businesses manage interactions and relationships with their customers, enabling improved customer service, sales, and marketing'
            descriptionColor='dark'
          />
          {/* </Link> */}
        </div>
        <div className='col-xl-4'>
          {/* <Link to={"/documentprocessing/receipts"}> */}
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            image='Erp'
            color='light'
            iconColor='white'
            title='ERP'
            titleColor='dark'
            description='Enterprise Resource Planning (ERP) is a comprehensive software solution that integrates and manages various business processes, such as finance, inventory, human resources, and more.'
            descriptionColor='dark'
          />
          {/* </Link> */}
        </div>
      </div>
      {/* end::Row */}
    </div>
  )
}