import React from 'react';

type HeaderProps = {
    title: string;
};

const Header: React.FC<HeaderProps> = ({ title }) => {
    return (
        <div className="card-header border-0 pt-5 mb-5">
            <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-dark mb-2">
                    {title}
                </span>
            </h3>
        </div>
    );
};

export default Header;
